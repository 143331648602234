import React, { useCallback, useState } from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import Button from 'src/components/Elements/Button'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import useUpdateEventRegStatus from 'src/queries/events/useUpdateEventRegStatus'
import programStyles from '../programStyles.module.scss'
import AlertModal from 'src/components/Elements/AlertModal'
import RegQuestions from './RegQuestions'

const EventRegButton = ({
  event,
  eventCardType,
  handleViewEvent,
  currentEvent
}) => {
  const googleTracking = useAnalyticsTracking()
  const { currentUser } = useGlobalContext()
  const { mutateAsync } = useUpdateEventRegStatus()
  const [openModal, setOpenModal] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
    text === 'Pending' || text === registeredDiv
      ? setDisabledButton(true)
      : setDisabledButton(false)
  }

  const handleRegActionButton = useCallback(
    async (e) => {
      e.stopPropagation()
      setDisabledButton(true)
      if (
        event?.is_registered ||
        [
          'registration_status_pending',
          'registration_status_tentative',
          'registration_status_waitlisted',
          'registration_status_reapproval_needed'
        ].includes(event?.registration_status_code_key)
      ) {
        handleViewEvent(e)
      } else {
        if (event?.outstanding_questions?.length > 0) {
          setOpenModal(true)
        } else {
          await mutateAsync({
            regObj: {
              crm_contact_id: currentUser?.crm_contact_id,
              event_id: event?.id,
              survey_user_id: event?.survey_user_id,
              current_event: currentEvent || false
            }
          })
          googleTracking(
            'event_reg',
            currentEvent ? 'event_page' : 'app_home_page',
            {
              event_id: `${event.id}`,
              brand: event.brand_code,
              region: event.region_code,
              event_name: event.name.substring(0, 36),
              event_type: event.product_code
            }
          )
          setDisabledButton(false)
        }
      }
    },
    [event]
  )

  const data = {
    questions: event?.outstanding_questions,
    event_id: event?.id,
    crm_contact_id: currentUser?.crm_contact_id,
    survey_user_id: event?.survey_user_id,
    current_event: currentEvent || false,
    brand: event?.brand_code,
    region: event?.region_code,
    event_name: event?.name?.substring(0, 36),
    event_type: event?.product_code
  }

  const registeredDiv = (
    <div
      className={classNames(generalStyles.flexRow, generalStyles.alignVertical)}
    >
      <div
        className={classNames(
          programStyles.opaqueGreyCircle,
          generalStyles.mr20
        )}
      >
        <IcomoonReact
          icon="Control-TickIcon-Medium-BlueOutline"
          iconSet={iconSet}
          color={variables.white}
          size={22}
        />
      </div>

      <span>Registered</span>
    </div>
  )
  // registration_status_cancelled, registration_status_cantattend, no status
  let text = event?.allow_registration ? 'Register Here' : 'Coming Soon'
  let color = event?.allow_registration ? 'solar' : 'inactiveGray'
  if (eventCardType === 'Up Next' && !event?.is_virtual_event && Date.now() / 1000 > event?.begin_date_to_epoch) {
    text = 'Access'
  } else {
    if (event?.is_virtual_event && Date.now() / 1000 > event?.begin_date_to_epoch && event?.is_registered) {
      text = 'Access Now'
    } else {
      switch (event?.registration_status_code_key) {
        case 'registration_status_pending':
        case 'registration_status_tentative':
        case 'registration_status_waitlisted':
        case 'registration_status_reapproval_needed':
          text = 'Pending'
          color = 'inactiveGray'
          break
        case 'registration_status_approved':
        case 'registration_status_approved_sponsor':
        case 'registration_status_approved_speaker':
        case 'registration_status_approved_internal':
          text = registeredDiv
          color = 'noBtnContainer'
          break
        default:
          break
      }
    }
  }

  return (
    <>
      <Button
        color={color}
        onClick={(e) => handleRegActionButton(e)}
        label={text}
        size="med"
        disabled={
          text === registeredDiv ||
          text === 'Pending' ||
          disabledButton ||
          !event?.allow_registration
        }
        textOnly={text === registeredDiv}
        id={`regButton${event?.id}`}
      />
      {openModal && (
        <AlertModal
          title="Additional Registration Questions"
          closeModal={handleCloseModal}
        >
          <RegQuestions closeModal={() => setOpenModal(false)} data={data} />
        </AlertModal>
      )}
    </>
  )
}

export default EventRegButton

import React, { useCallback } from 'react'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import Button from 'src/components/Elements/Button'
import WishlistTableHeader from './WishlistTableHeader'
import dragAndDropActive from '../images/drag-drop-active.png'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'

const DraggableList = ({ contacts, id, onClick, openModal }) => {
  const isPotential = id === 'potentialWishlistContacts'

  const buttonLabel = useCallback(
    (isBlock) => {
      if (isPotential) {
        return isBlock ? 'Unblock' : 'Block'
      } else {
        return 'Remove'
      }
    },
    [isPotential]
  )

  const sendId = useCallback(
    (user) => {
      if (isPotential) {
        const id = user?.blocked_event_wishlist_one_on_one_meeting_id
          ? user?.blocked_event_wishlist_one_on_one_meeting_id
          : user?.survey_user_id
        const type = user?.blocked_event_wishlist_one_on_one_meeting_id
          ? 'delete'
          : 'create'
        onClick(id, type)
      } else {
        onClick(user?.id, 'delete')
      }
    },
    [isPotential]
  )

  if (!contacts.length) return null

  return (
    <div className={virtualPortalStyles.wishlistRankContainer}>
      <WishlistTableHeader isPotential={isPotential} />
      <Droppable droppableId={id}>
        {(provided) => (
          <div
            className={virtualPortalStyles.draggableContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <span style={{ display: 'none' }}>{provided.placeholder}</span>
            {contacts.map((user, index) => {
              return (
                <Draggable
                  key={`key${user?.id}`}
                  draggableId={`key${user?.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={virtualPortalStyles.dragAndDrop}>
                        <div style={{ display: 'flex' }}>
                          <div
                            className={virtualPortalStyles.dragAndDropActive}
                          >
                            <img src={dragAndDropActive} />
                            {!isPotential && (
                              <p className="rank">{index + 1}</p>
                            )}
                          </div>
                          <div style={{ margin: '6px 20px 0px 20px' }}>
                            <a
                              className={virtualPortalStyles.purpleName}
                              onClick={() => openModal(user)}
                            >
                              {user?.full_name}
                            </a>
                            , {user?.title}, {user?.organization_name}
                          </div>
                        </div>
                        <div className={virtualPortalStyles.choseScreen}>
                          {!isPotential && (
                            <span>
                              {user?.on_other_wishlist ? 'ALSO CHOSE YOU' : ''}
                            </span>
                          )}
                          {isPotential && (
                            <span>
                              {user?.blocked_event_wishlist_one_on_one_meeting_id
                                ? 'YOU BLOCKED THEM'
                                : ''}
                            </span>
                          )}
                          <Button
                            color="evantaBlueOutline"
                            onClick={() => sendId(user)}
                            label={buttonLabel(
                              user?.blocked_event_wishlist_one_on_one_meeting_id
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            })}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default DraggableList

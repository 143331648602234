import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import { deviceTextTemplate } from 'src/utils/helpers/DeviceUtilities'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import AlertModal from './AlertModal'
import Button from './Button'
import Loader from './Loader'
import HorizontalLine from './HorizontalLine'
import elementStyles from './elements.module.scss'
import usePostContactForm from 'src/queries/contactForm/usePostContactForm'
import { useGlobalContext } from 'src/stores/GlobalContext'

const ContactUsForm = () => {
  const navigate = useNavigate()
  const googleTracking = useAnalyticsTracking()
  const { mutateAsync } = usePostContactForm()
  const { eventId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { currentUser } = useGlobalContext()

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      message: ''
    }
  })

  const submitForm = async (data) => {
    setIsLoading(true)
    const message = `${data.message}`
    try {
      const form = await mutateAsync({ message, template: deviceTextTemplate, email: currentUser.email, eventId })
      if (form.status === 201) {
        setOpenModal(true)
        googleTracking('contact_form_submit', 'internal_page')
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const closeModal = useCallback(() => {
    setOpenModal(false)
    if (eventId) {
      navigate(`/programs/${eventId}`)
    } else {
      navigate('/programs')
    }
  }, [eventId, openModal])

  return (
    <div className={generalStyles.thirdWidth}>
      <div className={generalStyles.mb20}>
        <span className={fontStyles.h3alternate2bolddark}>Contact Us</span>
        <div className={classNames(generalStyles.mt10)}>
          <HorizontalLine fullWidth={true} color={'lightGrey'} />
        </div>
      </div>
      <div>
        <span className={fontStyles.bodyregulardark}>
          Please let us know your question below.
        </span>
      </div>
      <div
        className={classNames(
          generalStyles.mt20,
          generalStyles.flexColumn,
          generalStyles.fullFlex
        )}
      >
        <Controller
          name="message"
          control={control}
          rules={{
            required: {
              value: true
            },
            minLength: {
              value: 10
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <textarea
              className={elementStyles.textareaInput}
              id="comments"
              onChange={(text) => onChange(text)}
              value={value}
              rows="10"
              onBlur={onBlur}
              placeholder="Write here..."
            />
          )}
        />
      </div>
      <div
        className={classNames(generalStyles.mt20, generalStyles.flexCentered)}
      >
        {isLoading
          ? (
            <Loader />
          )
          : (
            <Button
              color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
              size="large"
              onClick={handleSubmit(submitForm)}
              label="SUBMIT"
              disabled={!isValid}
            />
          )}
      </div>
      {openModal &&
        <AlertModal
          title="Thank you"
          closeModal={() => setOpenModal(false)} >
          <>
            <span className={fontStyles.bodyregulargrey}>
              <div>
                <span className={fontStyles.bodydemigrey}>
                  We will check your inquiry and get back to you shortly. Please
                  check your email.
                </span>
              </div>
            </span>
            <div className={generalStyles.mt40}>
              <Button
                color='evantaBlue'
                label='Done'
                onClick={closeModal}
                size='large'
              />
            </div>
          </>
        </AlertModal>
      }
    </div>
  )
}

export default ContactUsForm

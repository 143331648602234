import React from 'react'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import agendaStyles from '../agendaStyles.module.scss'

const ItinerarySessionCard = ({
  sessionType,
  topBorder,
  bottomBorder,
  inactive,
  sessionId,
  timeSectionHeaderIndex
}) => {
  return (
    <div
      className={classNames(
        agendaStyles.agendaItemCardContainer,
        { [agendaStyles.topBorder]: topBorder },
        { [agendaStyles.bottomBorder]: bottomBorder }
      )}
      // prefix id session in case there's other elements that share id
      id={`session-${sessionId}`}
      data-time-section={`${timeSectionHeaderIndex}`}
    >
      <span
        className={classNames(fontStyles.h6demigrey, {
          [fontStyles.inactive]: inactive
        })}
      >
        {sessionType}
      </span>
    </div>
  )
}

export default ItinerarySessionCard

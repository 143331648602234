import React from 'react'
import { useNavigate } from 'react-router-dom'
import IcomoonReact from 'icomoon-react'
import Button from 'src/components/Elements/Button'
import iconSet from 'src/assets/icons/iconList.json'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import preloginStyles from './prelogin.module.scss'

const ProfileForm = () => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/signup')
  }
  // folder with 4 files or one file with 4 methods for each page?
  // page 1
  // page 2
  // page 3
  // page 4 thank you (sign in)
  // Control-PageIndicator-BlueOutline
  // Control-PageIndicator-BlueSolid

  return (
    <div>
      <PreloginNavigationHeader back={true} onClick={() => handleBackClick()} />
      <div className={preloginStyles.contentBodyContainer}>
        <span className={fontStyles.h1regulardark}>Complete Your Profile</span>
        <Button label="CONTINUE" />
        <div>
          <IcomoonReact
            iconSet={iconSet}
            className={''}
            color={''}
            size={10}
            icon={''}
          />
          <IcomoonReact
            iconSet={iconSet}
            className={''}
            color={''}
            size={10}
            icon={''}
          />
          <IcomoonReact
            iconSet={iconSet}
            className={''}
            color={''}
            size={10}
            icon={''}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileForm

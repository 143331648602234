import React from 'react'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { ToastProvider } from './stores/ToastContext'
import Store from './Store'

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
  enabled: import.meta.env.PROD
}

const App = () => {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <ToastProvider>
          <Store />
        </ToastProvider>
      </ErrorBoundary>
    </RollbarProvider>
  )
}

export default App

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'

const fetchAttendees = async (eventId) => {
  const response = await fetchData('/my_evanta/users/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchAttendees = () => {
  const { eventId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: [`attendees_${eventId}`],
    queryFn: () => fetchAttendees(eventId),
    enabled: !!eventId,
    staleTime: Infinity,
    retry: false
  })

  const attendees = isEmpty(data) ? [] : data.event_users
  const filteredAttendees = isEmpty(attendees) ? [] : attendees.filter(attendee => attendee.is_attended)

  return {
    attendees,
    filteredAttendees,
    isLoadingAttendees: isLoading
  }
}

export default useFetchAttendees

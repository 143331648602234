import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { postFile } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postUploadPhoto = async (params) => {
  const response = await postFile('/my_evanta/users/user_photo_upload', params)
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUploadPhoto = () => {
  const { setAlert } = useToastAlert()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data, mutateAsync, mutate } = useMutation({
    mutationFn: postUploadPhoto,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['current_user'] })
      navigate('/edit-profile')
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  const currentUser = isEmpty(data) ? {} : data.user

  return { currentUser, mutateAsync, mutate }
}

export default useUploadPhoto

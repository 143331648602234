import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'

import { Outlet } from 'react-router-dom'

const EditPhotoPage = () => {
  return (
    <>
      <div
        className={classNames(
          generalStyles.mt20,
          generalStyles.mb20,
          generalStyles.alignVertical,
          generalStyles.flexColumn
        )}
      >
        <Outlet />
      </div>
    </>
  )
}

export default EditPhotoPage

import React from 'react'
import Modal from 'src/components/Elements/Modal'
import Button from 'src/components/Elements/Button'

const HelpAndSupport = ({ openModal, setOpenModal }) => {
  return (
    <Modal close={() => setOpenModal(false)} isOpen={openModal} >
      <h3 style={{ margin: 0 }}>Help & Support</h3>
      <h4 style={{ margin: 0, fontWeight: 400 }}>How to create your agenda:</h4>
      <ol>
        <li>Click the ‘+‘ button to add a session to your schedule.</li>
        <li>Click the ‘-‘ button to remove a session from your schedule.</li>
        <li>Select ‘My Schedule‘ to view your selected sessions.</li>
      </ol>
      <hr></hr>
      <h4 style={{ margin: 0, fontWeight: 400 }}>Keynote Session:</h4>
      <ul>
        <li>Sessions are open to all participants and led by thought leaders in your community.</li>
      </ul>
      <hr></hr>
      <h4 style={{ margin: 0, fontWeight: 400 }}>Boardroom Sessions:</h4>
      <ul>
        <li>Intimate and interactive conversations framed around a hot topic or priority.</li>
        <li>Limited availability based on a first-come, first-served basis.</li>
      </ul>
      <hr></hr>
      <h4 style={{ margin: 0, fontWeight: 400 }}>Community Networking:</h4>
      <ul>
        <li>Informal, interactive opportunity for you to network with peers in small groups.</li>
      </ul>
      <hr></hr>
      <h4 style={{ margin: 0, fontWeight: 400 }}>Peer to Peer Meetings:</h4>
      <ul>
        <li>Connect with peers in your community in a 1-to-1 setting.</li>
        <li>Available if you signed up prior to the summit.</li>
      </ul>
      <hr></hr>
      <Button style={{ margin: '0 auto', width: 80, padding: 4, borderRadius: 4 }} color="solar" label="Close" onClick={() => setOpenModal(false)} />
    </Modal>
  )
}

export default HelpAndSupport

import React from 'react'
import classNames from 'classnames'
import { transformDate } from 'src/utils/helpers/MessagesHelpers'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'

const TimeframeDivider = ({ timeframe }) => (
  <div
    className={classNames(
      generalStyles.flexRow,
      generalStyles.alignVertical,
      generalStyles.mb30,
      generalStyles.mt10
    )}
  >
    <div
      className={classNames(generalStyles.flexColumn, generalStyles.fullFlex)}
    >
      <HorizontalLine fullWidth={true} color={'lightGrey'} />
    </div>
    <div className={classNames(generalStyles.ml30, generalStyles.mr30)}>
      <span className={fontStyles.h6variant2demigrey}>
        {transformDate(timeframe)}
      </span>
    </div>
    <div
      className={classNames(generalStyles.flexColumn, generalStyles.fullFlex)}
    >
      <HorizontalLine fullWidth={true} color={'lightGrey'} />
    </div>
  </div>
)

export default TimeframeDivider

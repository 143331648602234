import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import { photoEditingOptions } from 'src/utils/helpers/DropdownOptionMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'
import useUpdateCurrentUser from 'src/queries/currentUser/useUpdateCurrentUser'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import Button from 'src/components/Elements/Button'
import Input from 'src/components/Elements/Input'
import Dropdown from 'src/components/Elements/Dropdown'
import Switch from 'src/components/Elements/Switch'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import settingStyles from './settingStyles.module.scss'

const EditProfilePage = () => {
  const googleTracking = useAnalyticsTracking()
  const { currentUser } = useGlobalContext()
  const { mutate } = useUpdateCurrentUser()
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: 'onBlur',
    reValidationMode: 'onBlur',
    defaultValues: {
      ...currentUser
    }
  })

  const otherDietaryTagValue = watch('dietary_requirement_other_tag')
  const otherAccessibilityTagValue = watch('accessibility_need_other_tag')

  useEffect(() => {
    reset(currentUser)
  }, [currentUser])

  const updateHandler = useCallback((data) => {
    googleTracking('profile_save', 'profile_page')
    mutate({ ...data })
  }, [mutate])

  const tagField = (fieldName, label, attrToUpdate) => {
    const handleCheck = (value, attr) => {
      if (!value) {
        resetField(attr, { defaultValue: '' })
      }
    }
    return (
      <Controller
        key={fieldName}
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className={generalStyles.mb10}>
            <Switch
              id={fieldName}
              label={label}
              onChange={(value) => {
                if (attrToUpdate) {
                  handleCheck(value, attrToUpdate)
                }
                onChange(value)
              }}
              enabled={value}
              disabled={false}
            />
          </div>
        )}
      />
    )
  }

  const renderTags = (tagType, otherFieldName) => {
    const tagArray = []
    for (const key in currentUser) {
      if (key.includes(`${tagType}_`)) {
        const text = key.replace(`${tagType}_`, '').replace('_tag', '')
        const label = text.charAt(0).toUpperCase() + text.slice(1)
        const additionalChange =
          key === `${tagType}_other_tag` ? otherFieldName : null
        tagArray.push({
          fieldName: key,
          label,
          additionalOnChange: additionalChange
        })
      }
    }
    return tagArray.map((t) => {
      return tagField(t.fieldName, t.label, t.additionalOnChange)
    })
  }

  const nameInputs = useMemo(() => {
    return ([
      {
        id: 'first_name',
        required: true,
        label: 'First Name'
      },
      {
        id: 'last_name',
        required: true,
        label: 'Last Name'
      },
      {
        id: 'title',
        required: false,
        label: 'Title'
      }
    ])
  }, [])

  if (isEmpty(currentUser)) return <></>

  return (
    <div className={generalStyles.thirdWidth}>
      <div>
        <div
          className={classNames(generalStyles.flexRow, generalStyles.flexApart)}
        >
          <div className={settingStyles.imageContainer}>
            <UserImage
              photoUrl={currentUser?.photo}
              styles={classNames(
                generalStyles.mr20,
                settingStyles.profileImage
              )}
              altText={`${currentUser?.first_name} ${currentUser?.last_name} Photo`}
            />
            <div className={settingStyles.editImageBtn}>
              <div
                className={classNames(
                  settingStyles.editImageBadgeContainer,
                  settingStyles.editProfilePage
                )}
              >
                <Dropdown
                  iconName='Control-AddIcon-Medium-BlueSolid'
                  iconSize={20}
                  position='right'
                  items={photoEditingOptions()}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              generalStyles.flexColumn,
              generalStyles.fullFlex
            )}
          >
            {nameInputs.map(input => (
              <Controller
                key={input.id}
                name={input.id}
                control={control}
                rules={{
                  required: input.required && { value: true, message: 'This field is required' }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    id={input.id}
                    name={input.id}
                    label={{ label: input.label }}
                    onChange={(text) => onChange(text)}
                    value={value}
                    onBlur={onBlur}
                    error={
                      isEmpty(errors[input.id]) ? null : errors?.[input.id].message
                    }
                  />
                )}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classNames(generalStyles.flexColumn, generalStyles.fullFlex)}>
        <Controller
          name="organization_name"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="organization_name"
              name="organization_name"
              label={{ label: 'Organization Name' }}
              onChange={(text) => onChange(text)}
              value={value}
              onBlur={onBlur}
              error={
                errors?.organization_name
                  ? errors?.organization_name?.message
                  : null
              }
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: { value: true, message: 'This field is required' }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="email"
              name="email"
              label={{ label: 'Email', helper: '* cannot be changed' }}
              onChange={(text) => onChange(text)}
              value={value}
              onBlur={onBlur}
              color="gray"
              disabled
              error={errors?.email ? errors?.email?.message : null}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="phone"
              name="phone"
              label={{ label: 'Phone' }}
              onChange={(text) => onChange(text)}
              value={value}
              onBlur={onBlur}
              maxLength={20}
              error={errors?.phone ? errors?.phone?.message : null}
            />
          )}
        />
        <Controller
          name="bio"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="bio"
              name="bio"
              label={{ label: 'Biography' }}
              multiline
              onChange={(text) => onChange(text)}
              value={value}
              onBlur={onBlur}
              maxLength={10000}
              errortext={errors?.bio ? errors?.bio?.message : null}
            />
          )}
        />
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodyregulardarkgrey}>
            Dietary Requirements
          </span>
        </div>
        {renderTags('dietary_requirement', 'dietary_restrictions')}
        {otherDietaryTagValue && (
          <div>
            <Controller
              name="dietary_restrictions"
              control={control}
              rules={{
                required: {
                  value: otherDietaryTagValue,
                  message: 'This field is required'
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  id='dietary_restrictions'
                  name="dietary_restrictions"
                  label={{ label: 'Dietary Notes for Other', placeholder: 'Add additional dietary notes here', helper: '*' }}
                  multiline
                  autoCapitalize="none"
                  onChange={(text) => onChange(text)}
                  value={value}
                  onBlur={onBlur}
                  maxLength={1000}
                  error={
                    errors?.dietary_restrictions
                      ? errors?.dietary_restrictions?.message
                      : null
                  }
                />
              )}
            />
          </div>
        )}
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <div>
          <Controller
            name="dietary_allergies"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                id='dietary_allergies'
                name="dietary_allergies"
                label={{ label: 'Allergies', placeholder: 'List allergies here' }}
                autoCapitalize="none"
                multiline
                onChange={(text) => onChange(text)}
                value={value}
                onBlur={onBlur}
                maxLength={1000}
                error={
                  errors?.dietary_allergies
                    ? errors?.dietary_allergies?.message
                    : null
                }
              />
            )}
          />
        </div>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <div>
          <span className={fontStyles.bodyregulardarkgrey}>
            Accessibility Needs
          </span>
        </div>
        {renderTags('accessibility_need', 'accessibility_needs')}
        {otherAccessibilityTagValue && (
          <div>
            <Controller
              name="accessibility_needs"
              control={control}
              rules={{
                required: {
                  value: otherAccessibilityTagValue,
                  message: 'This field is required'
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  id={'accessibility_needs'}
                  name="accessibility_needs"
                  label={{ label: 'Additional Accessibility Information', placeholder: 'Add additional accessibility notes here', helper: '*' }}
                  autoCapitalize="none"
                  multiline
                  onChange={(text) => onChange(text)}
                  value={value}
                  onBlur={onBlur}
                  maxLength={1000}
                  error={
                    errors?.accessibility_needs
                      ? errors?.accessibility_needs?.message
                      : null
                  }
                />
              )}
            />
          </div>
        )}
      </div>

      <div
        className={classNames(
          generalStyles.mt40,
          generalStyles.flexCentered,
          generalStyles.pb40
        )}
      >
        <Button
          color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
          size={'med'}
          label={'Save Changes'}
          onClick={handleSubmit(updateHandler)}
          disabled={!isValid}
        />
      </div>
    </div>
  )
}

export default EditProfilePage

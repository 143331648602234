import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import elementStyles from './elements.module.scss'

const TypeProps = {
  number: PropTypes.number,
  styles: PropTypes.any
}

const Badge = ({ number, styles }) => (
  <div className={classNames(elementStyles.badgeContainer, [styles])}>
    <span className={fontStyles.bodysmalldemiwhitecentered}>{number}</span>
  </div>
)

Badge.propTypes = {
  ...TypeProps
}

export default Badge

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const fetchWishlistUsers = async (config) => {
  const response = await fetchData('/virtual_portal/wishlist_users', { ...config })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchWishlistUsers = () => {
  const config = getQueryConfig()
  const { data } = useQuery({
    queryKey: ['wishlist_users', config],
    queryFn: () => fetchWishlistUsers(config),
    staleTime: Infinity,
    enabled: !!config
  })

  const wishlistUsers = isEmpty(data) ? [] : data.users

  return {
    wishlistUsers
  }
}

export default useFetchWishlistUsers

import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import variables from 'src/assets/styles/variables.module.scss'

const Loader = () => {
  return (
    <TailSpin
      height="35"
      width="35"
      color={variables.evantaBlue}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  )
}

export default Loader

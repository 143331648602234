import React from 'react'
import PropTypes from 'prop-types'

const TypeProps = {
  photoUrl: PropTypes.string,
  styles: PropTypes.any,
  altText: PropTypes.string
}

const UserImage = ({ photoUrl, styles, altText }) => (
  <img
    src={photoUrl || '/assets/images/blank-headshot.jpg'}
    alt={altText}
    className={styles}
  />
)

UserImage.propTypes = {
  ...TypeProps
}

export default UserImage

import React, { useState } from 'react'
import sanitize from 'sanitize-html'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import VirtualPortalStyles from '../wizard/components/virtualPortalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import classNames from 'classnames'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { groupBy, isEmpty } from 'lodash'
import { isPastTime } from 'src/utils/helpers/GeneralHelpers'

const Session = ({ i, session }) => {
  const [openDetails, setOpenDetails] = useState(false)
  const isMobile = useIsMobileQuery()
  const Component = openDetails ? MdKeyboardArrowUp : MdKeyboardArrowDown
  const pastSession = isPastTime(session.epoch_end_time)
  return (
    session.session_type === 'Break'
      ? <div className={VirtualPortalStyles.sessionBreak}>BREAK</div>
      : <div
        className={classNames(VirtualPortalStyles.sessionInside)}
      >
        <div className={classNames(generalStyles.flexRow)}>
          <img
            style={{ height: 200, opacity: pastSession ? '0.5' : '1' }}
            src={sanitize(`https://cfassets.evanta.com/app-assets/Portal-VBR${i + 1}-${isMobile ? 'mobile' : 'desktop'}.jpg`)}
          />
          <div style={{ padding: 20, opacity: pastSession ? '0.5' : '1' }}>
            <div>
              <h5 style={{ margin: 0, textTransform: 'uppercase' }}>{session.session_type}</h5>
              <h2>{session.name}</h2>
              {openDetails &&
            <>
              <p>Hosted by {session.sponsor_name}</p>
              {!isEmpty(session.event_session_speakers)
                ? session.event_session_speakers.map(speaker => {
                  return (
                    <div style={{ marginTop: 20, display: 'flex' }} key={speaker.id}>
                      <img
                        style={{ borderRadius: '100%', height: 65, width: 65, marginRight: 8 }}
                        src={speaker.photo}
                      />
                      <div>
                        <h3
                          className={fontStyles.h6bolddark}
                          style={{ margin: 0 }}
                        >
                          {speaker.first_name} {speaker.last_name}
                        </h3>
                        <p style={{ margin: 0 }}>{speaker.title}</p>
                        <p style={{ margin: 0 }}>{speaker.crm_account_name}</p>
                        <span
                          className={fontStyles.bodysmallaltregulardark}
                          style={{ margin: 0, textTransform: 'uppercase', fontWeight: 600 }}>
                          {speaker.speaker_label_name}
                        </span>

                      </div>
                    </div>
                  )
                })
                : null}
              <div dangerouslySetInnerHTML={{ __html: sanitize(session.body) }} />
            </>
              }
            </div>
            <div className={VirtualPortalStyles.detailsMenu} onClick={() => setOpenDetails(!openDetails)}>
              <Component />
              <p>Details</p>
            </div>
          </div>
        </div>
      </div>
  )
}

const Sessions = ({ sessions, isLoading }) => {
  const groupedSessions = isEmpty(sessions) ? [] : groupBy(sessions, 'formatted_display_date')

  if (isLoading) return <div>Loading...</div>

  if (isEmpty(sessions)) return <div>No sessions schedule</div>

  return (
    <div>
      {Object.entries(groupedSessions).map((values) => {
        const date = values[0]
        const sessionsValue = values[1]
        return (
          <div
            className={classNames(VirtualPortalStyles.sessionBox)}
            key={`session-container-${date}`}
          >
            <h5>{date}</h5>
            {sessionsValue.map((session, i) => {
              if (session.is_hidden) return null
              return <Session key={session.id} session={session} i={i} />
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Sessions

import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import ProgramsNavigation from 'src/features/navigation/ProgramsNavigation'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import layoutStyles from './layouts.module.scss'
import RightMainContentPanel from './SidePanels/RightMainContentPanel'
import { useGlobalContext } from 'src/stores/GlobalContext'

const LeftPanelLayout = () => {
  const { eventId, sidePanelDetails } = useGlobalContext()
  const { pathname } = useLocation()
  const isMobileSize = useIsMobileQuery()
  const navigationPresent = (eventId && pathname.includes('messages')) || ''

  return (
    <div
      className={classNames(generalStyles.fullFlex, generalStyles.flexColumn)}
    >
      {/* this is for MVP  */}
      {navigationPresent && <ProgramsNavigation isMobile={isMobileSize} />}
      <div className={layoutStyles.leftPanelContainer}>
        <div className={layoutStyles.sidePanelContainer}>
          <Outlet />
        </div>

        <div
          className={classNames(layoutStyles.contentContainer, {
            [layoutStyles.mobileSidePanel]: isMobileSize,
            [layoutStyles.hidden]: !sidePanelDetails.type
          })}
        >
          <RightMainContentPanel />
        </div>
      </div>
    </div>
  )
}

export default LeftPanelLayout

import React from 'react'
import { useNavigate } from 'react-router-dom'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import preloginStyles from './prelogin.module.scss'

const QualificationCriteria = () => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/signup')
  }
  return (
    <div>
      <PreloginNavigationHeader back={true} onClick={() => handleBackClick()} />
      <div className={preloginStyles.contentBodyContainer}>
        <span className={fontStyles.h1regulardark}>Qualification Criteria</span>
      </div>
    </div>
  )
}

export default QualificationCriteria

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import UserImage from 'src/components/Elements/UserImage'
import UserIcons from 'src/components/Elements/UserIcons'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import iconSet from 'src/assets/icons/iconList.json'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import userStyles from './userStyles.module.scss'

const UserDetailPage = () => {
  const navigate = useNavigate()
  const { currentEvent, currentUser, eventId, attendee, attendeeId, setSidePanelDetails } = useGlobalContext()
  const isApprovedInternal = useMemo(() => currentEvent.registration_status === 'Approved Internal', [currentEvent])

  const handleMessageBtn = useCallback(() => {
    setSidePanelDetails({ type: 'message', data: Number(attendeeId) })
    navigate(`/programs/${eventId}/messages`)
  }, [eventId, attendeeId])

  const renderBizCardDetails = () => {
    if (attendee?.is_biz_card) {
      return (
        <div className={generalStyles.mt40}>
          <div>
            <span className={fontStyles.h3alternate2bolddark}>
              Contact Details
            </span>
            <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
              <HorizontalLine color={'lightGrey'} fullWidth={true} />
            </div>
          </div>
          <div className={generalStyles.mt10}>
            {attendee?.phone
              ? (
                <div className={generalStyles.flexRow}>
                  <span className={fontStyles.bodyregulardark}>
                  Phone |{' '}
                    <span className={fontStyles.bodybolddark}>
                      {attendee.phone}
                    </span>
                  </span>
                </div>
              )
              : null}
            {attendee?.email
              ? (
                <div className={generalStyles.flexRow}>
                  <span className={fontStyles.bodyregulardark}>
                  Email |{' '}
                    <span className={fontStyles.bodybolddark}>
                      {attendee.email}
                    </span>
                  </span>
                </div>
              )
              : null}
          </div>
        </div>
      )
    }
  }
  return (
    <div className={generalStyles.thirdWidth}>
      <div className={userStyles.attendeeHeader}>
        <div
          className={classNames(generalStyles.flexColumn, generalStyles.pt40)}
        >
          <UserImage
            photoUrl={attendee?.photo}
            styles={classNames(generalStyles.mr20, userStyles.image)}
            altText={`${attendee?.first_name} ${attendee?.last_name} Photo`}
          />
          <div className={generalStyles.flexColumn}>
            <div
              className={classNames(
                generalStyles.flexRow,
                generalStyles.mt05,
                generalStyles.mb05
              )}
            >
              <span className={fontStyles.h2alternate2bolddark}>
                {attendee?.first_name} {attendee?.last_name}
              </span>
              <div className={generalStyles.ml20}>
                <UserIcons user={attendee} />
              </div>
            </div>
            <div className={generalStyles.mb05}>
              <span className={fontStyles.h3alternate2bolddark}>
                {attendee?.title}
              </span>
            </div>
            <div className={generalStyles.mb05}>
              <span className={fontStyles.h3alternate2bolddark}>
                {attendee?.organization_name}
              </span>
            </div>
          </div>
        </div>
        {!isApprovedInternal && <><div className={classNames(generalStyles.mt10, generalStyles.mb10)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div><div
          className={classNames(
            generalStyles.flexRow,
            generalStyles.verticalAlign,
            generalStyles.pt05,
            generalStyles.pb05
          )}
        >
          <button
            data-title="Message"
            className={userStyles.actionItemContainer}
            disabled={!currentEvent?.is_attended ||
                currentUser.id === parseInt(attendeeId)}
            onClick={() => handleMessageBtn()}
          >
            <IcomoonReact
              icon={'Symbol-MessageIcon-Medium-BlueOutline'}
              iconSet={iconSet}
              color={'evantaBlue'}
              size={23} />
          </button>
        </div></>}
        <div className={classNames(generalStyles.mt10, generalStyles.mb40)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
      </div>
      <div>
        <span className={fontStyles.h3alternate2bolddark}>Biography</span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <span>{attendee?.bio || 'No bio provided'}</span>
      </div>
      {renderBizCardDetails()}
    </div>
  )
}

export default UserDetailPage

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { logoutUser } from 'src/utils/helpers/GeneralHelpers'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import navStyles from '../navigation.module.scss'
import { version } from '../../../../package.json'

const LinkComponent = ({ isMobile, tab }) => {
  if (!tab.show) return <></>

  return (
    <button className={navStyles.navItemContainer} onClick={tab.onClick}>
      <div className={isMobile && navStyles.navItemContainerWrapMobile}>
        <IcomoonReact
          iconSet={iconSet}
          color={variables.evantaBlue}
          size={isMobile ? undefined : 19}
          icon={tab.icon}
        />
      </div>
      <span className={classNames(fontStyles.bodybolddark, generalStyles.ml20)}>
        {tab.label}
      </span>
    </button>
  )
}

const FooterLinkComponent = ({ link }) => {
  return (
    <span className={fontStyles.bodysmallaltdemigreycentered}>
      {link.href
        ? (
          <a
            href={link.href}
            target="_blank"
            rel="noreferrer"
            className={navStyles.customLink}
          >
            {link.label}
          </a>
        )
        : (
          <>{link.label}</>
        )}
    </span>
  )
}

const ProfileMenu = ({ setProfileMenuOpen, isMobile }) => {
  const navigate = useNavigate()

  const handleLogout = useCallback(() => {
    logoutUser()
    navigate('/auth/signin')
  }, [])

  const handleClick = useCallback((link) => {
    setProfileMenuOpen(false)
    navigate(link)
  }, [])

  const Tabs = useMemo(() => {
    return [
      {
        show: true,
        isActive: false,
        label: 'Edit Profile',
        icon: 'Control-EditLineIcon',
        onClick: () => handleClick('/profile')
      },
      {
        show: false,
        label: 'Bookmarked',
        icon: 'Symbol-BookmarkIcon-BlueOutline',
        onClick: () => handleClick('/bookmarked')
      },
      {
        show: false,
        label: 'Settings',
        icon: 'Symbol-SettingsIcon-Large-BlueOutline',
        onClick: () => handleClick('/settings')
      },
      {
        show: true,
        label: 'Help & Support',
        icon: 'Symbol-HelpIcon-Large-GreyOutline',
        onClick: () => handleClick('/contact')
      },
      {
        show: true,
        label: 'Logout',
        icon: 'Control-LogoutIcon',
        onClick: () => handleLogout()
      }
    ]
  }, [navigate, setProfileMenuOpen, handleLogout])

  const links = useMemo(() => {
    return [
      {
        label: `App Version ${version}`
      },
      {
        label: 'Terms & Conditions',
        href: 'https://www.evanta.com/terms/terms-and-conditions'
      },
      {
        label: 'Privacy Policy',
        href: 'https://www.evanta.com/terms/privacy-policy'
      },
      {
        label: 'Official Sweepstakes Rules ',
        href: 'https://www.evanta.com/terms/sweepstakes-rules'
      },
      {
        label: 'Official Leaderboard Rules',
        href: 'https://www.evanta.com/terms/leaderboard-rules'
      }
    ]
  }, [version])

  if (isMobile) {
    return (
      <div>
        <div className={navStyles.profileMenuContainerMobile}>
          {Tabs.map((tab, index) => (
            <LinkComponent isMobile key={`${tab.label} - ${index}`} tab={tab} />
          ))}
        </div>
        <div className={navStyles.line} />
        <div className={navStyles.footerMenuContainerMobile}>
          {links.map((link, index) => (
            <FooterLinkComponent key={`${link.label} - ${index}`} link={link} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      className={navStyles.profileMenuContainer}
      onMouseLeave={() => setProfileMenuOpen(false)}
    >
      <div className={navStyles.leftContainer}>
        {Tabs.map((tab, index) => (
          <LinkComponent key={`${tab.label} - ${index}`} tab={tab} />
        ))}
      </div>
      <div className={navStyles.line} />
      <div className={navStyles.rightContainer}>
        {links.map((link, index) => (
          <FooterLinkComponent key={`${link.label} - ${index}`} link={link} />
        ))}
      </div>
    </div>
  )
}

export default ProfileMenu

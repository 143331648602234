import React from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from 'src/stores/GlobalContext'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Button from 'src/components/Elements/Button'

const BusinessCardAgreement = ({ closeModal }) => {
  const { updateCurrentEventBizCardAccess } = useGlobalContext()
  const navigate = useNavigate()

  const handleAllowPermission = () => {
    updateCurrentEventBizCardAccess()
    closeModal()
  }

  const handleDeclinePermission = () => {
    navigate(-1)
  }

  return (
    <>
      <div className={generalStyles.mb20}>
        <span className={fontStyles.bodyregulargrey}>
          The QR Code is your virtual business card. By scanning a QR code, you are automatically sharing your Profile information with the participant or sponsor.
        </span>
      </div>
      <span className={fontStyles.bodybolddark}>
        Do you agree to share your contact details?
      </span>
      <div
        className={classNames(
          generalStyles.mt40,
          generalStyles.flexRow,
          generalStyles.gap40
        )}
      >
        <Button
          color={'evantaBlue'}
          onClick={() => handleAllowPermission()}
          label={'Yes'}
          size={'med'}
        />
        <Button
          color={'evantaBlueOutline'}
          onClick={() => handleDeclinePermission()}
          label={'No'}
          size={'med'}
        />
      </div>
    </>
  )
}

export default BusinessCardAgreement

import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import IcomoonReact from 'icomoon-react'
import iconSet from 'src/assets/icons/iconList.json'
import elementStyles from './elements.module.scss'

const TypeProps = {
  toggleableDiv: PropTypes.element,
  unexpandedText: PropTypes.string,
  expandedText: PropTypes.string,
  testID: PropTypes.string
}

const ExpandableDiv = ({ toggleableDiv, unexpandedText, expandedText, testID }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div data-testid={`expandable${testID}`}>
      {expanded ? <div data-testid='moreInfoBody'>{toggleableDiv}</div> : null}
      <div className={generalStyles.flexCentered}>
        <button
          onClick={() => setExpanded(!expanded)}
          className={classNames(
            generalStyles.flexRow,
            generalStyles.alignVertical,
            elementStyles.expandableBtn
          )}
          data-testid='moreInfoButton'
        >
          <span className={fontStyles.h6demigrey}>
            {!expanded ? unexpandedText : expandedText}
          </span>
          <IcomoonReact
            iconSet={iconSet}
            icon={
              expanded
                ? 'Control-CloseCarat-Small-BlueOutline'
                : 'Control-OpenCarat-Small-BlueOutline'
            }
            size={10}
            color={variables.evantaBlue}
            className={generalStyles.ml20}
          />
        </button>
      </div>
    </div>
  )
}

ExpandableDiv.propTypes = {
  ...TypeProps
}

export default ExpandableDiv

import React from 'react'
import classNames from 'classnames'
import { isPastTime } from 'src/utils/helpers/GeneralHelpers'
import {
  otherEvaluationsCompletedInTimeblock,
  p2PSessionCheckForScheduledMeetings
} from 'src/utils/helpers/SessionHelperMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import SessionRegButton from './SessionRegButton'
import FeedbackButton from './FeedbackButton'

const AgendaDetailHeader = ({ currentEvent, session }) => {
  const { attendee, currentUser, sessions } = useGlobalContext()

  const P2PSessionCheckForMeetings = (session) => {
    // only return p2p session eval button if there is at at least one meeting scheduled
    if (session.session_type_key === 'session_type_one_to_one_meeting') {
      return p2PSessionCheckForScheduledMeetings(session)
    } else {
      return true
    }
  }

  const renderActionButton = () => {
    if (isPastTime(session.epoch_start_time)) {
      if (
        (!otherEvaluationsCompletedInTimeblock(sessions, session) ||
          session.evaluation_completed) &&
        P2PSessionCheckForMeetings(session) &&
        currentUser?.can_take_session_survey
      ) {
        return (
          <FeedbackButton session={session} />
        )
      }
    } else {
      return (
        <SessionRegButton
          attendee={attendee}
          currentEvent={currentEvent}
          detailView={false}
          session={session}
        />
      )
    }
  }

  return (
    <div>
      <div>
        <span className={fontStyles.h6demigrey}>{session?.session_type}</span>
      </div>

      <div className={generalStyles.mt10}>
        <span className={fontStyles.bodybolddark}>{session?.name}</span>
      </div>

      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6demigrey}>{session?.room_name}</span>
      </div>
      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6variant2demigrey}>
          {session?.formatted_display_date} | {session?.formatted_display_time}
        </span>
      </div>
      <div className={classNames(generalStyles.mt20, generalStyles.mb10)}>
        <HorizontalLine color={'lightGrey'} fullWidth={true} />
      </div>
      <div
        className={classNames(generalStyles.mb10, generalStyles.minHeight35)}
      >
        {attendee && attendee?.is_approved && renderActionButton()}
      </div>
      <HorizontalLine color={'lightGrey'} fullWidth={true} />
    </div>
  )
}

export default AgendaDetailHeader

import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Button from 'src/components/Elements/Button'
import useUpdateEventRegStatus from 'src/queries/events/useUpdateEventRegStatus'

const RegQuestions = ({ closeModal, data }) => {
  const {
    questions
  } = data
  const { mutate } = useUpdateEventRegStatus()
  const googleTracking = useAnalyticsTracking()
  const [ibmMarketingConsent, setIbmMarketingConsent] = useState(false)
  const [ibmMarketingConsentCheckbox, setIbmMarketingConsentCheckbox] = useState([])
  const [ibmPrivacyPolicyCheckbox, setIbmPrivacyPolicyCheckbox] = useState([])
  const [multipleChoice, setMultipleChoice] = useState(null)
  const [checkBoxes, setCheckBoxes] = useState([])
  const radioOptions = questions.find(
    (q) => q.survey_question_mapping.key === 'casl'
  )?.question_options
  const [submitError, setSubmitError] = useState('')

  const onCheckboxesClick = (optionId, question, value) => {
    setCheckBoxes(
      checkBoxes.includes(optionId)
        ? [...checkBoxes].filter((a) => a !== optionId)
        : [...checkBoxes, optionId]
    )
    // add checkboxes to arrays, do not allow to submit if array is empty (no answers)
    if (question.survey_question_mapping.key === 'ibm_marketing_consent' && value) {
      setIbmMarketingConsentCheckbox(prev => [...prev, optionId])
    } else if (question.survey_question_mapping.key === 'ibm_marketing_consent' && !value) {
      setIbmMarketingConsentCheckbox(prev => prev.filter(option => option !== optionId))
    }
    if (question.survey_question_mapping.key === 'ibm_privacy_policy' && value) {
      setIbmPrivacyPolicyCheckbox(prev => [...prev, optionId])
    } else if (question.survey_question_mapping.key === 'ibm_privacy_policy' && !value) {
      setIbmPrivacyPolicyCheckbox(prev => prev.filter(option => option !== optionId))
    }
  }

  const submitHandler = useCallback(() => {
    if (!submitButtonInactive) {
      setSubmitError('Please answer all questions')
      return
    }
    const answers = questions?.reduce((obj, q) => {
      switch (q.survey_question_type.key) {
        case 'multiple_choice':
          obj[q.id] = q.question_options.find(
            (opt) => opt.name === multipleChoice
          )?.name
          break
        case 'checkboxes':
          obj[q.id] = q.question_options
            .filter((opt) => checkBoxes.includes(opt.id))
            ?.map((e) => e.name)
            ?.join(', ')
          break
        case 'checkbox':
          obj[q.id] = ibmMarketingConsent ? 'checked' : null
          break
        default:
          break
      }
      return obj
    }, {})
    mutate({
      regObj: {
        crm_contact_id: data.crm_contact_id,
        event_id: data.event_id,
        survey_user_id: data.survey_user_id,
        survey_answers: answers,
        current_event: data.current_event
      }
    })
    googleTracking(
      'additional_reg',
      data.current_event ? 'event_page' : 'app_home_page',
      {
        event_id: `${data.event_id}`,
        brand: data.brand,
        region: data.region,
        event_name: data.event_name,
        event_type: data.event_type
      }
    )
    setMultipleChoice(null)
    setIbmMarketingConsent(false)
    setCheckBoxes([])
    closeModal()
  }, [
    questions,
    ibmMarketingConsent,
    multipleChoice,
    checkBoxes,
    data
  ])

  const renderQuestions = () => {
    return data.questions.map((question, index) => {
      const questionText = (
        <div dangerouslySetInnerHTML={{ __html: question.question_text }}/>
      )
      let questionContents = null
      switch (question.survey_question_type.key) {
        case 'multiple_choice':
          questionContents = (
            <div className={generalStyles.flexColumn} data-testid={`answer${index}`}>
              {radioOptions.map((opt) => (
                <label
                  key={opt.id}
                  className={classNames(
                    fontStyles.bodybolddark,
                    generalStyles.pt20
                  )}
                >
                  <input
                    type="radio"
                    key={opt.id}
                    id={opt.id}
                    name={opt.name}
                    checked={multipleChoice === opt.name}
                    value={opt.name}
                    onChange={() => setMultipleChoice(opt.name)}
                    className={generalStyles.mr20}
                  />
                  {opt.name}
                </label>
              ))}
            </div>
          )
          break
        case 'checkboxes':
          questionContents = (
            <>
              {question.question_options.map((opt) => (
                <div
                  key={opt.id}
                  className={classNames(
                    generalStyles.flexRow,
                    generalStyles.mb20
                  )}
                  data-testid={`answer${index}`}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => onCheckboxesClick(opt.id, question, e.target.checked)}
                    value={checkBoxes.includes(opt.id)}
                    id={opt.id}
                  />
                  <span className={fontStyles.bodybolddark}>{opt.name}</span>
                </div>
              ))}
            </>
          )
          break
        case 'checkbox':
          questionContents = (
            <div
              className={classNames(generalStyles.flexRow, generalStyles.mb20)}
            >
              <input
                type="checkbox"
                onChange={setIbmMarketingConsent}
                value={ibmMarketingConsent}
              />
              <span className={fontStyles.bodybolddark}>{question.name}</span>
            </div>
          )
          break
        default:
          questionContents = <></>
          break
      }
      return (
        <div key={question.id} data-testid={`${question.survey_question_type.key}_question-${index}`}>
          <span className={fontStyles.bodyregulargrey} data-testid='questionBody'>{questionText}</span>
          {questionContents}
        </div>
      )
    })
  }

  const ibmConsent = () => {
    if (questions?.find((q) => q.survey_question_mapping.key === 'ibm_privacy_policy')?.is_required === true) {
      return (questions?.some((q) => q.survey_question_mapping.key === 'ibm_privacy_policy') && ibmPrivacyPolicyCheckbox.length > 0)
    } else { return true }
  }

  const ibmPrivacy = () => {
    if (questions?.find((q) => q.survey_question_mapping.key === 'ibm_marketing_consent')?.is_required === true) {
      return (questions?.some((q) => q.survey_question_mapping.key === 'ibm_marketing_consent') && ibmMarketingConsentCheckbox.length > 0)
    } else { return true }
  }

  const casl = () => {
    if (questions?.find((q) => q.survey_question_mapping.key === 'casl')?.is_required === true) {
      return (questions?.some((q) => q.survey_question_mapping.key === 'casl') && multipleChoice !== null)
    } else { return true }
  }

  // check for types casl, ibm_privacy_policy, ibm_marketing_consent
  const submitButtonInactive = (
    (questions?.length > 0) &&
    ibmPrivacy() &&
    ibmConsent() &&
    casl()
  )

  return (
    <>
      {renderQuestions()}
      <div className={generalStyles.mt40}>
        <p className={classNames(fontStyles.h5italicred)}>{submitError}</p>
        <Button
          color={'evantaBlue'}
          onClick={submitHandler}
          label={'Submit'}
          size={'med'}
        />
      </div>
    </>
  )
}

export default RegQuestions

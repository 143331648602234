import React from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useGlobalContext } from 'src/stores/GlobalContext'
import Button from 'src/components/Elements/Button'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'

const FeedbackButton = ({ session }) => {
  const { setSidePanelDetails } = useGlobalContext()

  const openEval = () => {
    session.session_type_key === 'session_type_one_to_one_meeting'
      ? setSidePanelDetails({ type: 'p2p_evaluation', data: session.id })
      : setSidePanelDetails({ type: 'evaluation', data: session.id })
  }

  if (isEmpty(session) || session.registration_status === 'not_authorized') return <></>

  return (
    <Button
      color={session.evaluation_completed ? 'evantaBlueOutline' : 'evantaBlue'}
      onClick={() => openEval()}
      label={
        session?.evaluation_completed
          ? (
            <div
              className={classNames(
                generalStyles.flexRow,
                generalStyles.alignVertical
              )}
            >
              <span>Feedback Given</span>
              <IcomoonReact
                icon='Control-TickIcon-Medium-BlueOutline'
                iconSet={iconSet}
                color={variables.evantaBlue}
                size={11}
                className={generalStyles.ml10}
              />
            </div>
          )
          : (
            'Give Feedback'
          )
      }
      size='med'
      disabled={session.evaluation_completed}
    />
  )
}

export default FeedbackButton

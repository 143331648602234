import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { useToastAlert } from 'src/stores/ToastContext'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import fabStyles from './fabStyles.module.scss'

const FabContent = ({ fab }) => {
  const navigate = useNavigate()
  const { setAlert } = useToastAlert()
  const { eventId } = useParams()

  const onPressFab = (action) => {
    if (action?.key) {
      let url = `${action?.data}`
      if (
        action?.key === 'session_registration' ||
        action?.key === 'session_evaluation'
      ) {
        navigate(`/programs/${eventId}/agenda`)
      } else {
        if (action?.key === 'peer_to_peer') {
          const callbackUrl = window.location
          url += `/profile/wizard?wizard_from=web&callback=${callbackUrl}`
        }
        window.open(url, '_blank')
      }
    } else {
      setAlert({
        message: 'Unable to open floating action button item. Please try again later.',
        status: 0,
        title: 'Error',
        forceLogout: false
      })
    }
  }

  return (
    <div className={classNames(generalStyles.mt20, generalStyles.flexColumn)}>
      {fab.map((f) => {
        return (
          <button
            key={f.key}
            onClick={() => onPressFab(f)}
            className={classNames(
              generalStyles.pt10,
              generalStyles.pb10,
              generalStyles.leftAlignedText,
              generalStyles.fullFlex
            )}
          >
            <div
              className={classNames(
                generalStyles.flexRow,
                generalStyles.alignVertical,
                generalStyles.flexApart
              )}
            >
              <div
                className={classNames(
                  generalStyles.flexRow,
                  generalStyles.alignVertical
                )}
              >
                <div className={generalStyles.mr20}>
                  <IcomoonReact
                    iconSet={iconSet}
                    color={variables.solar}
                    size={15}
                    icon={f.icon}
                  />
                </div>
                <span
                  className={classNames(
                    fontStyles.bodydemigrey,
                    fontStyles.hoverable
                  )}
                >
                  {f.text}
                </span>
              </div>
              <div className={fabStyles.clickContainer}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={12}
                  icon={'Control-ForwardCarat-Small-RedOutline'}
                />
              </div>
            </div>
          </button>
        )
      })}
    </div>
  )
}

export default FabContent

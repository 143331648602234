import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import programStyles from '../programStyles.module.scss'

const EventCardSkeleton = ({ largerHeight, eventCardType, currentEvent }) => (
  <div
    className={classNames(programStyles.eventCardContainer, {
      [programStyles.largerHeight]: largerHeight
    })}
  >
    <div
      className={classNames(
        generalStyles.flexRow,
        generalStyles.alignVertical,
        generalStyles.flexApart
      )}
    >
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.alignVertical
        )}
      >
        <div className={programStyles.fakeChip} />
        <div
          className={classNames(
            programStyles.skeletonDate,
            generalStyles.ml20
          )}
        />
      </div>
      {eventCardType !== 'Up Next' && (
        <div className={programStyles.fakeAlertBtnContainer} />
      )}
    </div>
    <div
      className={classNames(
        programStyles.eventName,
        generalStyles.flexColumn
      )}
    >
      <div className={programStyles.skeletonName} />

      <div
        className={classNames(
          programStyles.skeletonName,
          programStyles.smaller,
          generalStyles.mt05
        )}
      />
      {currentEvent && (
        <div
          className={classNames(
            programStyles.skeletonDate,
            programStyles.white,
            generalStyles.mt10
          )}
        />
      )}
    </div>

    <div
      className={classNames(generalStyles.flexRow, generalStyles.flexApart)}
    >
      <div className={programStyles.fakeRegBtn} />
      {eventCardType !== 'Up Next' && (
        <div
          className={classNames(
            programStyles.fakeAlertBtnContainer,
            programStyles.white
          )}
        />
      )}
    </div>
  </div>
)

export default EventCardSkeleton

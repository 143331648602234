import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import ExchangeBusinessCardButton from 'src/components/Elements/ExchangeBusinessCardButton'
import useFetchSponsors from 'src/queries/sponsors/useFetchSponsors'
import partnerStyles from './partnerStyles.module.scss'

const PartnerList = () => {
  const navigate = useNavigate()
  const { eventId, currentUser: user, attendees, currentEvent } = useGlobalContext()
  const { sponsors } = useFetchSponsors()
  const filteredPartners = useMemo(() => sponsors.filter(sponsor => !sponsor.is_hidden), [sponsors])
  const currentUser = attendees?.find((a) => a.user_id === user?.id)

  const showScanner = currentUser?.allow_event_prize && currentEvent?.is_attended

  const sections = [
    ...new Set(
      filteredPartners.map((p) => {
        return p.sponsor_type_name
      })
    )
  ].map((title) => {
    const list = filteredPartners.filter((p) => {
      return title === p.sponsor_type_name
    })
    return {
      key: title?.toLowerCase()?.replaceAll(' ', '-'),
      title,
      data: {
        list,
        twoColumn: list[0].sponsor_type_logo_size === 3
      }
    }
  })

  const renderPartner = (item, column) => {
    return (
      <button
        key={item.id}
        className={classNames(partnerStyles.partnerLogoContainer, {
          [partnerStyles.twoColumn]: column,
          [partnerStyles.primaryPartners]: item.sponsor_type_logo_size === 1,
          [partnerStyles.secondaryPartners]: item.sponsor_type_logo_size === 2
        })}
        onClick={() => navigate(`/programs/${eventId}/partners/${item?.id}`)}
      >
        <img
          className={partnerStyles.logo}
          src={item.logo}
          alt={`${item.name} Logo`}
        />
      </button>
    )
  }

  if (!filteredPartners.length) {
    return (
      <div className={generalStyles.flexCentered}>
        <span>This program is unsponsored at this time.</span>
      </div>
    )
  }

  return (
    <div className={classNames(generalStyles.thirdWidth, generalStyles.pb50)}>
      <div className={generalStyles.mb20}>
        {showScanner && <ExchangeBusinessCardButton
          label="Visit the Networking Lounge and"
          sublabel="Scan to Win"
          isSponsor
        />}
      </div>
      {sections?.map((section) => {
        const partnerList = section.data
        return (
          <div key={section.key} className={partnerStyles.partnerSection}>
            <span className={fontStyles.h2alternate2bolddark}>
              {section?.title}
            </span>
            <div className={classNames(generalStyles.mb20, generalStyles.mt20)}>
              <HorizontalLine color={'lightGrey'} fullWidth={true} />
            </div>
            <div className={classNames(partnerStyles.partnerList)}>
              {partnerList.list.map((partner) => {
                return renderPartner(partner, partnerList.twoColumn)
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PartnerList

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { postData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postUpdate = async (params) => {
  const { regObj } = params
  const response = await postData('/my_evanta/register', { registrant: { ...regObj }, web: true })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUpdateEventRegStatus = () => {
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: postUpdate,
    onSuccess: (data) => {
      if (eventId) {
        queryClient.invalidateQueries({ queryKey: [`event_${eventId}`] })
      }
      queryClient.invalidateQueries({ queryKey: ['upcoming_events'] })
    },
    onError: (error) => {
      const message = error.code === 422 ? 'Registration already submitted. Please refresh the page.' : error.info || error.text
      setAlert({
        status: error.code,
        title: error.message,
        message,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useUpdateEventRegStatus

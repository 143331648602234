import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchData } from 'src/api/api-utilities'

const fetchSpeakers = async (eventId) => {
  const response = await fetchData('/my_evanta/speakers/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchSpeakers = () => {
  const { eventId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: [`speakers_${eventId}`],
    queryFn: () => fetchSpeakers(eventId),
    enabled: !!eventId,
    staleTime: Infinity,
    retry: false
  })

  const speakers = isEmpty(data) ? [] : data.speakers?.filter((speaker) => speaker.is_speaker)
  return {
    speakers,
    isLoadingSpeakers: isLoading
  }
}

export default useFetchSpeakers

import React from 'react'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import programStyles from '../programStyles.module.scss'

const CarouselCard = ({
  carouselKey,
  imageUrl,
  blockTitle,
  slideTitle,
  slideBody
}) => {
  return (
    <div key={carouselKey} className={generalStyles.mb20} data-testid={carouselKey} >
      <div className={programStyles.carouselCardContainer}>
        <div className={programStyles.header}>
          <div className={generalStyles.mr20}>
            <img className={programStyles.image} src={imageUrl} />
          </div>
          <div className={generalStyles.flexColumn}>
            <div className={generalStyles.mb10}>
              <span className={fontStyles.h6demigrey} data-testid='expectBlockHeader'>{blockTitle}</span>
            </div>
            <span className={fontStyles.h3alternate2bolddark} data-testid='expectBlockTitle'>
              {slideTitle}
            </span>
          </div>
        </div>
        <div className={classNames(generalStyles.mt20, generalStyles.mb10)}>
          <HorizontalLine color={'white'} fullWidth={true} />
        </div>
        <span className={fontStyles.bodyregulardark} data-testid='expectBlockBody'>{slideBody}</span>
      </div>
    </div>
  )
}

export default CarouselCard

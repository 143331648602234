import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const postOneOnOneMeetingRank = async (params, config) => {
  const response = await postData('/virtual_portal/rank_event_one_on_one_meeting_wishlists',
    {
      event_one_on_one_meeting_wishlists: params
    },
    config
  )
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useRankOneOnOneMeeting = () => {
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => postOneOnOneMeetingRank(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_meeting_wishlist'] })
    }
  })

  return { mutateAsync, mutate }
}

export default useRankOneOnOneMeeting

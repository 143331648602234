import React from 'react'
import NoUserDisplay from 'src/features/programs/program/users/components/NoUserDisplay'
import useFetchGbs from 'src/queries/gbs/useFetchGbs'
import UserList from './UserList'

const GoverningBodyIndex = () => {
  const { gbs } = useFetchGbs()
  const userType = 'Governing Body Members'
  if (!gbs.length) {
    return <NoUserDisplay userType={userType} />
  }
  return <UserList users={gbs} userType={userType} />
}

export default GoverningBodyIndex

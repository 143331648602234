import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { postData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postBizCardExchange = async (params) => {
  const response = await postData('/my_evanta/qr_scanner/', { user_business_card: { ...params } })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const usePostBizCardExchange = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { search } = useLocation()
  const PATH = `/programs/${eventId}`
  const route = search.replace('?', '')
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: postBizCardExchange,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['attendees'])
      navigate(`${PATH}/${route}`)
      if (data.status === 201) {
        setAlert({
          status: data.status,
          title: 'Success',
          message: data.data.user_business_card?.success || 'Your scanned connection was successful.',
          forceLogout: false
        })
      }
    },
    onError: (error) => {
      navigate(`${PATH}/${route}`)
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default usePostBizCardExchange

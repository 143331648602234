import React, { useCallback, useState } from 'react'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import classNames from 'classnames'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'
import Button from 'src/components/Elements/Button'
import useUpdateProfile from 'src/queries/virtualPortal/useUpdateProfile'
import Modal from 'src/components/Elements/Modal'
import { useNavigate, useParams } from 'react-router-dom'

const Input = ({ label, disabled, value, errorMessage, required, textArea, onChange }) => {
  return (
    <div className={virtualPortalStyles.inputContainer}>
      <div className={generalStyles.flexRow}>
        <span className={fontStyles.bodydemigrey}>{label}</span>
        {required && <span className={fontStyles.bodyregularred}> *</span>}
      </div>
      {textArea
        ? <textarea onChange={onChange} value={value} />
        : <input className={virtualPortalStyles.input} onChange={onChange} value={value} disabled={disabled} />}
      {errorMessage && <span className={fontStyles.bodyregularred}>{errorMessage}</span>}
    </div>
  )
}

const Details = ({ surveyUser }) => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { mutate } = useUpdateProfile()

  const [form, setForm] = useState({
    ...surveyUser
  })
  const [openModal, setOpenModal] = useState(false)

  const changeForm = useCallback((value, field) => {
    setForm({
      ...form,
      [field]: value
    })
  }, [form, setForm])

  const onSubmit = () => {
    if (!form.first_name || !form.last_name) {
      setOpenModal(true)
    } else {
      mutate(form)
      setOpenModal(false)
    }
  }

  return (
    <div>
      <h3 style={{ fontWeight: 'normal' }} className={classNames(fontStyles.h2alternate2bolddark, generalStyles.mt30)}>
    Update your profile details globally
      </h3>
      <p className={fontStyles.bodydemigrey}>Please update your profile here and add a short biography. Your profile will be visible to peers and participants in the summit. This will update your profile across all Evanta events.</p>
      <div>
        <p className={fontStyles.bodydemigrey}>Profile Photo:</p>
        <div className={classNames(generalStyles.flexRow, generalStyles.alignVertical)}>
          <img className={virtualPortalStyles.userPhoto} src={surveyUser.photo} alt="user photo" />
          <p
            style={{ cursor: 'pointer' }}
            className={fontStyles.bodydemiblue}
            onClick={() => navigate(`/${token}/edit_profile/wizard`)}
          >
              Edit profile photo
          </p>
        </div>
      </div>
      <Input
        label="Corporate Email Address:"
        disabled
        value={form.email}
        errorMessage="This field cannot be changed. To update, please send an email to tiffany.clarke@evanta.com"
      />
      <Input
        label="First Name:"
        value={form.first_name}
        required
        onChange={(e) => changeForm(e.target.value, 'first_name')}
      />
      <Input
        label="Last Name:"
        value={form.last_name}
        required
        onChange={(e) => changeForm(e.target.value, 'last_name')}
      />
      <Input
        label="Title:"
        value={form.title}
        onChange={(e) => changeForm(e.target.value, 'title')}
      />
      <Input
        label="Organization:"
        value={form.organization_name}
        onChange={(e) => changeForm(e.target.value, 'organization_name')}
      />
      <Input
        label="Biography:"
        value={form.bio}
        textArea
        onChange={(e) => changeForm(e.target.value, 'bio')}
      />
      <div className={virtualPortalStyles.detailsFooter}>
        <Button onClick={onSubmit} label="SAVE & CONTINUE" color="solar" size="med" />
      </div>
      {openModal && <Modal isOpen={openModal}>
        <h1>Error</h1>
        <p>First Name and Last Name are required.</p>
        <div className={generalStyles.flexCentered}>
          <Button
            color="solar"
            onClick={() => setOpenModal(false)}
            label="CLOSE"
            size='med'
          />
        </div>
      </Modal>}
    </div>
  )
}

export default Details

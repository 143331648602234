import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { isEmpty } from 'lodash'
import { checkPathPresent } from 'src/utils/helpers/GeneralHelpers'
import { useGlobalContext } from 'src/stores/GlobalContext'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import navStyles from './navigation.module.scss'

const BackNavigation = ({ showScannerButtons }) => {
  const navigate = useNavigate()
  const { currentEvent } = useGlobalContext()
  const { pathname, search } = useLocation()
  const scannerActive = pathname.includes('scanner')
  const qrActive = pathname.includes('qr-code')

  const PATH = `/programs/${currentEvent.id}`

  const handleBackClick = () => {
    if (checkPathPresent(['scanner', 'qr-code'], pathname)) {
      if (isEmpty(search)) {
        navigate(`${PATH}`)
      } else if (search === '?attendees') {
        navigate(`${PATH}/attendees`)
      } else if (search === '?partners') {
        navigate(`${PATH}/partners`)
      } else {
        navigate(-1)
      }
    } else {
      navigate(-1)
    }
  }

  return (
    <div
      className={classNames(
        navStyles.headerNavContainer,
        navStyles.backNavContainer
      )}
    >
      <button
        className={navStyles.backButton}
        onClick={() => handleBackClick()}
      >
        <IcomoonReact
          iconSet={iconSet}
          color={variables.evantaBlue}
          size={16}
          icon="Control-BackCarat-Large-GreyOutline"
        />
      </button>
      { showScannerButtons &&
        <>
          <div className={classNames(navStyles.navItemContainer, { [navStyles.active]: qrActive })}>
            <button
              className={classNames(navStyles.iconContainer,
                { [navStyles.iconContainerActive]: qrActive }
              )}
              data-title="My QR Code"
              onClick={() => navigate(`/programs/${currentEvent.id}/qr-code${search || ''}`)}
            >
              <IcomoonReact iconSet={iconSet}
                color={qrActive ? variables.evantaBlue : variables.darkGrey}
                size={21}
                icon={
                  qrActive
                    ? 'Symbol-QR-Icon-Large-Blue'
                    : 'Symbol-QRIcon-Large-Grey'
                }
              />
            </button>
          </div>
          {currentEvent.is_attended &&
            <div className={classNames(navStyles.navItemContainer, { [navStyles.active]: scannerActive })}>
              <button
                className={classNames(navStyles.iconContainer,
                  { [navStyles.iconContainerActive]: scannerActive }
                )}
                data-title="Scanner"
                onClick={() => navigate(`/programs/${currentEvent.id}/scanner${search || ''}`)}
              >
                <IcomoonReact iconSet={iconSet}
                  color={scannerActive ? variables.evantaBlue : variables.darkGrey}
                  size={21}
                  icon={
                    scannerActive
                      ? 'Symbol-ScannerIcon-Large-Blue'
                      : 'Symbol-ScannerIcon-Large-Grey'
                  }
                />
              </button>
            </div>
          }
        </>
      }
    </div>
  )
}

export default BackNavigation

// Back nav ONLY
// - Attendee detail
// - Speaker detail
// - Governing Body detail
// - Partner detail
// - Leaderboard ranking
// - Event evaluation

import React, { useState } from 'react'
import {
  QueryClient,
  QueryClientProvider,
  QueryCache
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes/Routes'
import { useToastAlert } from './stores/ToastContext'

const Store = () => {
  const { setAlert } = useToastAlert()
  const [queryClient] = useState(() => new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        setAlert({
          status: error.code,
          title: error.message,
          message: error.info || error.text,
          forceLogout: error.forceLogout
        })
      }
    })
  }))

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <RouterProvider router={router}>
      </RouterProvider>
    </QueryClientProvider>
  )
}

export default Store

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const postOneOnOneMeeting = async (params, config) => {
  const response = await postData('/virtual_portal/create_event_one_on_one_meeting_wishlists',
    {
      event_one_on_one_meeting_wishlist: { ...params }
    },
    config
  )
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useCreateOneOnOneMeetingWishlist = () => {
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => postOneOnOneMeeting(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist_users'] })
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_meeting_wishlist'] })
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useCreateOneOnOneMeetingWishlist

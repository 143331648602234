import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { putData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postMessageRead = async (params) => {
  const { otherUserId } = params
  const response = await putData(`/my_evanta/mark_messages_read/${otherUserId}`)
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useMarkMessagesRead = () => {
  const { eventId } = useParams()
  const { setAlert } = useToastAlert()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: postMessageRead,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [`messages_${eventId}`] })
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useMarkMessagesRead

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import classNames from 'classnames'
import QrReader from 'react-qr-scanner'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useToastAlert } from 'src/stores/ToastContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Loader from 'src/components/Elements/Loader'
import AlertModal from 'src/components/Elements/AlertModal'
import usePostBizCardExchange from 'src/queries/attendees/usePostBizCardExchange'
import userStyles from './userStyles.module.scss'
import BusinessCardAgreement from './components/BusinessCardAgreement'

const Scanner = () => {
  const isMobileSize = useIsMobileQuery()
  const navigate = useNavigate()
  const { currentEvent, eventId, currentEventBizCardAccess } = useGlobalContext()
  const { setAlert } = useToastAlert()
  const { mutate } = usePostBizCardExchange()
  const [cameraOn, setCameraOn] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (currentEventBizCardAccess) {
      setCameraOn(true)
    }
  }, [currentEventBizCardAccess])

  useEffect(() => {
    if (!currentEventBizCardAccess) {
      setOpenModal(true)
    }
  }, [eventId])

  const closeModal = useCallback(() => {
    setOpenModal(false)
    navigate(-1)
  }, [])

  const handleBarCodeScanned = (data) => {
    if (data) {
      mutate({ business_card_hash: data.text, event_id: currentEvent.id })
    }
  }

  return (
    <>
      <div
        className={classNames(
          generalStyles.alignVertical,
          generalStyles.flexColumn,
          { [generalStyles.mr40]: !isMobileSize }
        )}
      >
        <div className={userStyles.cameraContainer}>
          <div className={userStyles.loaderContainer}>
            <Loader />
          </div>
          {cameraOn &&
            <QrReader
              delay={100}
              className={classNames(userStyles.camera, { [userStyles.mobile]: isMobileSize })}
              onError={(error) => {
                setAlert({
                  status: 500,
                  message: error,
                  title: 'Error',
                  forceLogout: false
                })
              }}
              onScan={handleBarCodeScanned}
            />
          }
        </div>
      </div>
      {openModal &&
      <AlertModal title='Virtual Business Card Exchange' closeModal={closeModal}>
        <BusinessCardAgreement closeModal={() => setOpenModal(false)} />
      </AlertModal>
      }
    </>
  )
}

export default Scanner

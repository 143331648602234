import React from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import messageStyles from '../messageStyles.module.scss'
import { useGlobalContext } from 'src/stores/GlobalContext'

const MessageMobileBackBtn = () => {
  const { resetSidePanelDetails } = useGlobalContext()
  return (
    <div className={messageStyles.mobileBackNav}>
      <button
        className={classNames(messageStyles.backButton)}
        onClick={() => resetSidePanelDetails()}
      >
        <IcomoonReact
          iconSet={iconSet}
          color={variables.evantaBlue}
          size={16}
          icon="Control-BackCarat-Large-GreyOutline"
        />
      </button>
    </div>
  )
}

export default MessageMobileBackBtn

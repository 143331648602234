import React, { useState, useEffect } from 'react'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { isEmpty } from 'lodash'
import { scheduledSortedOneOnOneMeetings } from 'src/utils/helpers/SessionHelperMethods'
import useUpdateP2PSessionEvalStatus from 'src/queries/sessions/useUpdateP2PSessionEvalStatus'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import CommentInput from './components/Evaluation/CommentInput'
import UserEvaluation from './components/Evaluation/UserEvaluation'

const P2PEvaluation = ({ session, formSubmitted, setFormValid }) => {
  const { eventId, attendees, resetSidePanelDetails, sessions } = useGlobalContext()
  const { mutate } = useUpdateP2PSessionEvalStatus()
  const oneOnOneMtgs = scheduledSortedOneOnOneMeetings(sessions.find(s => s.id === session.id))

  const [userRatingList, setUserRatingList] = useState(
    oneOnOneMtgs?.map((m) => {
      return { [m.participant_user_id]: 0 }
    })
  )

  const [userCommentList, setUserCommentList] = useState(
    oneOnOneMtgs?.map((m) => {
      return { [m.participant_user_id]: '' }
    })
  )
  const updateUserCommentList = (commentText, userId) => {
    const index = userCommentList?.findIndex(
      (l) => Object.keys(l)[0] === userId.toString()
    )
    const newList = [...userCommentList]
    newList[index][userId] = commentText
    setUserCommentList(newList)
  }

  const updateUserRatingList = (number, userId) => {
    const index = userRatingList?.findIndex(
      (l) => Object.keys(l)[0] === userId.toString()
    )
    const newList = [...userRatingList]
    newList[index][userId] = number
    setUserRatingList(newList)
  }
  /// Form Handling
  // Reset Form on Session Change
  useEffect(() => {
    setUserRatingList(
      oneOnOneMtgs?.map((m) => {
        return { [m.participant_user_id]: 0 }
      })
    )
    setUserCommentList(
      oneOnOneMtgs?.map((m) => {
        return { [m.participant_user_id]: '' }
      })
    )
  }, [session])

  // Validate Submit Button
  useEffect(() => {
    // If Mtg Participants, Required
    const usersRatingCompleted =
      userRatingList?.length > 0
        ? !userRatingList?.some((l) => Object.values(l)[0] === 0)
        : true
    setFormValid(usersRatingCompleted)
  }, [userRatingList])

  const handleFormData = async (formData) => {
    await mutate({ data: formData, sessionId: session.id })
    resetSidePanelDetails()
  }

  useEffect(() => {
    if (formSubmitted) {
      const formData = {
        event_one_on_one_meeting_surveys: oneOnOneMtgs.map((m) => {
          return {
            event_one_on_one_meeting_id: m.id,
            rating:
              userRatingList?.[
                userRatingList?.findIndex(
                  (l) => Object.keys(l)[0] === m.participant_user_id.toString()
                )
              ]?.[m.participant_user_id],
            comments:
              userCommentList?.[
                userCommentList?.findIndex(
                  (l) => Object.keys(l)[0] === m.participant_user_id.toString()
                )
              ]?.[m.participant_user_id]
          }
        })
      }

      handleFormData(formData)
    }
  }, [formSubmitted])

  const renderUserRatingsAndComments = () => {
    if (oneOnOneMtgs?.length > 0) {
      return (
        <>
          <span key="userHeader" className={fontStyles.bodybolddark}>
            How would you rate your meeting(s) during this session?
          </span>
          {oneOnOneMtgs.map((mtg, i, oneOnOneMtgs) => {
            const user = attendees.find(
              (f) => f?.user_id === mtg?.participant_user_id
            )

            if (isEmpty(user)) return <></>
            return (
              <div
                key={`mtg-eval-${mtg?.id || Math.random()}`}
                className={generalStyles.mt20}
              >
                <div>
                  <span className={fontStyles.bodybolddark}>
                    {mtg.time_block}
                  </span>
                </div>

                <UserEvaluation
                  eventId={eventId}
                  people={oneOnOneMtgs}
                  user={user}
                  updateRatingMethod={updateUserRatingList}
                  ratingId={mtg?.participant_user_id}
                  userType={'speaker'}
                  userRating={
                    userRatingList?.[
                      userRatingList?.findIndex(
                        (l) => Object.keys(l)[0] === user?.user_id.toString()
                      )
                    ]?.[user?.user_id]
                  }
                />
                <CommentInput
                  commentText={
                    userCommentList?.[
                      userCommentList?.findIndex(
                        (l) => Object.keys(l)[0] === user?.user_id.toString()
                      )
                    ]?.[user?.user_id]
                  }
                  updateCommentMethod={updateUserCommentList}
                  commentId={user?.user_id}
                />
              </div>
            )
          })}
        </>
      )
    }
  }

  return (
    <div key="session-eval" className={generalStyles.pb40}>
      {renderUserRatingsAndComments()}
    </div>
  )
}

export default P2PEvaluation

import React from 'react'
import { isEmpty } from 'lodash'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import useFetchSessionsFromToken from 'src/queries/virtualPortal/useFetchSessionsFromToken'
import useFetchSurveyUser from 'src/queries/virtualPortal/useFetchSurveyUser'
import Tabs from './Tabs'
import Sessions from './Sessions'
import BlueHeader from '../wizard/BlueHeader'
import Footer from '../wizard/components/Footer'

const VirtualPortal = () => {
  const { surveyUser } = useFetchSurveyUser()
  const { sessions, isLoading } = useFetchSessionsFromToken()

  if (isEmpty(surveyUser)) return <></>

  return (
    <div>
      <BlueHeader event={surveyUser?.event} />
      <div style={{ margin: '0px auto 120px', width: '60%' }}>
        <div style={{ margin: '20px 0px' }}>
          <h1 style={{ margin: 0 }}>Welcome {surveyUser.user.full_name ?? ''}</h1>
          <h6 style={{ margin: 0, letterSpacing: '0.88px' }} className={fontStyles.h4demigrayuppercase}>{surveyUser.user.title ?? ''}, {surveyUser.user.organization_name ?? ''}</h6>
        </div>
        <Tabs />
        <p className={fontStyles.bodydemigrey}>
      Welcome to your Virtual Event Portal, your one-stop access to all virtual sessions. Please make sure to select the session(s) you would like to participate in via the add to my schedule button. This will secure your spot and enable you to join each session 5 minutes prior to the start time.
        </p>
        <Sessions sessions={sessions} isLoading={isLoading} />
      </div>
      <Footer />
    </div>
  )
}

export default VirtualPortal

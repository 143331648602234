import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRollbarPerson } from '@rollbar/react'
import { Provider } from 'src/stores/GlobalContext'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import MainNavigation from 'src/features/navigation/MainNavigation'
import MobileNavigation from 'src/features/navigation/MobileNavigation'
import useFetchCurrentUser from 'src/queries/currentUser/useFetchCurrentUser'
import layoutStyles from './layouts.module.scss'
import LeftPanelLayout from './LeftPanelLayout'
import RightPanelLayout from './RightPanelLayout'
import useFetchEvent from 'src/queries/events/useFetchEvent'

const restrictedPathsAttended = [
  'speakers',
  'governing-body'
]

const restrictedPathsUnattended = [
  'attendees',
  'leaderboard',
  'messages'
]

const MainLayout = () => {
  const { currentUser } = useFetchCurrentUser()
  const { currentEvent } = useFetchEvent()
  const location = useLocation()
  const isMobileSize = useIsMobileQuery()
  const navigate = useNavigate()
  const isP2P = location.pathname.includes('wizard') || location.pathname.includes('virtual')

  useRollbarPerson(currentUser)
  useEffect(() => {
    if (isMobileSize) {
      // This is the fix for https://www.pivotaltracker.com/story/show/186351792
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    const path = location.pathname.split('/').pop()
    const restrictedPaths = currentEvent.is_attended ? restrictedPathsAttended : restrictedPathsUnattended
    if (restrictedPaths.includes(path) && !isEmpty(currentEvent)) {
      navigate(`/programs/${currentEvent.id}`)
    }
  }, [location, currentEvent])

  return (
    <Provider>
      <div
        className={
          isP2P || isMobileSize ? undefined : layoutStyles.mainLayoutContainer
        }
      >
        {isP2P || isMobileSize
          ? undefined
          : (
            <div className={layoutStyles.mainNavContainer}>
              <MainNavigation />
            </div>
          )
        }
        {isMobileSize && <MobileNavigation />}
        {location.pathname.includes('/messages') ||
        location.pathname.includes('/search')
          ? <LeftPanelLayout />
          : <RightPanelLayout isP2P={isP2P} />
        }
      </div>
    </Provider>
  )
}

export default MainLayout

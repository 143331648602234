import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import { FormUtilities } from 'src/utils/helpers/FormUtilities'
import { postData } from 'src/api/api-utilities'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Input from 'src/components/Elements/Input'
import Button from 'src/components/Elements/Button'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import preloginStyles from './prelogin.module.scss'

const ForgotPassword = () => {
  const [formCompleted, setFormCompleted] = useState(false)
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/auth/signin')
  }
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      email: ''
    }
  })

  const submitEmailForm = async (data) => {
    try {
      Cookies.set('email', data.email)
      await postData('/users/password', {
        user: { email: data.email }
      })
      setFormCompleted(true)
    } catch (error) {
      // this route returns no errors
      console.log(error)
    }
  }

  const renderForm = () => {
    return (
      <>
        <div>
          <div className={generalStyles.mb30} data-testid="header">
            <span className={fontStyles.h1regulardark}>Password Reset</span>
          </div>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true
              },
              pattern: {
                value: FormUtilities.EMAIL_REGEX
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                id="email"
                label={{ label: 'Email', placeholder: 'Email' }}
                name="email"
                type="email"
                icon={{ name: 'Symbol-EmailIcon-Large-GreySolid', size: 27 }}
                onBlur={onBlur}
                value={value}
                onChange={(text) => onChange(text)}
                error={errors && errors?.email?.message}
              />
            )}
          />
        </div>
        <div data-testid="submit">
          <Button
            color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
            size="large"
            onClick={handleSubmit(submitEmailForm)}
            label="SUBMIT"
            disabled={!isValid}
          />
        </div>
      </>
    )
  }

  const renderConfirmation = () => {
    return (
      <>
        <div data-testid="resetConfirmation">
          <div className={generalStyles.mb30} data-testid="header">
            <span className={fontStyles.h1regulardark}>Request Submitted</span>
          </div>
          <div data-testid="body">
            <span className={fontStyles.bodydemigrey}>
            If you are a member, you will receive an email with the reset password link. If you do not receive an email, please contact us.
            </span>
          </div>
        </div>
        <div data-testid="returnBtn">
          <Button
            color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
            size="large"
            onClick={() => handleBackClick()}
            label="Return to Sign In"
            disabled={!isValid}
          />
        </div>
      </>
    )
  }

  return (
    <div key="forgotPasswordForm" className={preloginStyles.forgotPasswordForm}>
      <PreloginNavigationHeader
        back={!formCompleted}
        close={formCompleted}
        onClick={() => handleBackClick()}
      />
      <div
        className={classNames(
          preloginStyles.contentBodyContainer,
          generalStyles.flexApart
        )}
      >
        {formCompleted ? renderConfirmation() : renderForm()}
      </div>
    </div>
  )
}

export default ForgotPassword

import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import EventCardSkeleton from './components/EventCardSkeleton'
import programStyles from './programStyles.module.scss'

const ProgramsSkeleton = () => (
  <div className={generalStyles.thirdWidth}>
    <div className={generalStyles.mb40}>
      <EventCardSkeleton eventCardType='Up Next' largerHeight />
    </div>

    <div
      className={classNames(
        generalStyles.flexRow,
        generalStyles.flexApart,
        generalStyles.alignVertical,
        generalStyles.mb20
      )}
    >
      <div className={programStyles.fakeFilterBtn} />
      <div className={programStyles.fakeFilterText} />
    </div>

    {[...Array(2)].map((x, i) => {
      return (
        <div
          key={i}
          className={classNames(
            programStyles.upcomingContainer,
            generalStyles.mb20
          )}
        >
          <EventCardSkeleton />
        </div>
      )
    })}
  </div>
)

export default ProgramsSkeleton

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import { FormUtilities } from 'src/utils/helpers/FormUtilities'
import { deviceTextTemplate } from 'src/utils/helpers/DeviceUtilities'
import Input from 'src/components/Elements/Input'
import Loader from 'src/components/Elements/Loader'
import Button from 'src/components/Elements/Button'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import usePostContactForm from 'src/queries/contactForm/usePostContactForm'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import preloginStyles from './prelogin.module.scss'

const DetailedContactUsForm = () => {
  const navigate = useNavigate()
  const { mutateAsync } = usePostContactForm()

  const handleBackClick = () => {
    if (formComplete) {
      navigate('/auth/signin')
    } else {
      navigate('/auth/help')
    }
  }
  const [formComplete, setFormComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    }
  })

  const submitForm = async (data) => {
    setIsLoading(true)
    const message = `${data.message} \n \n${data.firstName} ${data.lastName}`
    try {
      const form = await mutateAsync({ message, template: deviceTextTemplate, email: data.email, trackAnalytics: false })
      setIsLoading(false)
      if (form.status === 201) {
        setFormComplete(true)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const thanks = () => {
    return (
      <div
        className={classNames(
          generalStyles.flexColumn,
          generalStyles.flexApart,
          generalStyles.fullFlex
        )}
      >
        <div>
          <div className={generalStyles.mb30}>
            <span className={fontStyles.h1regulardark}>Thank You</span>
          </div>

          <span className={fontStyles.bodydemigrey}>
            We will check your inquiry and get back to you shortly. Please check
            your email.
          </span>
        </div>

        <div>
          <Button
            color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
            size="large"
            onClick={() => handleBackClick()}
            label="Return to Sign In"
            disabled={!isValid}
          />
        </div>
      </div>
    )
  }
  const form = () => {
    return (
      <div className={preloginStyles.contactForm}>
        <div>
          <div className={generalStyles.mb30}>
            <span className={fontStyles.h1regulardark}>Contact Us</span>
          </div>
          <div className={preloginStyles.nameRow}>
            <div className={preloginStyles.nameInputContainer}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={{ placeholder: 'First Name' }}
                    name="firstName"
                    id="firstName"
                    type="text"
                    styles={preloginStyles.nameInput}
                    onBlur={onBlur}
                    value={value}
                    onChange={(text) => onChange(text)}
                  />
                )}
              />
            </div>
            <div className={preloginStyles.nameInputContainer}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    id="lastName"
                    name="lastName"
                    label={{ placeholder: 'Last Name' }}
                    type="text"
                    styles={preloginStyles.nameInput}
                    onBlur={onBlur}
                    value={value}
                    onChange={(text) => onChange(text)}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true
              },
              pattern: {
                value: FormUtilities.EMAIL_REGEX
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                id="email"
                name="email"
                label={{ placeholder: 'Corporate Email Address' }}
                type="email"
                onBlur={onBlur}
                value={value}
                onChange={(text) => onChange(text)}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            rules={{
              required: {
                value: true
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                id="message"
                name="message"
                label={{ placeholder: 'Message' }}
                type="text"
                onBlur={onBlur}
                value={value}
                onChange={(text) => onChange(text)}
                error={errors && errors?.email?.message}
              />
            )}
          />
        </div>
        <div className={preloginStyles.actionRow}>
          {isLoading
            ? (
              <Loader />
            )
            : (
              <Button
                color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
                size="large"
                onClick={handleSubmit(submitForm)}
                label="SUBMIT"
                disabled={!isValid}
              />
            )}
        </div>
      </div>
    )
  }

  return (
    <div className={preloginStyles.detailContactUsPage}>
      <PreloginNavigationHeader
        back={!formComplete}
        close={formComplete}
        onClick={() => handleBackClick()}
      />
      <div className={preloginStyles.contentBodyContainer}>
        {!formComplete ? form() : thanks()}
      </div>
    </div>
  )
}

export default DetailedContactUsForm

import React from 'react'
import classNames from 'classnames'
import { transformTime } from 'src/utils/helpers/MessagesHelpers'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import messageStyles from '../messageStyles.module.scss'

const Message = ({ id, createdAt, user, text, isCurrentUser }) => {
  if (!user) return <></>
  return (
    <div
      key={id}
      className={classNames(
        generalStyles.flexRow,
        generalStyles.alignVertical,
        generalStyles.mb20
      )}
    >
      <div className={generalStyles.mr20}>
        <UserImage
          photoUrl={user.avatar || null}
          altText={`${user.name} photo`}
          styles={messageStyles.msgProfileImage}
        />
      </div>
      <div>
        <div>
          <span className={fontStyles.h6variant2demidark}>
            {isCurrentUser ? 'You' : user.name} |{' '}
            {transformTime(createdAt)}
          </span>
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>{text}</span>
        </div>
      </div>
    </div>
  )
}

export default Message

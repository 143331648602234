import React, { useState, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import iconSet from 'src/assets/icons/iconList.json'
import UserImage from 'src/components/Elements/UserImage'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import navStyles from './navigation.module.scss'
import ProfileMenu from './components/ProfileMenu'

const TabComponent = ({ tab, isMobile, toggleMainNav }) => (
  <NavLink to={tab.link}>
    {() => {
      return (
        <div className={navStyles.navItemContainer}>
          <button
            data-title={isMobile ? undefined : tab.label}
            className={
              isMobile
                ? navStyles.iconContainerMobile
                : navStyles.iconContainer
            }
            onClick={
              isMobile
                ? () => {
                  toggleMainNav()
                }
                : undefined
            }
          >
            <div>
              <IcomoonReact
                iconSet={iconSet}
                color={
                  tab.isActive ? variables.evantaBlue : variables.darkGrey
                }
                size={isMobile ? undefined : 22}
                icon={
                  tab.isActive
                    ? 'Symbol-ProgramIcon-BlueOutline'
                    : 'Symbol-ProgramIcon-GreyOutline'
                }
              />
            </div>
            {isMobile && (
              <span
                className={classNames(
                  tab.isActive && navStyles.activeMobileTab,
                  fontStyles.bodybolddark,
                  generalStyles.ml20
                )}
              >
                {tab.label}
              </span>
            )}
          </button>
          {tab.isActive && !isMobile && (
            <IcomoonReact
              className={navStyles.selectedIndicator}
              iconSet={iconSet}
              size={40}
              icon='Control-SelectedIndicator-Blue'
            />
          )}
        </div>
      )
    }}
  </NavLink>
)

const ProfileComponent = ({
  user,
  isMobile,
  setProfileMenuOpen,
  profileMenuOpen
}) => {
  if (isMobile) {
    return (
      <div className={navStyles.imageContainerMobile}>
        <UserImage
          photoUrl={user?.photo}
          styles={navStyles.profileImageMobile}
          altText="Profile Image"
        />
        <span
          className={classNames(fontStyles.bodybolddark, generalStyles.ml20)}
        >
          {user?.full_name}
        </span>
      </div>
    )
  }

  return (
    <button
      className={navStyles.imageContainer}
      onClick={() => setProfileMenuOpen(!profileMenuOpen)}
    >
      <UserImage
        photoUrl={user?.photo}
        styles={navStyles.profileImage}
        altText="Profile Image"
      />
    </button>
  )
}

const MainNavigation = ({ isMobile, toggleMainNav }) => {
  const location = useLocation()
  const { currentUser } = useGlobalContext()
  const [profileMenuOpen, setProfileMenuOpen] = useState(isMobile)

  const Tabs = useMemo(() => {
    const path = location.pathname
    return [
      {
        show: false,
        isActive: path === '/home',
        label: 'Home',
        link: '/home'
      },
      {
        show: false,
        isActive: false,
        label: 'Communities',
        link: '/communities'
      },
      {
        show: true,
        isActive: path.includes('programs'),
        label: 'Programs',
        link: '/programs'
      },
      {
        show: false,
        isActive: false,
        label: 'Insights',
        link: '/insights'
      },
      {
        show: false,
        isActive: false,
        label: 'Notifications',
        link: '/notifications'
      },
      {
        show: false,
        isActive: false,
        label: 'Search',
        position: 'bottom',
        link: '/search'
      },
      {
        show: false,
        isActive: false,
        label: 'Directory',
        position: 'bottom',
        link: '/directory'
      },
      {
        show: false,
        isActive: false,
        label: 'Messages',
        position: 'bottom',
        link: '/messages'
      },
      {
        show: false,
        isActive: false,
        label: 'Contact',
        position: 'bottom',
        link: '/contact'
      }
    ]
  }, [location])

  return (
    <div className={isMobile ? navStyles.mainNavMobile : navStyles.mainNav}>
      <div
        className={
          isMobile ? navStyles.topNavSectionMobile : navStyles.topNavSection
        }
      >
        {!isMobile && (
          <IcomoonReact
            iconSet={iconSet}
            className={navStyles.logo}
            size={50}
            icon="App-EvantaLogo-Small-Colour"
          />
        )}
        <nav>
          {Tabs.map((tab, index) => {
            if (!tab.position && tab.show) {
              return (
                <TabComponent
                  isMobile={isMobile}
                  key={`${index} - ${tab.label}`}
                  tab={tab}
                  toggleMainNav={toggleMainNav}
                />
              )
            } else return null
          })}
        </nav>
        {isMobile && <div className={navStyles.separatorLine}></div>}
      </div>
      <div
        className={
          isMobile
            ? navStyles.bottomNavSectionMobile
            : navStyles.bottomNavSection
        }
      >
        <nav>
          {Tabs.map((tab, index) => {
            if (tab.position && tab.show) {
              return (
                <TabComponent
                  isMobile={isMobile}
                  key={`${index} - ${tab.label}`}
                  tab={tab}
                  toggleMainNav={toggleMainNav}
                />
              )
            } else return null
          })}
        </nav>
        <ProfileComponent
          user={currentUser}
          isMobile={isMobile}
          setProfileMenuOpen={setProfileMenuOpen}
          profileMenuOpen={profileMenuOpen}
        />
      </div>
      {profileMenuOpen && (
        <ProfileMenu
          isMobile={isMobile}
          setProfileMenuOpen={setProfileMenuOpen}
        />
      )}
    </div>
  )
}

export default MainNavigation

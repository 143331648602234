import React from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import PropTypes from 'prop-types'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import elementStyles from './elements.module.scss'

const TypeProps = {
  id: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.shape({ label: PropTypes.string, placeholder: PropTypes.string, helper: PropTypes.string }),
  multiline: PropTypes.bool,
  error: PropTypes.string,
  styles: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  props: PropTypes.any,
  maxLength: PropTypes.number,
  icon: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }),
  fullWidth: PropTypes.bool
}

const InputComponent = ({
  icon,
  id,
  placeholder,
  onKeyDown,
  maxLength,
  props,
  styles,
  disabled,
  onBlur,
  onChange,
  value,
  type,
  name
}) => {
  return (
    <div className={elementStyles.inputContainer}>
      {icon?.name && (
        <div className={elementStyles.icon}>
          <IcomoonReact
            iconSet={iconSet}
            color={variables.grey}
            size={icon.size}
            icon={icon.name}
          />
        </div>
      )}
      <input
        className={classNames(elementStyles.input, { ...styles })}
        style={{ color: disabled && 'gray' }}
        disabled={disabled}
        maxLength={maxLength || 256}
        onBlur={onBlur}
        onChange={onChange}
        value={value || ''}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        id={id}
        name={name}
        type={type}
        {...props}
      />
    </div>
  )
}

const TextAreaComponent = ({ styles, disabled, maxLength, onBlur, onChange, value, placeholder, props, id, name }) => (
  <textarea
    id={id}
    name={name}
    className={classNames(elementStyles.textareaInput, {
      ...styles
    })}
    disabled={disabled}
    maxLength={maxLength || 256}
    onBlur={onBlur}
    onChange={onChange}
    value={value || ''}
    placeholder={placeholder}
    {...props}
  />
)

const Input = ({
  id,
  disabled,
  label,
  multiline,
  error,
  styles,
  onBlur,
  onChange,
  onKeyDown,
  value,
  props,
  maxLength,
  icon,
  type,
  name,
  fullWidth = false
}) => {
  return (
    <div style={{ width: fullWidth ? '100%' : 'auto' }} className={elementStyles.formControl}>
      {label.label && !icon && (
        <div className={elementStyles.labelContainer}>
          <span className={elementStyles.label}>{label.label}</span>
          <span className={elementStyles.labelHelper}>
            {label.helper}
          </span>
        </div>
      )}
      {multiline
        ? <TextAreaComponent
          id={id}
          name={name}
          disabled={disabled}
          maxLength={maxLength || 256}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ''}
          placeholder={label.placeholder}
          styles={styles}
          {...props}
        />
        : (
          <InputComponent
            id={id}
            name={name}
            disabled={disabled}
            maxLength={maxLength || 256}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value || ''}
            placeholder={label.placeholder}
            icon={icon}
            type={type}
            styles={styles}
            {...props}
          />
        )}

      {error && icon
        ? <div
          className={classNames(
            fontStyles.h5italicred,
            generalStyles.flexRow,
            generalStyles.alignVertical,
            generalStyles.mt10
          )}
        >
          <IcomoonReact
            iconSet={iconSet}
            className={elementStyles.errorIcon}
            color={variables.solar}
            size={22}
            icon='Symbol-AlertIcon-TriangleLarge-Red'
          />
          <div
            className={classNames(elementStyles.errorText, generalStyles.ml6)}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
        : <div className={elementStyles.errorContainer}>
          <div
            className={elementStyles.errorText}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      }
    </div>
  )
}

Input.propTypes = {
  ...TypeProps
}

export default Input

import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import UserRow from 'src/components/Elements/UserRow'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import DropdownFilterBtn from 'src/components/Elements/DropdownFilterBtn'
import { ConditionalWrapper } from 'src/utils/ConditionalWrapper'
import ExchangeBusinessCardButton from 'src/components/Elements/ExchangeBusinessCardButton'
import userStyles from './userStyles.module.scss'

const attendessOpt = [
  { label: 'Connected With', id: 'connections', icon: 'Control-TickIcon-Small-BlueSolidBold' },
  { label: 'Governing Body', id: 'gbs', icon: 'Symbol-GBIcon-Medium-BlueOutline' },
  { label: 'Speaker', id: 'speakers', icon: 'Symbol-SpeakerIcon-Medium-BlueOutline' }
]

const gbsOpt = [
  { label: 'Co-chair', id: 'chairs' },
  { label: 'Member', id: 'members' }
]

const UserList = ({ users, userType }) => {
  const { currentEvent } = useGlobalContext()
  const [filterSelected, setFilterSelected] = useState('all')

  const options = useMemo(() => {
    let arr = { header: 'profile tag', items: [{ label: 'All', id: 'all' }] }
    switch (userType) {
      case 'Attendees':
        arr = [{ ...arr, items: [...arr.items, ...attendessOpt] }]
        break
      case 'Governing Body Members':
        arr = [{ ...arr, items: [...arr.items, ...gbsOpt] }]
        break
      default:
        return [arr]
    }
    return arr
  }, [userType])

  const filteredUsers = useMemo(() => {
    switch (filterSelected) {
      case 'connections':
        return users.filter(u => u.is_biz_card)
      case 'gbs':
        return users.filter(u => u.is_gb)
      case 'speakers':
        return users.filter(u => u.is_speaker)
      case 'chairs':
        return users.filter(u => u.gb_is_featured)
      case 'members':
        return users.filter(u => !u.gb_is_featured)
      default:
        return users
    }
  }, [filterSelected, users])

  const sections = [
    ...new Set(
      filteredUsers.map((a) => {
        return a.last_name[0].toLowerCase()
      })
    )
  ].map((title) => {
    return {
      title,
      data: filteredUsers.filter((a) => {
        return title.toLowerCase() === a.last_name[0].toLowerCase()
      })
    }
  })

  const handleScroll = (id) => {
    const element = document.getElementById('scrollable-container')
    const filterHeaderHeight = document
      .getElementById('filter-header')
      .getBoundingClientRect().height
    const top =
      document.getElementById(id).getBoundingClientRect().top +
      element.scrollTop -
      filterHeaderHeight -
      90
    element.scrollTo({ top })
  }

  const renderAlphaList = () => {
    const letters = []
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]
    alphabet.forEach((letter) => {
      if (sections.map((s) => s?.title.toUpperCase()).includes(letter)) {
        const section = sections.find((s) => s?.title.toUpperCase() === letter)
        letters.push(
          <button
            className={generalStyles.p0}
            key={letter}
            onClick={() => handleScroll(`section-scroll-to-${letter}`)}
          >
            <span className={fontStyles.h6bolddark}>
              {section?.title?.toUpperCase()}
            </span>
          </button>
        )
      } else {
        letters.push(
          <span key={letter} className={fontStyles.h6demigrey}>
            {letter}
          </span>
        )
      }
    })
    return <div className={userStyles.alphaList}>{letters}</div>
  }

  return (
    <div className={classNames(generalStyles.thirdWidth, generalStyles.pb50)}>
      <div id="filter-header" className={userStyles.filterHeader}>
        <div className={generalStyles.pt40}>
          {userType !== 'Speakers' && (
            <DropdownFilterBtn
              options={options}
              onSelect={(filter) => setFilterSelected(filter['profile tag'].id)}
            />
          )}
          {userType === 'Attendees' &&
            currentEvent.is_attended &&
            !currentEvent.is_virtual_event && (
            <div className={generalStyles.mb20}>
              <ExchangeBusinessCardButton
                sublabel="Exchange Business Cards"
              />
            </div>
          )}

          {renderAlphaList()}
        </div>
      </div>

      {sections?.map((section) => {
        return (
          <div key={section?.title}>
            <div
              className={generalStyles.mt40}
              id={`section-scroll-to-${section?.title?.toUpperCase()}`}
            >
              <span className={fontStyles.h3alternate2bolddark}>
                {section?.title?.toUpperCase()}
              </span>
              <div className={generalStyles.mt10}>
                <HorizontalLine color='lightGrey' fullWidth={true} />
              </div>
            </div>
            {section?.data?.map((attendee, i, array) => (
              <div key={attendee?.id}>
                <button
                  key={attendee?.id}
                  className={classNames(
                    generalStyles.mt10,
                    generalStyles.mb10,
                    generalStyles.p10,
                    generalStyles.leftAlignedText,
                    { [generalStyles.userRowButton]: userType === 'Attendees' }
                  )}
                  disabled={userType !== 'Attendees'}
                >
                  <ConditionalWrapper
                    condition={userType === 'Attendees'}
                    wrapper={(children) => (
                      <Link
                        to={`/programs/${currentEvent.id}/attendees/${attendee?.user_id}`}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    <UserRow user={attendee} includeIcons={true} />
                  </ConditionalWrapper>
                </button>
                <div
                  className={classNames({
                    [generalStyles.ml10]: array?.length !== i + 1
                  })}
                >
                  <HorizontalLine
                    color='lightGrey'
                    fullWidth={array?.length === i + 1}
                  />
                </div>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default UserList

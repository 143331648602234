import useFetchEvent from 'src/queries/events/useFetchEvent'
import useFetchCurrentUser from 'src/queries/currentUser/useFetchCurrentUser'
import useFetchAttendees from 'src/queries/attendees/useFetchAttendees'

export const useAnalyticsTracking = () => {
  const { currentUser, isLoadingCurrentUser } = useFetchCurrentUser()
  const { currentEvent, isLoadingEvent } = useFetchEvent()
  const { attendees } = useFetchAttendees()
  const mode = import.meta.env.PROD
  // testing locally
  // const mode = import.meta.env.DEV

  const googleTracking = (type, pageName, trackingParams, enabled = true, props) => {
    if (isLoadingCurrentUser || isLoadingEvent || !enabled || !mode) return

    const eventInfo = {
      event_id: null,
      brand: null,
      region: null,
      event_name: null,
      event_type: null
    }
    if (trackingParams?.event_id) {
      eventInfo.event_id = trackingParams.event_id
      eventInfo.brand = trackingParams.brand
      eventInfo.region = trackingParams.region
      eventInfo.event_name = trackingParams.event_name
      eventInfo.event_type = trackingParams.event_type
    } else {
      eventInfo.event_id = `${currentEvent?.id}`
      eventInfo.brand = currentEvent?.brand_code
      eventInfo.region = currentEvent?.region_code
      eventInfo.event_name = currentEvent?.name?.substring(0, 36)
      eventInfo.event_type = currentEvent?.product_code
    }

    const userInfo = {
      crm_contact_id: null,
      evanta_internal: null
    }
    const currentUserUserId = currentUser.id

    if (currentUserUserId) {
      userInfo.crm_contact_id = `${currentUser.crm_contact_id}`
      userInfo.evanta_internal = `${
        attendees?.find((a) => a.user_id === currentUserUserId)
          ?.registration_status_code_key ===
        'registration_status_approved_internal'
      }`
    }
    // To help with testing
    // console.log({
    //   event: type,
    //   aem_page_template: pageName,
    //   ...eventInfo,
    //   ...userInfo,
    //   ...trackingParams,
    //   ...props
    // })
    // Google Req: Values should be Strings
    window.dataLayer.push({
      event: type,
      aem_page_template: pageName,
      ...eventInfo,
      ...userInfo,
      ...trackingParams,
      ...props
    })
    return ''
  }

  return googleTracking
}

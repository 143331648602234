import React from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { pluralizeString } from 'src/utils/helpers/GeneralHelpers'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import fabStyles from './fabStyles.module.scss'

const FAB = ({ fab }) => {
  const { setSidePanelDetails } = useGlobalContext()

  const handleClick = () => {
    setSidePanelDetails({ type: 'fab', data: null, title: 'Action Items' })
  }

  return (
    <button onClick={() => handleClick()} className={fabStyles.fabContainer}>
      <div className={fabStyles.fabContents}>
        <IcomoonReact
          iconSet={iconSet}
          color={variables.white}
          size={20}
          icon="Symbol-ClockIcon-WhiteOutline"
          className={fabStyles.icon}
        />
        <div className={fabStyles.textContainer}>
          <span className={fontStyles.bodydemilight}>
            It&apos;s time to catch up on your{' '}
            <span
              className={classNames(
                fontStyles.h5demilight,
                fontStyles.underline
              )}
            >
              {fab.length} {pluralizeString('Action Item', fab)}
            </span>
          </span>
        </div>
      </div>
    </button>
  )
}

export default FAB

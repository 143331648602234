import React, { useState, useEffect, useRef, useMemo } from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { isEmpty } from 'lodash'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { transformMessages } from 'src/utils/helpers/MessagesHelpers'
import useFetchMessages from 'src/queries/messages/useFetchMessages'
import useMarkMessagesRead from 'src/queries/messages/useMarkMessagesRead'
import useCreateMessage from 'src/queries/messages/useCreateMessage'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import TimeframeDivider from './components/TimeframeDivider'
import Message from './components/Message'
import messageStyles from './messageStyles.module.scss'

const MessageList = ({ data }) => {
  const { eventId, currentUser, attendees } = useGlobalContext()
  const {
    messages: messagesData,
    isLoadingMessages,
    refetch
  } = useFetchMessages()
  const { mutate } = useMarkMessagesRead()
  const { mutate: createMessageMutation } = useCreateMessage()
  const messagesEndRef = useRef(null)
  const otherUserId = parseInt(data)
  const otherUser = attendees.find((a) => a.user_id === otherUserId)
  const [currentText, setCurrentText] = useState('')
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const messages = useMemo(
    () => transformMessages(messagesData, currentUser, otherUser),
    [messagesData, currentUser, otherUser]
  )

  const markMessagesRead = () => mutate({ otherUserId })

  useEffect(() => {
    markMessagesRead()
  }, [otherUserId, messages])

  useEffect(() => {
    scrollToBottom()
  }, [])

  const onSend = async () => {
    await createMessageMutation({
      body: currentText,
      recipientUserId: otherUserId,
      currentEventId: eventId
    })
    setCurrentText('')
  }

  if (isLoadingMessages) return <></>

  return (
    <div onClick={() => refetch()}>
      <div className={messageStyles.messageContentContainer}>
        <div className={messageStyles.content}>
          <div ref={messagesEndRef} />
          {messages?.map((messageGroup) => {
            return messageGroup.map((message, index) => {
              return (
                <div key={index}>
                  {index === messageGroup.length - 1 ? <TimeframeDivider timeframe={message.createdAt} /> : null}
                  <Message
                    id={message.id}
                    createdAt={message.createdAt}
                    user={message.user}
                    text={message.text}
                    isCurrentUser={message.user.id === currentUser.id}
                  />
                </div>
              )
            })
          })}
        </div>
      </div>
      <div className={messageStyles.messageActionContainer}>
        <div className={messageStyles.msgInputContainer}>
          <input
            id="message"
            name="message"
            type="text"
            placeholder="Write message..."
            className={classNames(
              generalStyles.pl20,
              generalStyles.searchInput
            )}
            value={currentText}
            onChange={(e) => {
              setCurrentText(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !isEmpty(currentText)) {
                onSend()
              }
            }}
          />
        </div>
        <div
          className={classNames(
            generalStyles.flexRow,
            generalStyles.alignVertical,
            generalStyles.flexEnd,
            generalStyles.mt10
          )}
        >
          <button
            data-title="Send"
            className={messageStyles.iconContainer}
            disabled={!currentText}
            onClick={onSend}
          >
            <IcomoonReact
              iconSet={iconSet}
              color={variables.hex6d6e71}
              size={26}
              icon={'Symbol-SendIcon-GreyOutline'}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MessageList

import React, { useMemo, useState, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import useFetchSurveyUser from 'src/queries/virtualPortal/useFetchSurveyUser'
import useFetchWishlistUsers from 'src/queries/virtualPortal/useFetchWishlistUsers'
import useCreateOneOnOneMeetingWishlist from 'src/queries/virtualPortal/useCreateOneOnOneMeetingWishlist'
import useDeleteOneOnOneMeetingWishlist from 'src/queries/virtualPortal/useDeleteOneOnOneMeetingWishlist'
import WizardHeader from './components/WizardHeader'
import LoadingScreen from './components/LoadingScreen'
import Wishlist from './wishlist/Wishlist'
import WishlistRank from './wishlist-rank/WishlistRank'
import VirtualPortalHeader from './WizardHeader'
import Availability from './availability/Availability'
import Thankyou from './Thankyou'
import ErrorModal from './components/ErrorModal'
import Details from './details/Details'
import EditProfile from './details/EditProfile'
import BlueHeader from './BlueHeader'
import virtualPortalStyles from './components/virtualPortalStyles.module.scss'
import Footer from './components/Footer'

const WizardPage = () => {
  const params = useParams()
  const location = useLocation()
  const { token, tab: paramsTab } = params
  const errorMessage = localStorage.getItem('wizard_error')
  const { surveyUser, isLoading } = useFetchSurveyUser()
  const { wishlistUsers } = useFetchWishlistUsers()
  const { mutate } = useCreateOneOnOneMeetingWishlist()
  const { mutate: deleteMeetingMutation } = useDeleteOneOnOneMeetingWishlist()
  const [searchValue, setSearchValue] = useState('')
  const isRegistrationClosed = !surveyUser?.event?.peer_to_peer_wizard_is_available
  const tab = useMemo(() => location.pathname.split('/')[2], [location])

  const visitingOneOnOneWizardFrom = useMemo(() => {
    const { search } = location
    return search.split('=')[1]
  }, [location])

  const listData = useMemo(() => {
    const include = wishlistUsers.filter(user => user.wishlist_id && !user.blocked_status).map(user => user.id)
    const block = wishlistUsers.filter(user => user.blocked_status).map(user => user.id)
    if (searchValue !== '') {
      const data = wishlistUsers.filter((su) => {
        let searchableText =
          `${su.user.first_name} ` +
          `${su.user.last_name} ` +
          `${su.user.organization_name} `
        searchableText = searchableText.toLowerCase()
        return searchableText.indexOf(searchValue.toLowerCase()) >= 0
      })
      return { wishlistUsers: data, include, block }
    } else {
      return {
        wishlistUsers,
        include,
        block
      }
    }
  }, [wishlistUsers, searchValue])

  const searchUsers = useCallback((e) => {
    const searchTerm = e.target.value
    setSearchValue(searchTerm)
  }, [])

  const deleteMeeting = useCallback((wishlistId) => {
    deleteMeetingMutation({ wishlistId })
  }, [])

  const createMeeting = useCallback((userId, block) => {
    const data = {
      is_blocked: `${block}`,
      survey_user_id: surveyUser.id,
      impacted_survey_user_id: userId
    }
    mutate(data)
  }, [surveyUser, mutate])

  if (errorMessage) {
    return <ErrorModal token={token} message={errorMessage} />
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isRegistrationClosed) {
    return <VirtualPortalHeader event={surveyUser.event} />
  }

  const hideTabs = tab === 'thankyou' || paramsTab === 'edit_profile'

  return (
    <div>
      <BlueHeader
        event={surveyUser?.event}
      />
      <div className={virtualPortalStyles.layout}>
        {hideTabs
          ? null
          : <WizardHeader currentUser={surveyUser.user} tab={tab} token={token}
            visitingOneOnOneWizardFrom={visitingOneOnOneWizardFrom} />}
        {tab === 'detail' && <Details surveyUser={surveyUser?.user} />}
        {tab === 'wishlist' &&
        <Wishlist
          surveyUser={surveyUser}
          searchUsers={searchUsers}
          listData={listData}
          deleteMeeting={deleteMeeting}
          createMeeting={createMeeting}
        />
        }
        {tab === 'wishlist_rank' &&
        <WishlistRank
          deleteMeeting={deleteMeeting}
          createMeeting={createMeeting}
        />
        }
        {tab === 'availability' &&
        <Availability
          event={surveyUser?.event}
        />
        }
        {tab === 'thankyou' &&
        <Thankyou survey={surveyUser} />
        }
        {tab === 'edit_profile' && <EditProfile photo={surveyUser?.user?.photo} /> }
      </div>
      <Footer />
    </div>
  )
}

export default WizardPage

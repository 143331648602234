import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchData } from 'src/api/api-utilities'
import { useParams } from 'react-router-dom'

const fetchLeaderboard = async (eventId) => {
  const response = await fetchData('/my_evanta/leaderboard/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchLeaderboard = () => {
  const { eventId } = useParams()
  const [leaderboard, setLeaderboard] = useState({
    leaderboardRules: [],
    leaderboardSponsor: [],
    leaderboardUsers: []
  })

  const { data, isLoading } = useQuery({
    queryKey: [`leaderboard_${eventId}`],
    queryFn: () => fetchLeaderboard(eventId),
    enabled: !!eventId,
    staleTime: Infinity,
    retry: false
  })

  useEffect(() => {
    if (!isLoading) {
      setLeaderboard({
        leaderboardRules: data.leaderboard.leaderboard_rules || [],
        leaderboardUsers: data.leaderboard.event_user_leaderboards || [],
        leaderboardSponsor: data.leaderboard.leaderboard_sponsor || []
      })
    }
  }, [isLoading])

  return {
    leaderboardRules: leaderboard.leaderboardRules,
    leaderboardUsers: leaderboard.leaderboardUsers,
    leaderboardSponsor: leaderboard.leaderboardSponsor,
    isLoadingLeaderboard: isLoading
  }
}

export default useFetchLeaderboard

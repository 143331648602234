import React from 'react'
import Modal from 'src/components/Elements/Modal'
import Button from 'src/components/Elements/Button'
import virtualPortalStyles from './virtualPortalStyles.module.scss'
import noImage from '../images/no_image.png'

const UserDetailsModal = ({ closeModal, isOpen, user }) =>
  isOpen && (
    <Modal isOpen={isOpen} close={closeModal}>
      <div className={virtualPortalStyles.userDetailsModal}>
        <div className={virtualPortalStyles.userDetailsHeader}>
          <img
            className={virtualPortalStyles.userImg}
            src={user?.photo || noImage}
          />
          <div>
            <h2 style={{ fontWeight: 600, margin: 0 }}>{user?.full_name}</h2>
            <h3 style={{ margin: 0 }}>{user?.title}</h3>
            <h3>{user?.organization_name}</h3>
          </div>
        </div>
        <div style={{ margin: '18px 0px' }}>
          <strong style={{ color: '#606060' }}>Bio</strong>
          <p style={{ margin: 0 }}>{user?.bio || 'No bio provided'}</p>
        </div>
        <hr />
        <Button
          color="solar"
          label="Close"
          style={{
            padding: '10px',
            borderRadius: '0.25rem',
            margin: '20px auto'
          }}
          onClick={closeModal}
        />
      </div>
    </Modal>
  )

export default UserDetailsModal

import Cookies from 'js-cookie'

export const checkPathPresent = (arrayOfPaths, pathname) => {
  return arrayOfPaths.some((path) => pathname.includes(path))
}

export const pluralizeString = (string, array) => array.length > 0 ? `${string}s` : string

export const logoutUser = () => Cookies.remove('token')

export const isPastTime = (time) => {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000)
  return time < currentTimeInSeconds
}

export const isPastDate = (dateValue) => {
  const date = new Date(dateValue)
  const currentDate = new Date()
  return date < currentDate
}

import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { putData } from 'src/api/api-utilities'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'

const updatePassword = async (params) => {
  const url = params.reset_password_token ? '/users/password' : '/users/password/reset'
  const response = await putData(url, { user: { ...params } })
  return response
}

const useUpdatePassword = () => {
  const isMobileSize = useIsMobileQuery()
  const navigate = useNavigate()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: updatePassword,
    onSuccess: (data, params) => {
      if (data.status === 200) {
        Cookies.set('token', data.data.auth_token)
        if (params.reset_password_token && isMobileSize) {
          const forwardToMobile = confirm(
            'Would you like to continue on the mobile application?'
          )
          if (forwardToMobile) {
            window.location.href = 'my-evanta-app://'
            // forward mobile web to /programs after 2s in case of any issue with app forward so use is not stuck with modal
            setTimeout(() => {
              window.location.href = '/programs'
            }, 2000)
          } else navigate('/programs')
        } else navigate('/programs')
      }
    }
  })

  return { mutateAsync, mutate }
}

export default useUpdatePassword

import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import elementStyles from './elements.module.scss'

const TypeProps = {
  color: PropTypes.string,
  fullWidth: PropTypes.bool
}

const HorizontalLine = ({ color, fullWidth }) => (
  <div
    className={classNames(elementStyles.hr, elementStyles[color], {
      [elementStyles.smallerLine]: !fullWidth
    })}
    data-testid='linebreak'
  />
)

HorizontalLine.propTypes = {
  ...TypeProps
}

export default HorizontalLine

import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import UserRow from 'src/components/Elements/UserRow'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import CurrentUserRankingComponent from 'src/features/programs/program/leaderboard/components/CurrentUserRankingComponent'
import useFetchLeaderboard from 'src/queries/leaderboard/useFetchLeaderboard'
import leaderboardStyles from './leaderboardStyles.module.scss'

const LeaderboardRanking = () => {
  const { eventId, currentUser: user, attendees } = useGlobalContext()
  const { leaderboardUsers } = useFetchLeaderboard()
  const isMobileSize = useIsMobileQuery()
  const currentUser = attendees.find((a) => a?.user_id === user.id)

  const renderTopThree = () => {
    if (leaderboardUsers !== null) {
      return leaderboardUsers.slice(0, 3).map((u) => {
        const user = attendees.find((a) => a?.user_id === u?.user_id)
        return (
          <div
            key={user.id}
            className={classNames(
              leaderboardStyles.topThreeContainer,
              generalStyles.flexRow
            )}
          >
            <div className={leaderboardStyles.rankingContainer}>
              <span className={fontStyles.h1bolddark}>{u?.rank}</span>
            </div>
            <UserRow
              isMedPhoto={true}
              user={user}
              textContent={'points'}
              points={`${u?.formatted_points} points`}
            />
          </div>
        )
      })
    }
  }
  return (
    <div className={generalStyles.thirdWidth}>
      <div
        className={classNames(leaderboardStyles.rankingHeader, {
          [generalStyles.mb20]: isMobileSize
        })}
      >
        <span className={fontStyles.h2alternate2bolddark}>
          Leaderboard Ranking
        </span>
        <div className={classNames(generalStyles.mt20, generalStyles.mb30)}>
          <CurrentUserRankingComponent leaderboardUsers={leaderboardUsers} currentUser={currentUser} />
        </div>
        <HorizontalLine color='lightGrey' fullWidth />
      </div>

      {renderTopThree()}
      {leaderboardUsers !== null &&
        leaderboardUsers.slice(3).map((lu) => {
          const user = attendees.find((a) => a?.user_id === lu?.user_id)
          if (!isEmpty(user)) {
            return (
              <div key={`${user.id}-${lu.rank}`}>
                <div className={generalStyles.flexRow}>
                  <div className={leaderboardStyles.rankingContainer}>
                    <span className={fontStyles.h3alternate2bolddark}>
                      {lu?.rank}
                    </span>
                  </div>
                  <Link to={`/programs/${eventId}/attendees/${user?.user_id}`}>
                    <UserRow
                      isMedPhoto={true}
                      user={user}
                      textContent={'points'}
                      points={`${lu?.formatted_points} points`}
                    />
                  </Link>
                </div>
                <div
                  className={classNames(
                    generalStyles.mt20,
                    generalStyles.mb20,
                    generalStyles.ml60
                  )}
                >
                  <HorizontalLine color={'lightGrey'} fullWidth={true} />
                </div>
              </div>
            )
          } else return ''
        })}
    </div>
  )
}

export default LeaderboardRanking

import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import iconSet from 'src/assets/icons/iconList.json'
import Button from 'src/components/Elements/Button'
import settingStyles from './settingStyles.module.scss'

const ProfilePage = () => {
  const { currentUser } = useGlobalContext()
  const googleTracking = useAnalyticsTracking()
  const navigate = useNavigate()

  const handleEditProfileNavigation = () => {
    googleTracking('profile_edit', 'profile_page')
    navigate('/edit-profile')
  }

  return (
    <div className={generalStyles.thirdWidth}>
      <div>
        <div
          className={classNames(
            generalStyles.flexRow,
            generalStyles.flexApart,
            generalStyles.alignTop
          )}
        >
          <div className={generalStyles.flexRow}>
            <div className={settingStyles.imageContainer}>
              <UserImage
                photoUrl={currentUser?.photo}
                styles={classNames(
                  generalStyles.mr20,
                  settingStyles.profileImage
                )}
                altText={`${currentUser?.first_name} ${currentUser?.last_name} Photo`}
              />
            </div>
            <div className={generalStyles.flexColumn}>
              <span className={fontStyles.h2alternate2bolddark}>
                {currentUser?.first_name} {currentUser?.last_name}
              </span>

              <span className={fontStyles.h3alternate2bolddark}>
                {currentUser?.title}
              </span>
              <span className={fontStyles.h3alternate2bolddark}>
                {currentUser?.organization_name}
              </span>
            </div>
          </div>
          <button
            name="Edit Profile"
            onClick={() => handleEditProfileNavigation()}
            className={settingStyles.editButtonContainer}
            data-title="Edit"
          >
            <div className={settingStyles.iconContainer}>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='Control-EditLineIcon'
              />
            </div>
          </button>
        </div>
        <div className={classNames(generalStyles.mt10, generalStyles.mb40)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
      </div>
      <div className={generalStyles.mt40}>
        <span className={fontStyles.h3alternate2bolddark}>Biography</span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <span>{currentUser?.bio || 'No bio provided'}</span>
      </div>
      <div className={generalStyles.mt40}>
        <span className={fontStyles.h3alternate2bolddark}>Contact Details</span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <div>
          <span className={fontStyles.bodyregulargrey}>Phone | </span>
          <span className={fontStyles.bodybolddark}>{currentUser?.phone}</span>
        </div>
        <div className={generalStyles.mt10}>
          <span className={fontStyles.bodyregulargrey}>Email | </span>
          <span className={fontStyles.bodyboldgrey}>{currentUser?.email}</span>
        </div>
      </div>
      <div className={classNames(generalStyles.mt20, generalStyles.mb20)}>
        <span className={fontStyles.h6demidarkgrey}>
          THE FIELDS BELOW WILL NOT BE SHARED WITH BUSINESS CARD EXCHANGE OR BE
          VIEWABLE BY OTHER USERS. THIS INFORMATION IS USED BY EVENT STAFF ONLY.
        </span>
      </div>
      <div>
        <span className={fontStyles.h3alternate2bolddark}>
          Dietary Requirements
        </span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>
            {currentUser?.dietary_assignments || 'None selected'}
          </span>
        </div>
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodybolddarkgrey}>Dietary Notes</span>
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>
            {currentUser.dietary_restrictions || 'No dietary notes added.'}
          </span>
        </div>
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodybolddarkgrey}>Allergies</span>
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>
            {currentUser?.dietary_allergies || 'No allergies added.'}
          </span>
        </div>
      </div>
      <div className={generalStyles.mt40}>
        <span className={fontStyles.h3alternate2bolddark}>
          Accessibility Needs
        </span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>
            {currentUser?.accessibity_assignments || 'None selected'}
          </span>
        </div>
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodybolddarkgrey}>
            Additional Accessibility Information
          </span>
        </div>
        <div>
          <span className={fontStyles.bodyregulardark}>
            {currentUser?.accessibility_needs ||
              'No accessibility notes added.'}
          </span>
        </div>
      </div>
      <div
        className={classNames(
          generalStyles.mt40,
          generalStyles.pb40,
          generalStyles.flexCentered
        )}
      >
        <Button
          color={'evantaBlue'}
          size={'med'}
          label={'Edit'}
          onClick={() => handleEditProfileNavigation()}
        />
      </div>
    </div>
  )
}

export default ProfilePage

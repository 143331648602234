import React from 'react'
import NoUserDisplay from 'src/features/programs/program/users/components/NoUserDisplay'
import useFetchSpeakers from 'src/queries/speakers/useFetchSpeakers'
import UserList from './UserList'

const SpeakerIndex = () => {
  const { speakers } = useFetchSpeakers()
  const userType = 'Speakers'
  if (!speakers.length) {
    return <NoUserDisplay userType={userType} />
  }
  return <UserList users={speakers} userType={userType} />
}

export default SpeakerIndex

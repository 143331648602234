import React from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import iconSet from 'src/assets/icons/iconList.json'
import preloginStyles from './navigation.module.scss'

const PreloginNavigationHeader = ({ back, close, onClick }) => (
  <div className={preloginStyles.navContainer}>
    {back && (
      <button className={preloginStyles.buttonContainer} onClick={onClick}>
        <IcomoonReact
          iconSet={iconSet}
          className={preloginStyles.navBtn}
          size={16}
          icon='Control-BackCarat-Large-GreyOutline'
        />
      </button>
    )}
    {close && (
      <button
        className={classNames(
          preloginStyles.buttonContainer,
          preloginStyles.right
        )}
        onClick={onClick}
      >
        <IcomoonReact
          iconSet={iconSet}
          className={preloginStyles.navBtn}
          size={12}
          icon='Control-CrossIcon-Large-GreyOutlineBold'
        />
      </button>
    )}
  </div>
)

export default PreloginNavigationHeader

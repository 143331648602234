import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import UserDetailsModal from '../components/UserDetailsModal'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'

const RenderRows = ({ listData, setWishlist, openModal }) => {
  const { wishlistUsers, include, block } = listData
  return (
    <div className={virtualPortalStyles.tableBody}>
      {wishlistUsers.map((wishlistUser, index) => {
        const { user, id } = wishlistUser
        const isSelectedUp = include.includes(id)
        const isSelectedDown = block.includes(id)
        return (
          <div className={virtualPortalStyles.row} key={id}>
            <div className={virtualPortalStyles.column}>
              <button
                className={classNames(
                  virtualPortalStyles.thumbs,
                  virtualPortalStyles.thumbsUp,
                  isSelectedUp && virtualPortalStyles.thumbsUpActive
                )}
                onClick={() => setWishlist(id, 'include')}
              >
                <i
                  className={`fa fa-2x fa-thumbs${isSelectedUp ? '' : '-o'}-up`}
                  style={{ transform: 'scaleX(-1)' }}
                />
              </button>
              <button
                className={classNames(
                  virtualPortalStyles.thumbs,
                  virtualPortalStyles.thumbsDown,
                  isSelectedDown && virtualPortalStyles.thumbsDownActive
                )}
                onClick={() =>
                  setWishlist(id, 'block', wishlistUser?.wishlist_id)
                }
              >
                <i
                  className={`fa fa-2x fa-thumbs${
                    isSelectedDown ? '' : '-o'
                  }-down`}
                />
              </button>
            </div>
            <div className={virtualPortalStyles.column}>
              {user?.organization_name}
            </div>
            <div
              className={classNames(
                virtualPortalStyles.column,
                virtualPortalStyles.fullName
              )}
              onClick={() => openModal(wishlistUser)}
            >
              {user?.full_name}
            </div>
            <div className={virtualPortalStyles.column}>{user?.title}</div>
            <div className={virtualPortalStyles.column}>{index + 1}</div>
          </div>
        )
      })}
    </div>
  )
}

const WishlistBody = ({ listData, setWishlist }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  const openModal = useCallback(
    (user) => {
      setIsModalOpen(true)
      setSelectedUser(user)
    },
    [listData]
  )

  return (
    <div className={virtualPortalStyles.table}>
      <div className={virtualPortalStyles.tableHeader}>
        <div>
          Include ({listData.include.length}) / Block ({listData.block.length})
        </div>
        <div>Organization</div>
        <div>Name</div>
        <div>Title</div>
        <div style={{ position: 'relative' }}>
          Relevance Rank
          <i
            className={classNames(
              'fa fa-question helper-text',
              virtualPortalStyles.tooltip
            )}
          >
            <span className={virtualPortalStyles.tooltiptext}>
              We’ve calculated your most relevant meeting partners based on our
              matching criteria.
            </span>
          </i>
        </div>
      </div>
      {listData.wishlistUsers.length
        ? (
          <RenderRows
            setWishlist={setWishlist}
            listData={listData}
            openModal={openModal}
          />
        )
        : (
          <div className={classNames(generalStyles.pb20, generalStyles.pt20)}>
          No users to display
          </div>
        )}
      <UserDetailsModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        user={selectedUser?.user}
      />
    </div>
  )
}

export default WishlistBody

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { postFile } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const postUploadPhoto = async (params, token) => {
  const response = await postFile('/virtual_portal/upload', params, { Sessionauthorization: token })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUploadVirtualPhoto = () => {
  const { setAlert } = useToastAlert()
  const navigate = useNavigate()
  const { token } = useParams()
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => postUploadPhoto(params, token),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['survey_user', config] })
      navigate(`/${token}/detail/wizard`)
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useUploadVirtualPhoto

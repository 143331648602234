import React, { useState } from 'react'
import classNames from 'classnames'
import virtualPortalStyles from '../wizard/components/virtualPortalStyles.module.scss'

const Tabs = () => {
  const [tab, setTab] = useState('agenda')
  return (
    <div style={{ width: 250 }} className={virtualPortalStyles.wizardLinks}>
      <div
        onClick={() => setTab('agenda')}
        className={classNames(
          tab === 'agenda'
            ? virtualPortalStyles.active
            : virtualPortalStyles.tabs
        )}
        style={{ cursor: 'pointer' }}
      >
        <a>
        AGENDA
        </a>
      </div>
      <div
        onClick={() => setTab('schedule')}
        className={classNames(
          tab === 'schedule'
            ? virtualPortalStyles.active
            : virtualPortalStyles.tabs
        )}
        style={{ cursor: 'pointer' }}
      >
        <a>
        MY SCHEDULE
        </a>
      </div>
    </div>
  )
}

export default Tabs

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Input from 'src/components/Elements/Input'
import useUpdatePassword from 'src/queries/auth/useUpdatePassword'
import Button from 'src/components/Elements/Button'
import preloginStyles from './prelogin.module.scss'

const ChangeTempPasswordForm = () => {
  const { token } = useParams()
  const { mutateAsync } = useUpdatePassword()
  const [loginError, setLoginError] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      passwordOne: '',
      passwordTwo: ''
    }
  })

  const submitEmailForm = async (data) => {
    const userObj = token
      ? {
        reset_password_token: token,
        password: data.passwordOne,
        password_confirmation: data.passwordTwo
      }
      : {
        new_password: data.passwordOne,
        confirmation_password: data.passwordTwo
      }
    const login = await mutateAsync({ ...userObj })
    if (login.response) {
      const errorMessage = login.response.status !== 200 && Object.values(login.response.data.errors)[0][0]
      if (!isEmpty(errorMessage)) {
        setLoginError(errorMessage === 'account_disabled' ? 'Account disabled.' : 'Invalid login credentials')
      }
    }
  }

  return (
    <div
      key="changePasswordForm"
      className={classNames(
        preloginStyles.contentBodyContainer,
        generalStyles.flexApart,
        preloginStyles.changeTempPwForm
      )}
    >
      <div className={generalStyles.pt50}>
        <div className={generalStyles.mb30}>
          <span className={fontStyles.h1regulardark}>Change Password</span>
        </div>
        <Controller
          name="passwordOne"
          control={control}
          rules={{
            required: {
              value: true
            },
            minLength: {
              value: 8
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="passwordOne"
              label={{ label: 'New Password', placeholder: 'New Password' }}
              name="passwordOne"
              type="password"
              icon={{ name: 'Symbol-PasswordIcon-GreySolid', size: 27 }}
              onBlur={onBlur}
              value={value}
              onChange={(text) => onChange(text)}
            />
          )}
        />
        <Controller
          name="passwordTwo"
          control={control}
          rules={{
            required: {
              value: true
            },
            minLength: {
              value: 8
            },
            validate: {
              match: (value, formValues) => value === formValues.passwordOne
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className={generalStyles.mt40}>
              <Input
                id="passwordTwo"
                label={{ label: 'Re-enter New Password', placeholder: 'Re-enter New Password' }}
                name="passwordTwo"
                type="password"
                icon={{ name: 'Symbol-PasswordIcon-GreySolid', size: 27 }}
                onBlur={onBlur}
                value={value}
                onChange={(text) => onChange(text)}
                error={
                  errors?.passwordTwo || loginError
                    ? loginError || 'Passwords do not match'
                    : null
                }
              />
            </div>
          )}
        />
        <div className={generalStyles.mt30}>
          <span className={fontStyles.h6variant2demigrey}>
            Passwords must be a minimum of eight characters in length.
          </span>
        </div>
      </div>
      <div>
        <Button
          color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
          size="large"
          onClick={handleSubmit(submitEmailForm)}
          label="SUBMIT"
          disabled={!isValid}
        />
      </div>
    </div>
  )
}

export default ChangeTempPasswordForm

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchData } from 'src/api/api-utilities'

const fetchSponsors = async (eventId) => {
  const response = await fetchData('/my_evanta/sponsors/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchSponsors = (sponsorId) => {
  const { eventId } = useParams()
  const id = parseInt(sponsorId)

  const { data, isLoading } = useQuery({
    queryKey: [`sponsors_${eventId}`],
    queryFn: () => fetchSponsors(eventId),
    enabled: !!eventId,
    staleTime: Infinity,
    retry: false
  })

  const sponsors = isEmpty(data) ? [] : data.sponsors
  const partnerById = sponsors?.find(sponsor => sponsor.id === id)

  return {
    sponsors,
    partner: partnerById,
    isLoadingSponsors: isLoading
  }
}

export default useFetchSponsors

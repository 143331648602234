import { deviceDetect, isMobile, isIOS } from 'react-device-detect'

export const useIsMobile = () => isMobile
export const useIsiOS = () => isIOS
export const useDeviceAgent = () => deviceDetect(window?.navigator?.userAgent)
const device = useDeviceAgent()?.osName?.concat(' ')?.concat(useDeviceAgent()?.osVersion)
const browser = useDeviceAgent()?.browserName
  ?.concat(' ')
  ?.concat(useDeviceAgent()?.browserFullVersion)
export const deviceTextTemplate = `

    --------------------------
    Device Details for Support
    Device: ${device} | Browser: ${browser}
    `

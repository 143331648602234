import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import Button from './Button'

const ErrorPage = () => {
  const navigate = useNavigate()
  return (
    <div className={generalStyles.greyBackground}>
      <div
        className={classNames(
          generalStyles.flexColumn,
          generalStyles.flexCentered,
          generalStyles.centeredText
        )}
      >
        <div className={generalStyles.mt40}>
          <img
            src="/assets/images/evanta_logo_white.png"
            alt="Evanta Logo"
            height={150}
          />
        </div>
        <div className={generalStyles.mt40}>
          <span
            className={classNames(fontStyles.h1bolddark, fontStyles.extraLrg)}
          >
            404
          </span>
        </div>
        <div className={generalStyles.mt40}>
          <span className={fontStyles.h3alternate2regulardark}>
            Page not found
          </span>
        </div>
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodyregulardark}>
            The page you are looking for does not exist.
          </span>
        </div>
        <div
          className={classNames(generalStyles.mt40, generalStyles.flexCentered)}
        >
          <Button
            color={'evantaBlue'}
            size={'large'}
            label={'Return Home'}
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </div>
  )
}

export default ErrorPage

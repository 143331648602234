import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useToastAlert } from 'src/stores/ToastContext'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { logoutUser } from 'src/utils/helpers/GeneralHelpers'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import LayoutStyles from 'src/components/Layout/layouts.module.scss'
import elementStyles from './elements.module.scss'

const FlashMessage = () => {
  const navigate = useNavigate()
  const { alert: { status, title, message, forceLogout }, resetAlert } = useToastAlert()
  const { eventId } = useGlobalContext()
  const messageType = status === 201 ? 'positive' : 'negative'

  const handleLogout = async (e) => {
    e.preventDefault()
    resetAlert()
    logoutUser()
    navigate('/auth/signin')
  }

  if (isEmpty(message)) return <></>

  return (
    <div
      className={classNames(LayoutStyles.flashMessageContainer, {
        [LayoutStyles[`lower${eventId ? 'WithNav' : ''}`]]: !!message
      })}
    >
      <div
        className={classNames(
          elementStyles.flashContainer,
          elementStyles[messageType]
        )}
      >
        <div className={elementStyles.textContainer}>
          <span className={messageType === 'positive' ? fontStyles.bodyboldblue : fontStyles.bodyboldred}>{title}: </span>
          <span>
            {message}
            {forceLogout && (
              <span>
                <a href="#" onClick={(e) => handleLogout(e)}>
                Return to Login
                </a>
              </span>
            )}
          </span>
        </div>
        <div className={elementStyles.closeContainer}>
          <button
            onClick={resetAlert}
            className={elementStyles.iconContainer}
          >
            <IcomoonReact
              iconSet={iconSet}
              color={variables.darkGrey}
              size={12}
              icon="Control-CrossIcon-Large-GreyOutline"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FlashMessage

import React, { useCallback } from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import useUpdateSessionStatus from 'src/queries/sessions/useUpdateSessionStatus'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import {
  diffInDaysFromNow
} from 'src/utils/helpers/SessionHelperMethods'
import iconSet from 'src/assets/icons/iconList.json'
import agendaStyles from '../agendaStyles.module.scss'

const SessionRegButton = ({ attendee, currentEvent, detailView, session }) => {
  const { mutate, isPending } = useUpdateSessionStatus()

  let iconColor = variables.evantaBlue
  let icon = 'Control-AddIcon-Medium-BlueOutline'
  let text = 'Add to my Schedule'
  let disabled = false
  let visible = currentEvent.event_agenda_status_key === 'event_agenda_status_full_agenda'

  if (session.session_type_key === 'session_type_one_to_one_meeting') {
    if (
      attendee.registration_status_code_key !== 'registration_status_approved'
    ) {
      return (visible = false)
    }
    const diffInDays = diffInDaysFromNow(currentEvent.event_epoch_start_time)
    // event hasn't ended yet
    if (diffInDays >= 0) {
      if (
        currentEvent?.p2p_information
          ?.external_1_on_1_platform_selections_is_complete
      ) {
        icon = 'Control-EditIcon-BlueOutline'
        text = 'UPDATE PEER-TO-PEER MEETINGS'
      } else {
        icon = 'Symbol-SettingsIcon-Medium-BlueOutline'
        text = 'SET UP PEER-TO-PEER'
      }
    } else {
      if (session?.registration_status === 'un_register') {
        icon = 'Control-TickIcon-Medium-BlueSolid'
        text = 'On My Schedule'
        disabled = true
      }
    }
  } else {
    switch (session?.registration_status) {
      case 'un_register':
        text = 'On My Schedule'
        icon = 'Control-TickIcon-Medium-BlueSolid'
        break
      case 'spaces_available':
        text = 'Spaces Available'
        break
      case 'session_full':
        iconColor = variables.solar
        text = 'Session Full'
        icon = 'Control-CrossIcon-Large-RedSolid'
        disabled = true
        break
      case 'not_authorized':
        iconColor = variables.solar
        icon = ''
        text = 'Approval Required'
        disabled = true
        break
      default:
        break
    }
  }

  const handleButtonClick = useCallback(() => {
    if (session.session_type_key === 'session_type_one_to_one_meeting') {
      const callbackUrl = window.location
      window.open(
        `${currentEvent.p2p_information.external_1_on_1_platform_wizard_url}?wizard_from=web&callback=${callbackUrl}`,
        '_blank'
      )
    } else {
      mutate({ sessionId: session.id })
    }
  }, [session])

  if (!visible) {
    return <div></div>
  }

  return (
    <button
      name={text}
      className={classNames(agendaStyles.sessionRegBtnContainer, {
        [agendaStyles.detailButton]: detailView
      })}
      disabled={disabled || isPending}
      onClick={() => handleButtonClick()}
      data-testid='regButton'
    >
      {icon !== '' && (
        <IcomoonReact
          color={iconColor}
          size={18}
          icon={icon}
          iconSet={iconSet}
          className={generalStyles.mr20}
        />
      )}
      <span>{text}</span>
    </button>
  )
}

export default SessionRegButton

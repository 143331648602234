import React from 'react'
import classNames from 'classnames'
import QRCode from 'react-qr-code'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import userStyles from './userStyles.module.scss'

const QRCodePage = () => {
  const { currentUser } = useGlobalContext()
  const isMobileSize = useIsMobileQuery()

  return (
    <>
      <div
        className={classNames(
          generalStyles.alignVertical,
          generalStyles.flexColumn,
          userStyles.qrCode
        )}
      >
        <div className={generalStyles.mb10}>
          <UserImage
            photoUrl={currentUser?.photo}
            styles={userStyles.profileImage}
          />
        </div>
        <div>
          <span className={fontStyles.h2alternate2bolddark}>
            {currentUser?.first_name} {currentUser?.last_name}
          </span>
        </div>
        <div>
          <span className={fontStyles.bodybolddark}>{currentUser?.title}</span>
        </div>
        <div>
          <span className={fontStyles.bodybolddark}>
            {currentUser?.organization_name}
          </span>
        </div>

        {currentUser?.business_card_hash &&
          <div className={classNames(userStyles.qrCodeContainer, isMobileSize && userStyles.qrCodeContainerMobile)}>
            <QRCode value={currentUser.business_card_hash} />
          </div>
        }
      </div>
    </>
  )
}

export default QRCodePage

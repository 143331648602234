import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const deleteOneOnOneTimeslot = async (params, config) => {
  const response = await deleteData(`/virtual_portal/event_session_one_on_one_timeslot_survey_user_opt_outs/${params.id}`, { ...config })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useDeleteOneOnOneTimeslot = () => {
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => deleteOneOnOneTimeslot(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event_one_on_one_timeslot'] })
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useDeleteOneOnOneTimeslot

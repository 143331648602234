import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import UserIcons from './UserIcons'
import UserImage from './UserImage'
import elementStyles from './elements.module.scss'

const TypeProps = {
  user: PropTypes.object,
  textContent: PropTypes.string,
  isSmallPhoto: PropTypes.bool,
  isMedPhoto: PropTypes.bool,
  includeIcons: PropTypes.bool,
  points: PropTypes.string,
  currentUser: PropTypes.bool,
  speakerType: PropTypes.string
}

const UserRow = ({
  user,
  textContent,
  isSmallPhoto,
  isMedPhoto,
  speakerType,
  includeIcons,
  points,
  currentUser
}) => {
  const renderUserText = () => {
    let text = ''
    switch (textContent) {
      case 'points':
        text = (
          <div className={elementStyles.minHeight}>
            <span className={fontStyles.bodydemigrey}>{points}</span>
          </div>
        )
        break
      case 'speaker':
        text = (
          <>
            <span className={fontStyles.bodysmallaltdemigreycentered}>
              {user?.organization_name}
            </span>
            <span className={fontStyles.bodysmallaltdemigreycentered}>
              {user?.title}
            </span>
            <span className={fontStyles.bodysmallaltdemigreycentered}>
              {speakerType}
            </span>
          </>
        )
        break
      default:
        text = (
          <div className={elementStyles.minHeight}>
            <span className={fontStyles.bodydemigrey} data-testid="userOrg">
              {user?.organization_name}
            </span>
          </div>
        )
        break
    }
    return text
  }

  return (
    <div className={elementStyles.userRowContainer}>
      <div className={elementStyles.imageContainer} data-testid='userHeadshot'>
        <UserImage
          photoUrl={user?.photo}
          styles={classNames(generalStyles.mr20, elementStyles.profileImage, {
            [elementStyles.smallPhoto]: isSmallPhoto,
            [elementStyles.medPhoto]: isMedPhoto
          })}
          altText={`${user?.first_name} ${user?.last_name} Photo`}
        />
      </div>
      <div
        className={classNames(
          generalStyles.flexColumn,
          generalStyles.leftAlignedText
        )}
      >
        <div
          className={classNames(
            elementStyles.userRowContainer,
            generalStyles.mb05
          )}
        >
          <span className={fontStyles.bodybolddark} data-testid="userName">
            {currentUser ? 'You' : `${user?.first_name} ${user?.last_name}`}
          </span>
          {includeIcons && (
            <div className={generalStyles.ml20}>
              <UserIcons user={user} />
            </div>
          )}
        </div>
        {renderUserText()}
      </div>
    </div>
  )
}

UserRow.propTypes = {
  ...TypeProps
}

export default UserRow

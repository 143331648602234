import React from 'react'
import { isEmpty } from 'lodash'
import virtualPortalStyles from './components/virtualPortalStyles.module.scss'

const VirtualPortalHeader = ({ event }) => {
  if (isEmpty(event)) return ''
  return (
    <div>
      <div className={virtualPortalStyles.virtualPortalHeader}>
        <div className={virtualPortalStyles.virtualPortalHeaderContainer}>
          <div className={virtualPortalStyles.virtualPortalHeaderLogo}>
            <img src='https://cfassets.evanta.com/ar/production/evanta.com/Evanta_Logo_White.png' alt='loading page'/>
          </div>
          <div className={virtualPortalStyles.virtualPortalHeaderTitle}>
            <h4>{ event.website_display_name }</h4>
            <h5>{ event.event_begin_date }</h5>
          </div>
        </div>
      </div>
      <div>
        <h3>Registration for Peer-to-Peer meetings are now closed.</h3>
      </div>
    </div>
  )
}

export default VirtualPortalHeader

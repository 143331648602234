import React from 'react'
import classNames from 'classnames'
import EventCardSkeleton from 'src/features/programs/home/components/EventCardSkeleton'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import programStyles from './programStyles.module.scss'

const ProgramHomeSkeleton = () => {
  return (
    <div className={generalStyles.flexColumn}>
      <div className={classNames(generalStyles.mr20, generalStyles.pb20)}>
        <EventCardSkeleton
          largerHeight={true}
          currentEvent={true}
          eventCardType={'Up Next'}
        />
      </div>

      <div className={generalStyles.thirdWidth}>
        <div className={classNames(generalStyles.pt20, generalStyles.pb20)}>
          <div className={programStyles.fakeTitle} />
          <div
            className={classNames(
              programStyles.fakeTitle,
              programStyles.smaller,
              generalStyles.mt05
            )}
          />
          <div className={classNames(generalStyles.mt10, generalStyles.mb10)}>
            <HorizontalLine color={'lightGrey'} fullWidth={true} />
          </div>
          {[...Array(3)].map((x, i) => {
            return (
              <div
                key={i}
                className={classNames(
                  generalStyles.flexRow,
                  generalStyles.alignVertical,
                  generalStyles.pt10,
                  generalStyles.pb10
                )}
              >
                <div className={programStyles.fakeNumberContainer} />
                <div
                  className={classNames(
                    generalStyles.ml10,
                    programStyles.fakeText
                  )}
                />
              </div>
            )
          })}
          <div className={classNames(generalStyles.pt20, generalStyles.pb20)}>
            <div className={generalStyles.mb20}>
              <div className={programStyles.carouselCardContainer}>
                <div className={programStyles.header}>
                  <div
                    className={classNames(
                      generalStyles.mr20,
                      programStyles.fakeImageContainer
                    )}
                  />
                  <div
                    className={classNames(
                      generalStyles.flexColumn,
                      generalStyles.fullFlex
                    )}
                  >
                    <div
                      className={classNames(
                        generalStyles.mb10,
                        programStyles.fakeDescription
                      )}
                    />

                    <div className={programStyles.fakeBlockTitle} />
                  </div>
                </div>
                <div
                  className={classNames(generalStyles.mt20, generalStyles.mb10)}
                >
                  <HorizontalLine color={'white'} fullWidth={true} />
                </div>
                <div className={programStyles.fakeBlockTitle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramHomeSkeleton

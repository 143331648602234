export const initialSidePanel = {
  type: '',
  data: '',
  title: ''
}

export const initialAlertState = {
  title: '',
  message: '',
  forceLogout: false
}

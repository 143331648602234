import React from 'react'

const SettingsPage = () => {
  return (
    <div>
      <span>Settings</span>
    </div>
  )
}

export default SettingsPage

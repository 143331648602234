import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import {
  diffInDaysFromNow,
  otherEvaluationsCompletedInTimeblock,
  p2PSessionCheckForScheduledMeetings
} from 'src/utils/helpers/SessionHelperMethods'
import { ConditionalWrapper } from 'src/utils/ConditionalWrapper'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { isPastTime } from 'src/utils/helpers/GeneralHelpers'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import P2PView from './P2PView'
import SessionRegButton from './SessionRegButton'
import FeedbackButton from './FeedbackButton'
import agendaStyles from '../agendaStyles.module.scss'

const AgendaItemCard = ({
  topBorder,
  bottomBorder,
  inactive,
  session,
  handleCardClick,
  timeSectionHeaderIndex
}) => {
  const { currentEvent, eventId, currentUser, attendee, attendees, sessions } = useGlobalContext()
  const diffInDays = diffInDaysFromNow(currentEvent.event_epoch_start_time)

  const P2PSessionCheckForMeetings = useMemo(() => {
    const { session_type_key: sessionTypeKey } = session
    return sessionTypeKey === 'session_type_one_to_one_meeting' ? p2PSessionCheckForScheduledMeetings(session) : true
  }, [session])

  const renderActionButton = () => {
    if (isPastTime(session.epoch_start_time)) {
      if (
        (!otherEvaluationsCompletedInTimeblock(sessions, session) ||
          session.evaluation_completed) &&
        P2PSessionCheckForMeetings &&
        currentUser?.can_take_session_survey
      ) {
        return (
          <FeedbackButton session={session} />
        )
      }
    } else {
      return (
        <SessionRegButton
          attendee={attendee}
          currentEvent={currentEvent}
          detailView={false}
          session={session}
        />
      )
    }
  }

  const renderP2PMeetings = () => {
    if (
      session?.session_type_key === 'session_type_one_to_one_meeting' &&
      attendee?.is_approved &&
      currentEvent?.p2p_information
        ?.external_1_on_1_platform_selections_is_complete
    ) {
      if (session?.one_on_one_meetings?.length > 0) {
        const timeslots = session.one_on_one_meetings.map((mtg) => {
          const p2pUser = attendees.find(
            (a) => a?.user_id === mtg?.participant_user_id
          )
          return (
            <div
              key={`mtg-${mtg?.id || Math.random()}`}
              className={classNames(agendaStyles.p2pRow, {
                [agendaStyles.withHover]: p2pUser
              })}
            >
              <div className={agendaStyles.p2pContents}>
                <div
                  className={classNames(generalStyles.mt10, generalStyles.mb20)}
                >
                  <HorizontalLine color={'white'} fullWidth={true} />
                </div>
                <ConditionalWrapper
                  condition={p2pUser}
                  wrapper={(children) => (
                    <div
                      onClick={() => {
                        handleCardClick(session?.id)
                      }}
                    >
                      <Link
                        to={`/programs/${eventId}/attendees/${p2pUser?.user_id}`}
                      >
                        {children}
                      </Link>
                    </div>
                  )}
                >
                  <P2PView meeting={mtg} p2pUser={p2pUser} />
                </ConditionalWrapper>
              </div>
            </div>
          )
        })
        return <>{timeslots}</>
      }
    }
  }
  const p2pSelectionsStatus = (showElement, element) => {
    if (
      session?.session_type_key === 'session_type_one_to_one_meeting' &&
      attendee?.registration_status_code_key === 'registration_status_approved'
    ) {
      // diffInDays >= 0 indicates that the event hasn't ended yet
      if (diffInDays >= 0) {
        switch (element) {
          case 'icon':
            return !currentEvent?.p2p_information
              ?.external_1_on_1_platform_selections_is_complete
          default:
            return true
        }
      } else {
        /* if less than 6 days out and they have NOT completed the selections, DON"T show button */
        /* if less than 6 days out and they have completed the selections, show button */
        switch (element) {
          case 'icon':
            return false
          default:
            return currentEvent?.p2p_information
              ?.external_1_on_1_platform_selections_is_complete
        }
      }
    } else {
      // for all other session types, show or hide the element
      return showElement
    }
  }

  return (
    <div
      className={classNames(
        agendaStyles.agendaItemCardContainer,
        agendaStyles.withHover,
        { [agendaStyles.topBorder]: topBorder },
        { [agendaStyles.bottomBorder]: bottomBorder }
      )}
      // prefix id session in case there's other elements that share id
      id={`session-${session?.id}`}
      data-time-section={`${timeSectionHeaderIndex}`}
    >
      <div
        onClick={() => {
          handleCardClick(session?.id)
        }}
        data-testid='sessionDetails'
      >
        <div
          className={classNames(generalStyles.flexRow, generalStyles.flexApart)}
        >
          <div data-testid='sessionType'>
            <span
              className={classNames(fontStyles.h6demigrey, {
                [fontStyles.inactive]: inactive
              })}
            >
              {session?.session_type}
            </span>
          </div>
          {p2pSelectionsStatus(false, 'icon') && (
            <div data-testid='sessionAlert'>
              <IcomoonReact
                iconSet={iconSet}
                size={21}
                color={variables.solar}
                icon={'Symbol-AlertIcon-Circle-Red'}
              />
            </div>
          )}
        </div>

        <div className={classNames(generalStyles.mt10, generalStyles.mb10)} data-testid='sessionName'>
          <span
            className={classNames(fontStyles.h3bolddark, {
              [fontStyles.inactive]: inactive
            })}
          >
            {session?.name}
          </span>
          <div className={classNames(generalStyles.mt10, generalStyles.mb10)} data-testid='sessionRoom'>
            <span className={fontStyles.h6demigrey}>{session?.room_name}</span>
          </div>
        </div>
      </div>

      {renderP2PMeetings()}
      {attendee &&
        attendee?.is_approved &&
        p2pSelectionsStatus(true, 'button') && (
        <>
          <HorizontalLine color={'white'} fullWidth={true}/>
          <div
            data-testid='sessionActions'
            className={classNames(
              generalStyles.flexRow,
              generalStyles.flexApart,
              generalStyles.mt10,
              generalStyles.verticalAlign
            )}
          >
            {renderActionButton()}
          </div>
        </>
      )}
    </div>
  )
}

export default AgendaItemCard

import React from 'react'
import AgendaList from './AgendaList'
import { getFilteredSessions } from 'src/utils/helpers/SessionHelperMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'

const AgendaIndex = () => {
  const { sessions, isLoadingSessions } = useGlobalContext()
  const filteredSessions = getFilteredSessions(sessions, 'is_hidden')
  return (
    <div>
      <AgendaList sessions={filteredSessions} isLoadingSessions={isLoadingSessions} />
    </div>
  )
}

export default AgendaIndex

import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import iconSet from 'src/assets/icons/iconList.json'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import useClickOutside from 'src/hooks/useClickOutside'
import elementStyles from './elements.module.scss'

const TypeProps = {
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconSet: PropTypes.number,
  position: PropTypes.oneOf(['left', 'right']),
  items: PropTypes.arrayOf(PropTypes.shape({
    clickEvent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    iconColor: PropTypes.string,
    iconName: PropTypes.string,
    iconSize: PropTypes.number,
    internalNavigation: PropTypes.bool,
    key: PropTypes.any,
    label: PropTypes.string
  }))
}

const Dropdown = ({ iconName, iconColor, iconSize, position, items }) => {
  const [expandDropdown, setExpandDropdown] = useState(false)
  const clickRef = useRef()
  const navigate = useNavigate()

  useClickOutside(clickRef, () => setExpandDropdown(false))

  const renderItem = (item) => {
    return (
      <button
        key={item.key}
        className={classNames(
          generalStyles.flexRow,
          generalStyles.alignVertical,
          elementStyles.dropdownItemBtn
        )}
        onClick={() => {
          item.internalNavigation
            ? navigate(item.clickEvent)
            : window.open(item.clickEvent, '_blank')
        }}
        id={`option_${item.key}`}
      >
        <IcomoonReact
          iconSet={iconSet}
          color={item.iconColor}
          size={item.iconSize}
          icon={item.iconName}
        />
        <div className={generalStyles.ml20}>
          <span className={fontStyles.bodybolddark}>{item.label}</span>
        </div>
      </button>
    )
  }
  return (
    <div className={elementStyles.dropdownContainer} ref={clickRef} data-testid='dropdownMenu'>
      <button
        className={elementStyles.dropdownBtnContainer}
        onClick={() => setExpandDropdown(!expandDropdown)}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={iconSize}
          icon={iconName}
          className={classNames(
            elementStyles.dropdownBtnColor,
            elementStyles[iconColor]
          )}
        />
      </button>
      <div
        className={classNames(
          elementStyles.dropdownItemsContainer,
          elementStyles[position],
          {
            [elementStyles.hidden]: !expandDropdown
          }
        )}
        onMouseLeave={() => setExpandDropdown(false)}
      >
        {items.map((i) => {
          return renderItem(i)
        })}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  ...TypeProps
}

export default Dropdown

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchData } from 'src/api/api-utilities'

const fetchEvent = async (eventId) => {
  const response = await fetchData('/my_evanta/events/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchEvent = () => {
  const { eventId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: [`event_${eventId}`],
    queryFn: () => fetchEvent(eventId),
    enabled: !!eventId,
    retry: false
  })
  const event = isEmpty(data) ? {} : data.event
  return {
    currentEvent: event,
    isLoadingEvent: isLoading
  }
}

export default useFetchEvent

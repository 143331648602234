import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { transformThreads } from 'src/utils/helpers/MessagesHelpers'
import useFetchMessages from 'src/queries/messages/useFetchMessages'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import Loader from 'src/components/Elements/Loader'
import messageStyles from './messageStyles.module.scss'
import Thread from './components/Thread'

const ThreadIndex = () => {
  const queryClient = useQueryClient()
  const { eventId, currentUser, currentEvent } = useGlobalContext()
  const { messages, isLoadingMessages } = useFetchMessages()
  const messageThreads = useMemo(() => transformThreads(messages, currentUser))
  const isApprovedInternal = useMemo(() => currentEvent.registration_status === "Approved Internal", [currentEvent])


  const handleRefreshMessages = () => queryClient.invalidateQueries(`messages_${eventId}`)

  useEffect(() => {
    handleRefreshMessages()
  }, [eventId])

  if(isApprovedInternal) return <></>

  const renderMessageThreads = () => {
    if (messageThreads.length === 0) {
      return (
        <span className={fontStyles.bodyregulargrey}>
          Start a conversation by clicking the message button on an
          Attendee&apos;s profile.
        </span>
      )
    } else {
      return messageThreads.map((message) => (
        <div key={message.id}>
          <Thread
            userId={message.other_user_id}
            message={message}
            handleRefreshMessages={handleRefreshMessages}
          />
        </div>
      ))
    }
  }

  return (
    <div className={messageStyles.messagesContainer}>
      <div className={messageStyles.fixedHeader}>
        <div className={messageStyles.threadHeader}>
          <div
            className={classNames(
              generalStyles.flexRow,
              generalStyles.fullFlex,
              generalStyles.flexApart
            )}
          >
          </div>
        </div>
        <HorizontalLine color='lightGrey' fullWidth />
      </div>
      <div className={messageStyles.contentContainer}>
        <div className={messageStyles.content}>
          <div className={messageStyles.recentHeader}>
            <span className={fontStyles.h3alternate2bolddark}>Recent</span>
            <div className={classNames(generalStyles.mt10)}>
              <HorizontalLine color='lightGrey' fullWidth />
            </div>
          </div>
          {isLoadingMessages
            ? (
              <div
                className={classNames(
                  generalStyles.flexCentered,
                  generalStyles.mt30
                )}
              >
                <Loader />
              </div>
            )
            : (
              renderMessageThreads()
            )}
        </div>
      </div>
    </div>
  )
}

export default ThreadIndex

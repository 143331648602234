import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { postData } from 'src/api/api-utilities'

const postSignIn = async (params) => {
  const response = await postData('/users/sign_in', { user: { ...params } })
  return response
}

const useLogin = () => {
  const navigate = useNavigate()
  const { mutateAsync, mutate, isPending } = useMutation({
    mutationFn: postSignIn,
    onSuccess: (data) => {
      if (data.status === 200) {
        Cookies.set('token', data.data.auth_token)
        Cookies.set('email', data.data.email)
        if (data.data.temp_password) {
          navigate('/auth/update-password')
        } else {
          // This is for google analytics to trigger when user has logged in and is redirected to /programs
          navigate('/programs', { state: { fromLogin: true } })
        }
      }
    }
  })

  return { mutateAsync, mutate, isLoading: isPending }
}

export default useLogin

import React from 'react'

const SupportPage = () => {
  return (
    <div>
      <span>Support</span>
    </div>
  )
}

export default SupportPage

import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import EventTypeChip from './EventTypeChip'
import programStyles from '../programStyles.module.scss'

const PastEventCard = ({ event, eventCardType }) => {
  const navigate = useNavigate()
  const isMobileSize = useIsMobileQuery()

  const handleViewEvent = (e) => {
    e.preventDefault()
    if (event?.is_virtual_event) {
      return window.open(event?.virtual_portal_url, '_blank')
    } else {
      return navigate(`/programs/${event?.id}`)
    }
  }
  return (
    <button
      className={programStyles.pastEventContainer}
      onClick={(e) => handleViewEvent(e)}
      data-testid="eventCard"
    >
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.alignVertical
        )}
      >
        <EventTypeChip eventCardType={eventCardType} color={'darkGrey'} />
        {!isMobileSize && (
          <span className={fontStyles.h6demigrey}>
            {event.formatted_date_range}
          </span>
        )}
      </div>
      {isMobileSize && (
        <div className={generalStyles.mt10}>
          <span className={fontStyles.h6demigrey} data-testid="eventDate">
            {event.formatted_date_range}
          </span>
        </div>
      )}
      <div className={classNames(generalStyles.mt10, fontStyles.h3bolddark)} data-testid="eventName">
        {event.name}
      </div>
    </button>
  )
}

export default PastEventCard

import React, { useCallback, useState, useRef } from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import useClickOutside from 'src/hooks/useClickOutside'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import elementStyles from './elements.module.scss'

const TypeProps = {
  options: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string
    }))
  })),
  onSelect: PropTypes.func
}

const Option = ({ option, toggleFilter, selected, section }) => {
  const { label, icon } = option
  const isActive = selected[section.header] === label
  return (
    <div key={label}>
      <div className={classNames(elementStyles.filterOptionColumn)}>
        <button
          className={classNames(elementStyles.optionContainer, {
            [elementStyles.activeOption]: isActive
          })}
          onClick={toggleFilter}
          data-testid={option.optionKey}
        >
          <span style={{ textTransform: 'capitalize' }}>{label}</span>
          {icon && (
            <IcomoonReact
              className={classNames(elementStyles.optionIcon, {
                [elementStyles.activeIcon]: isActive
              })}
              iconSet={iconSet}
              size={16}
              icon={icon}
            />
          )}
        </button>
      </div>
    </div>
  )
}

const DropdownFilterBtn = ({
  options,
  onSelect
}) => {
  const clickRef = useRef()
  const [openDropdown, setOpenDropdown] = useState(false)
  // ensures all is the initial filter selected by default
  const [selected, setSelected] = useState(options.reduce((acc, curr) => {
    acc[curr.header] = 'all'
    return acc
  }, {}))

  useClickOutside(clickRef, () => setOpenDropdown(false))

  const toggleFilter = useCallback((option, section) => {
    const { header } = section
    const { label, id } = option
    const filters = { ...selected, [header]: label }
    setSelected(filters)
    onSelect({ ...selected, [header]: { label, id } })
  }, [onSelect, selected])

  return (
    <div className={classNames(generalStyles.flexRow, generalStyles.alignVertical, generalStyles.flexApart, generalStyles.mb20)}>
      <div className={elementStyles.dropdownFilterContainer} ref={clickRef}>
        <button
          className={elementStyles.dropdownFilterBtn}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <IcomoonReact
            iconSet={iconSet}
            color={variables.evantaBlue}
            size={16}
            icon="Symbol-FilterIcon"
          />
          <span className={elementStyles.text}>Filter</span>
          <IcomoonReact
            iconSet={iconSet}
            color={variables.evantaBlue}
            size={10}
            className={classNames(elementStyles.carat, {
              [elementStyles.caratFlip]: !openDropdown
            })}
            icon="Control-CloseCarat-Small-BlueOutline"
          />
        </button>
        <div
          className={classNames(elementStyles.filterOptionContainer, generalStyles.flexRow, {
            [elementStyles.hidden]: !openDropdown
          })}
        >
          <div className={generalStyles.flexRow}>
            {options.map((section, index) => {
              const showBorder = index !== options.length - 1
              return (
                <div key={`${section.header} - ${index}`} className={classNames(showBorder && generalStyles.borderRight, generalStyles.mr20, generalStyles.pr20)}>
                  <p
                    className={classNames(
                      fontStyles.h4demigrayuppercase,
                      generalStyles.mb20
                    )}
                    data-testid="header"
                  >
                    {section.header}
                  </p>
                  {section.items.map(option =>
                    <Option
                      key={`${option.label} - ${index}`}
                      option={option}
                      toggleFilter={() => toggleFilter(option, section)}
                      selected={selected}
                      section={section}
                    />)
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <span className={fontStyles.h6variant2demigrey} data-testid="currentFilter">
      Showing {Object.entries(selected).map(([header, label], index, array) => (
          <span
            key={header}
            style={{ textTransform: 'capitalize' }}
          >
            {header}:
            <span style={{ marginRight: 4, textTransform: 'capitalize' }} className={classNames(fontStyles.h6variant2demibluecentered, generalStyles.ml6)}
              data-testid="filterName">{label}</span>
            {index < array.length - 1 && '|'}
          </span>
        ))}
      </span>
    </div>
  )
}

DropdownFilterBtn.propTypes = {
  ...TypeProps
}

export default DropdownFilterBtn

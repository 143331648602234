class DropdownOption {
  constructor(
    label,
    key,
    iconName,
    iconSize,
    iconColor,
    clickEvent,
    internalNavigation
  ) {
    this.label = label
    this.key = key
    this.iconName = iconName
    this.iconSize = iconSize
    this.iconColor = iconColor
    this.clickEvent = clickEvent
    this.internalNavigation = internalNavigation
  }
}
export const programContactOptions = (contact) => {
  const actionArray = []
  if (contact?.email) {
    actionArray.push(
      new DropdownOption(
        `Email ${contact?.first_name}`,
        'email',
        'Symbol-EmailIcon-Medium-BlueOutline',
        16,
        'evantaBlue',
        `mailto:${contact?.email}`,
        false
      )
    )
  }
  if (contact?.phone) {
    actionArray.push(
      new DropdownOption(
        `Call ${contact?.first_name}`,
        'phone',
        'Symbol-PhoneIcon-Medium-BlueOutline',
        20,
        'evantaBlue',
        `tel:${contact?.phone}`,
        false
      )
    )
  }
  if (contact?.phone_mobile) {
    actionArray.push(
      new DropdownOption(
        `Text ${contact?.first_name}`,
        'text',
        'Symbol-MessageIcon-Medium-BlueOutline',
        20,
        'evantaBlue',
        `sms:${contact?.phone_mobile}`,
        false
      )
    )
  }
  if (contact?.linkedin_profile) {
    actionArray.push(
      new DropdownOption(
        `View ${contact?.first_name}'s LinkedIn`,
        'linkedin',
        'App-LinkedInIcon-Medium-Blue',
        20,
        'evantaBlue',
        `${contact?.linkedin_profile}`,
        false
      )
    )
  }
  return actionArray
}
export const photoEditingOptions = () => {
  const actionArray = []
  actionArray.push(
    // new DropdownOption(
    //   'Edit current profile photo',
    //   'editor',
    //   'Control-EditIcon-BlueOutline',
    //   20,
    //   'evantaBlue',
    //   '/edit-photo/editor',
    //   true
    // ),
    new DropdownOption(
      'Upload from photos',
      'upload',
      'Symbol-ImageIcon-Large-BlueOutline',
      20,
      'evantaBlue',
      '/edit-photo/upload',
      true
    ),
    new DropdownOption(
      'Take a photo',
      'camera',
      'Symbol-CameraIcon-BlueOutline',
      20,
      'evantaBlue',
      '/edit-photo/camera',
      true
    )
  )
  return actionArray
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import useFetchUpcomingEvents from 'src/queries/events/useFetchUpcomingEvents'
import EventCard from 'src/features/programs/home/components/EventCard'
import DropdownFilterBtn from 'src/components/Elements/DropdownFilterBtn'
import programStyles from './programStyles.module.scss'
import ViewMore from './ViewMore'

const options = [
  {
    header: 'status',
    items: [{ label: 'all', id: 'all' }, { label: 'registered', id: 'registered' }, { label: 'unregistered', id: 'unregistered' }]
  }
]

const registered = [
  'registration_status_pending',
  'registration_status_approved',
  'registration_status_approved_sponsor',
  'registration_status_approved_speaker',
  'registration_status_approved_internal',
  'registration_status_waitlisted',
  'registration_status_reapproval_needed'
]

const UpcomingEvents = () => {
  const [filter, setFilter] = useState('all')
  const [limit, setLimit] = useState(10)
  const [displayedData, setDisplayedData] = useState([])
  const [showMore, setShowMore] = useState(false)
  const { data, eventCount, isLoading } = useFetchUpcomingEvents({ limit, config: { suspense: limit === 10, refetchOnWindowFocus: false } })

  const shapedData = useMemo(() => {
    return data?.map(event => ({ ...event, filter_reg_status: registered.includes(event.registration_status_code_key) ? 'registered' : 'unregistered' }))
  }, [data])

  const upcomingEvents = useMemo(() => {
    if (filter === 'registered' || filter === 'unregistered') return shapedData.filter((event) => event.filter_reg_status === filter)
    else if (filter === 'all' && !showMore) return shapedData?.slice(0, 10)
    else return data
  }, [filter, showMore, shapedData])

  useEffect(() => {
    // When the component render it would only request 10 events from the api
    // if the user changes the filter dropdown to 'register' or 'unregistered'
    // this useEffect would load the data to all events to be able to filter correctly on the FE
    if (data?.length === 10 && filter !== 'all') {
      setLimit(0)
    }
  }, [data, filter])

  // This is so when refreshing the data <Suspense /> on the parent component won't trigger
  // to avoid rendering the skeleton after the first render
  useEffect(() => {
    if (!isLoading) {
      setDisplayedData(upcomingEvents)
    }
  }, [isLoading, upcomingEvents])

  const renderMessage = () => {
    if (isEmpty(upcomingEvents) && !isLoading) {
      if (filter === 'all' || filter === 'unregistered') {
        return (
          <p className={classNames(generalStyles.centeredText, generalStyles.mt40, fontStyles.h6variant2demigrey)}>
            No programs to show; please contact <a href="mailto:support@evanta.com">support@evanta.com</a> for assistance.</p>
        )
      } else {
        return (
          <p className={classNames(generalStyles.centeredText, generalStyles.mt40, fontStyles.h6variant2demigrey)}>
            Your registered programs will appear here.</p>
        )
      }
    }
  }

  const onClick = useCallback(() => {
    if (limit === 10) {
      setLimit(0)
    }

    setShowMore(!showMore)
  }, [limit, setLimit, showMore])

  return (
    <>
      <DropdownFilterBtn
        options={options}
        onSelect={({ status }) => setFilter(status.id)}
      />
      {displayedData?.map((event) => {
        return (
          <div
            key={event.id}
            className={classNames(
              programStyles.upcomingContainer,
              generalStyles.mb20
            )}
            data-testid={`upcomingEvent${event.id}`}
          >
            <EventCard
              event={event}
              eventCardType={event?.is_virtual_event ? 'Virtual' : 'In-person'}
              currentEvent={false}
            />
          </div>
        )
      })}
      {eventCount > 10 && filter === 'all' ? <ViewMore viewMore={showMore} onClick={onClick} /> : null}
      {renderMessage()}
    </>

  )
}

export default UpcomingEvents


import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import elementStyles from './elements.module.scss'

const TypeProps = {
  children: PropTypes.element,
  close: PropTypes.func,
  isOpen: PropTypes.bool
}

const modalElement = document.getElementById('modal-root')

const Modal = ({ children, close, isOpen = false }) => (
  createPortal(
    isOpen
      ? <div className={elementStyles.modal}>
        <div className={elementStyles.overlay} onClick={close} />
        <div className={elementStyles.modalBody}>{children}</div>
      </div>
      : null,
    modalElement
  )
)

Modal.propTypes = {
  ...TypeProps
}

export default Modal

import React from 'react'

const BookmarkedPage = () => {
  return (
    <div>
      <span>Bookmarked Page</span>
    </div>
  )
}

export default BookmarkedPage

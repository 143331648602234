import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { putData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postUserData = async (params) => {
  const tagsToAdd = []
  const tagsToDelete = []
  for (const key in params) {
    if (key.includes('_tag')) {
      const tagCodeKey = key.replace('_tag', '')
      if (params[key]) {
        tagsToAdd.push({ [tagCodeKey]: params[key] })
      } else {
        tagsToDelete.push({ [tagCodeKey]: params[key] })
      }
    }
  }
  const user = {
    ...params,
    tags: {
      add: tagsToAdd,
      delete: tagsToDelete
    }
  }
  const response = await putData('/my_evanta/users/profile', { user: { ...user } })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUpdateCurrentUser = () => {
  const { setAlert } = useToastAlert()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data, mutateAsync, mutate } = useMutation({
    mutationFn: postUserData,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['current_user'] })
      navigate('/profile')
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  const currentUser = isEmpty(data) ? {} : data.user

  return { currentUser, mutateAsync, mutate }
}

export default useUpdateCurrentUser

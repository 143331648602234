import { isEmpty } from 'lodash'

export const approvedStatuses = (event) => {
  return [
    'registration_status_approved',
    'registration_status_approved_sponsor',
    'registration_status_approved_speaker',
    'registration_status_approved_internal'
  ].includes(event?.registration_status_code_key)
}

export const eventEnded = (event) => {
  if (!isEmpty(event)) {
    const eventEndDate = event.end_date.split('T')[0].split('-')
    return (
      new Date().setHours(0, 0, 0, 0) >=
      new Date(eventEndDate[0], eventEndDate[1] - 1, eventEndDate[2])
    )
  }
}

export const dayBeforeEventHasStarted = (event) => {
  return (
    Math.floor(Date.now() / 1000) > event?.begin_date_to_epoch - 86400
  )
}

export const isOneDayPastEvent = (event) => {
  return new Date(event.end_date + 1) < new Date()
}

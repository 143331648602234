import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { postFile } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postMessage = async (params) => {
  const { body, recipientUserId, currentEventId } = params
  const response = await postFile('/my_evanta/messages/', { message: { body, recipient_user_id: recipientUserId }, event_id: currentEventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useCreateMessage = () => {
  const { eventId } = useParams()
  const { setAlert } = useToastAlert()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: postMessage,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [`messages_${eventId}`] })
    },
    onError: (error) => {
      const message = error.info || error.text
      setAlert({
        status: error.code,
        title: error.message,
        message: `${message}. Please refresh the page`,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useCreateMessage

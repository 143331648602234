import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import elementStyles from './elements.module.scss'

const TypeProps = {
  color: PropTypes.oneOf(['solar', 'solarOutline', 'evantaBlue', 'evantaBlueOutline', 'inactiveGray', 'greyOutline', 'noBtnContainer']).isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.elementType]).isRequired,
  size: PropTypes.oneOf(['large', 'med', 'small']),
  disabled: PropTypes.bool,
  lettercase: PropTypes.bool,
  textOnly: PropTypes.bool
}

const Button = ({
  color,
  onClick,
  label,
  size,
  disabled,
  lettercase,
  textOnly,
  style,
  id
}) => (
  <button
    name={label}
    className={classNames(
      elementStyles.button,
      elementStyles[`${size}CTA`],
      elementStyles[color],
      { [elementStyles.notUppercase]: lettercase },
      { [elementStyles.noPadding]: textOnly }
    )}
    onClick={onClick}
    disabled={disabled}
    id={id}
    style={{ ...style }}
  >
    {label}
  </button>
)

Button.propTypes = {
  ...TypeProps
}

export default Button

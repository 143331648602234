import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'src/assets/icons/iconList.json'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import layoutStyles from '../layouts.module.scss'

const RightSidePanelHeader = ({ icon, iconColor, title }) => (
  <div className={layoutStyles.sidePanelHeaderContainer}>
    <div className={layoutStyles.sidePanelText}>
      {icon && (
        <IcomoonReact
          iconSet={iconSet}
          color={iconColor}
          size={20}
          icon={icon}
          className={generalStyles.mr20}
        />
      )}
      <span className={fontStyles.h3alternate2bolddark}>{title}</span>
    </div>
    <div className={generalStyles.mt10} />
    <HorizontalLine color={'lightGrey'} fullWidth={true} />
  </div>
)

export default RightSidePanelHeader

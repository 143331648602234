import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchData } from 'src/api/api-utilities'

const fetchGbs = async (eventId) => {
  const response = await fetchData('/my_evanta/governing_body_members/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchGbs = () => {
  const { eventId } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: [`gbs_${eventId}`],
    queryFn: () => fetchGbs(eventId),
    enabled: !!eventId,
    staleTime: Infinity,
    retry: false
  })

  const gbs = isEmpty(data) ? [] : data.event_crm_contact_gbs

  return {
    gbs,
    isLoadingGbs: isLoading
  }
}

export default useFetchGbs

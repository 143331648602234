import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/Elements/Button'

const QrButton = ({ event }) => {
  const navigate = useNavigate()
  const eventId = event?.id
  const text = event?.is_attended ? 'QR CODE/SCANNER' : 'CHECK-IN'

  const onClick = (e) => {
    e.stopPropagation()
    navigate(`/programs/${eventId}/qr-code`)
  }

  return (
    <Button
      color='solar'
      onClick={onClick}
      label={text}
      size='med'
      disabled={false}
    />
  )
}

export default QrButton

import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import classNames from 'classnames'
import Button from 'src/components/Elements/Button'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'
import ImageEditor from 'src/features/settings/components/ImageEditor'

const EditProfile = ({ photo }) => {
  const navigate = useNavigate()
  const inputRef = useRef()
  const [image, setImage] = useState(photo)
  const [preview, setPreview] = useState(false)

  const handleFileUpload = async (e) => {
    e.preventDefault()
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type
      const validImageTypes = ['image/jpeg', 'image/png']
      if (validImageTypes.indexOf(fileType) < 0) {
        return false
      } else {
        setImage(e.target.files[0])
        setPreview(true)
      }
    }
  }

  const handleButtonClick = (e) => {
    e.preventDefault()
    if (!inputRef || !inputRef.current) return

    inputRef.current.click()
  }

  return (
    <div className={classNames(
      generalStyles.flexColumn,
      generalStyles.flexCentered,
      generalStyles.mt20,
      generalStyles.mb20,
      generalStyles.alignVertical)
    }>
      {preview
        ? <ImageEditor selectedImage={image} isWizard />
        : <>
          <img className={virtualPortalStyles.editProfilePicture} src={image} alt="user photo" />
          <div className={classNames(generalStyles.mt20, generalStyles.flexCentered)}>
            <Button color="solar" size='small' label="Cancel" onClick={() => navigate(-1)} />
            <Button onClick={handleButtonClick} color="solar" size='small' label="Upload photo" style={{ marginLeft: 8 }} />
          </div>
          <span className={classNames(fontStyles.bodydemigrey, generalStyles.mt05)}>jpeg or png files only</span>
          <input
            accept="image/png, image/jpeg"
            ref={inputRef} type='file' hidden onChange={handleFileUpload}
          />
        </>
      }
    </div>
  )
}

export default EditProfile

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const fetchSurveyUser = async (config) => {
  const response = await fetchData('/virtual_portal/sessions_from_token', { ...config })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useFetchSessionsFromToken = () => {
  const config = getQueryConfig()
  const { data, isLoading } = useQuery({
    queryKey: ['sessions_from_token', config],
    queryFn: () => fetchSurveyUser(config),
    staleTime: Infinity,
    enabled: !!config
  })

  if (data?.response?.status) {
    localStorage.setItem('wizard_error', `${Object.values(data.response.data.errors)[0]}`)
  }

  const sessions = isEmpty(data?.data) ? [] : data.data.sessions

  return {
    sessions: isEmpty(sessions) ? [] : sessions,
    isLoading
  }
}

export default useFetchSessionsFromToken

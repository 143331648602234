import React from 'react'
import PropTypes from 'prop-types'
import IcomoonReact from 'icomoon-react'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'

const TypeProps = {
  user: PropTypes.object
}

const UserIcons = ({ user }) => {
  const userIcons = []
  if (user?.is_biz_card) {
    userIcons.push('Control-TickIcon-Small-BlueSolidBold')
  }
  if (user?.is_gb) {
    userIcons.push('Symbol-GBIcon-Medium-BlueOutline')
  }
  if (user?.is_speaker) {
    userIcons.push('Symbol-SpeakerIcon-Medium-BlueOutline')
  }
  return userIcons.map((icon) => {
    return (
      <IcomoonReact
        iconSet={iconSet}
        icon={icon}
        size={19}
        key={icon}
        color={variables.evantaBlue}
        className={generalStyles.mr20}
      />
    )
  })
}

UserIcons.propTypes = {
  ...TypeProps
}

export default UserIcons

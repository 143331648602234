import { useQuery } from '@tanstack/react-query'
import { fetchData } from 'src/api/api-utilities'
import { isEmpty } from 'lodash'

const fetchPastEvents = async ({ queryKey }) => {
  const limit = queryKey[1]
  const response = await fetchData(`/my_evanta/past_events?limit=${limit}`)
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
  }
  return response.data
}

const useFetchPastEvents = ({ limit, config }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['past_events', limit],
    queryFn: fetchPastEvents,
    retry: false,
    ...config
  })

  return {
    data: isEmpty(data) ? [] : data.events,
    isLoading,
    eventCount: isEmpty(data) ? 0 : data?.meta?.event_count
  }
}

export default useFetchPastEvents

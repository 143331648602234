import React from 'react'
import { useGlobalContext } from 'src/stores/GlobalContext'
import NoUserDisplay from 'src/features/programs/program/users/components/NoUserDisplay'
import UserList from './UserList'

const AttendeeIndex = () => {
  const { filteredAttendees } = useGlobalContext()
  const userType = 'Attendees'
  if (filteredAttendees?.length === 0) {
    return <NoUserDisplay userType={userType} />
  }
  return <UserList users={filteredAttendees} userType={userType} />
}

export default AttendeeIndex

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/Elements/Button'
import virtualPortalStyles from './components/virtualPortalStyles.module.scss'

const CompleteSurvey = ({ survey }) => {
  return (
    !survey.lps_is_complete && <div className='bottom-buttons margin-top-10'>
      <div>
        <a href={survey.lps_survey_link}
          target='_blank'
          rel="noopener noreferrer" >
            Complete Leadership Perspective Survey <i className="fa fa-external-link"></i>
        </a>
      </div>
    </div>
  )
}

const Thankyou = ({ survey }) => {
  const navigate = useNavigate()

  const redirect = useCallback(() => {
    navigate(`/programs/${survey.event.id}`)
  }, [navigate, survey])

  return (
    <div className={virtualPortalStyles.thankyouPage}>
      <h1>Your peer-to-peer meeting setup is complete!</h1>
      <p>To return to the Evanta App, please click the button below.</p>
      <div className={virtualPortalStyles.thankyouPageFooter}>
        <CompleteSurvey survey={survey} />
        <Button color="solar" onClick={() => redirect()} label="CLOSE" />
      </div>
    </div>
  )
}

export default Thankyou

import { useMutation } from '@tanstack/react-query'
import { postData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const postContactForm = async (params) => {
  const { message, email, template, eventId } = params
  const response = await postData('/my_evanta/supports', {
    support_request: {
      title: 'From App Event Support',
      description: message + template,
      app_support_email: email,
      event_id: eventId
    }
  })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const usePostContactForm = () => {
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: postContactForm,
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })
  return { mutateAsync, mutate }
}

export default usePostContactForm

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { putData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const updateUserProfile = async (params, config) => {
  const response = await putData('/virtual_portal/profile', { user: params }, config)
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUpdateProfile = () => {
  const navigate = useNavigate()
  const params = useParams()
  const config = getQueryConfig()
  const queryClient = useQueryClient()
  const { setAlert } = useToastAlert()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: (params) => updateUserProfile(params, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['survey_user', config] })
      navigate(`/${params.token}/wishlist/wizard`)
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useUpdateProfile

/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import { groupBy, isEmpty, map, orderBy, uniqBy } from 'lodash'

// Filter messages by recipient id and sort messages from oldest to newest
export const sortMessages = (messages, currentUser, otherUserId) => {
  return orderBy(messages.filter(message => (currentUser.id === message.sender_user_id ||
    currentUser.id === message.recipient_user_id) &&
  (otherUserId === message.sender_user_id ||
    otherUserId === message.recipient_user_id)
  ), (message) => message.created_at, ['desc'])
}

export const transformTime = (time) => {
  return new Date(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

export const transformDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const formatter = new Intl.DateTimeFormat('en-US', options)
  return formatter.format(new Date(date))
}

export const transformMessages = (messages, currentUser, otherUser) => {
  if (!isEmpty(messages) && currentUser && otherUser) {
    const filteredMessagesByUser = sortMessages(messages, currentUser, otherUser.user_id)
      .map(message => ({
        ...message,
        date: message.created_at.split('T')[0]
      }))
    const groupMessagesByDate = groupBy(filteredMessagesByUser, 'date')
    return map(groupMessagesByDate, (messageByDate) => {
      return messageByDate.map(message => {
        const user = message.sender_user_id === currentUser.id ? currentUser : otherUser
        return ({
          id: message.id,
          createdAt: message.created_at,
          date: message.date,
          text: message.message,
          timeframe: message.created_at,
          user: {
            id: message.sender_user_id,
            name: `${user.first_name} ${user.last_name}`,
            avatar: user.photo
          }
        })
      })
    })
  }
}

export const transformThreads = (messages, currentUser) => {
  if (isEmpty(messages)) return []
  else {
    let threads = {}
    messages.map(message => {
      const { id, recipient_user_id, sender_user_id, has_been_read, created_at } = message
      const isSender = sender_user_id === currentUser.id
      const unreadMessages = threads[recipient_user_id]?.data_count + 1 || 1
      return (
        threads = {
          ...threads,
          [recipient_user_id]: {
            ...threads[recipient_user_id],
            is_sender: isSender,
            other_user_id: isSender ? recipient_user_id : sender_user_id,
            data_count: !has_been_read && !isSender ? unreadMessages : 0,
            created_at,
            most_recent_message: message.message,
            id
          }
        }
      )
    })

    const orderMessages = orderBy(Object.values(threads), (m) => m.created_at, ['desc'])
    return uniqBy(orderMessages, (message) => message.other_user_id)
  }
}

export const unreadMessagesCount = (messages, currentUser) => {
  if (!isEmpty(messages)) {
    return messages.filter(
      (msg) =>
        !msg.has_been_read &&
        msg.recipient_user_id === currentUser.id
    ).length
  } else {
    return 0
  }
}

import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { fetchData } from 'src/api/api-utilities'

const fetchCurrentUser = async () => {
  const response = await fetchData('/my_evanta/current_user')
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchCurrentUser = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['current_user'],
    queryFn: fetchCurrentUser,
    retry: false
  })

  const currentUser = isEmpty(data) ? {} : data.user

  return {
    currentUser,
    isLoadingCurrentUser: isLoading,
    isErrorCurrentUser: isError
  }
}

export default useFetchCurrentUser

import React, { useCallback, useState } from 'react'
import Modal from 'src/components/Elements/Modal'
import Button from 'src/components/Elements/Button'

const ErrorForm = ({
  handleTokenSubmit,
  token,
  message,
  handleTokenInputChange,
  newToken,
  submit
}) => {
  if (message === 'Network Error') {
    return <p>Please try connecting to the internet and try again.</p>
  } else {
    return <form onSubmit={handleTokenSubmit}>
      <p>Provided Access Key: { token }</p>
      <input id="access_key" name="access_key"style={{ width: '75%', marginBottom: '10px', padding: '5px' }} placeholder='Access Key' value={newToken} onChange={handleTokenInputChange} />
      <Button color="solar" label="Submit" style={{ padding: '10px', borderRadius: '0.25rem' }} onClick={submit} />
    </form>
  }
}

const ErrorModal = ({ token, message }) => {
  const [newToken, setNewToken] = useState('')

  const handleTokenInputChange = useCallback((e) => {
    setNewToken(e.target.value)
  }, [])

  const submit = useCallback((e) => {
    localStorage.removeItem('wizard_error')
    e.preventDefault()
    window.location = `${window.location.origin}/${newToken}/wishlist/wizard`
  }, [newToken])

  return (
    <div>
      <Modal isOpen={true}>
        <div>
          <h2>Sorry, we’re having issues connecting you to your event.</h2>
          <p>Ensure you’re using Google Chrome browser for the best experience.</p>
          <p>Please enter your access key below for the event you are trying to access.</p>
          <p>For your access key or further assistance, please reach out for help using the <a className='live-chat-link' onClick={() => window.expandLiveChat()}>LiveChat</a> window.</p>
          <hr />
          <div>
            <ErrorForm
              message={message}
              token={token}
              newToken={newToken}
              handleTokenInputChange={handleTokenInputChange}
              submit={submit}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ErrorModal

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'src/components/Elements/Button'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import preloginStyles from './prelogin.module.scss'

const SignUpForm = () => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('..')
  }

  return (
    <div>
      <PreloginNavigationHeader back={true} onClick={() => handleBackClick()} />
      <div className={preloginStyles.contentBodyContainer}>
        <span className={fontStyles.h1regulardark}>Sign up to MyEvanta</span>
        <p>
          Do you Qualify? |{' '}
          <Link to="/qualifications">View Qualification Criteria</Link>
        </p>
        <Button
          label={'CONTINUE'}
          onClick={() => {
            navigate('/profile')
          }}
        />
      </div>
    </div>
  )
}

export default SignUpForm

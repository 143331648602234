import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import elementStyles from './elements.module.scss'

const TypeProps = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.any
}

const Switch = ({ label, onChange, enabled, disabled, id }) => (
  <div
    key={id}
    className={classNames(
      generalStyles.flexRow,
      generalStyles.flexApart,
      generalStyles.alignCentered
    )}
  >
    <span className={fontStyles.bodyregulardark}>{label}</span>
    <button
      id={id}
      className={{ height: 20, width: 50 }}
      onClick={() => onChange(!enabled)}
      disabled={disabled}
    >
      <div
        className={classNames(elementStyles.trackStyle, {
          [elementStyles.switchTrackOff]: !enabled
        })}
      >
        <span
          className={classNames(
            elementStyles.switchText,
            { [elementStyles.switchTextOn]: enabled },
            { [elementStyles.switchTextOff]: !enabled }
          )}
        >
          {enabled ? 'ON' : 'OFF'}
        </span>
        <div
          className={classNames(
            elementStyles.thumbStyle,
            { [elementStyles.on]: enabled },
            { [elementStyles.off]: !enabled }
          )}
        />
      </div>
    </button>
  </div>
)

Switch.propTypes = {
  ...TypeProps
}

export default Switch

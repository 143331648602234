import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import Badge from 'src/components/Elements/Badge'
import NavItem from './NavItem'
import navStyles from '../navigation.module.scss'

const NavLinkItem = ({ badge, isMobile, hoverTitle, iconName, isActive }) => {
  return (
    <>
      {badge && <Badge styles={navStyles.badge} />}
      {isMobile && isActive && <div className={navStyles.selectedItem} />}
      <NavItem
        hoverTitle={hoverTitle}
        iconName={iconName}
        isActive={isActive}
        size={isMobile ? null : 16}
        isMobile={isMobile}
      />
      {isMobile && <span>{hoverTitle}</span>}
    </>
  )
}

const CustomNavLink = ({
  name,
  isDefaultLink,
  linkAddress,
  hoverTitle,
  iconName,
  eventId,
  badge,
  handleGaTracking,
  gaPrefix,
  isMobile,
  closeMenu
}) => {
  const googleTracking = useAnalyticsTracking()
  const link = isDefaultLink ? `/programs/${eventId}` : `/programs/${eventId}/${linkAddress}`

  const onClick = () => {
    if (!isDefaultLink && handleGaTracking) {
      googleTracking(`${gaPrefix}_${name}`, 'event_page')
    }
    closeMenu()
  }

  return (
    <NavLink to={link} end={isDefaultLink}>
      {({ isActive }) => (
        <div
          className={isMobile ? navStyles.navLinkContainerMobile : navStyles.navLinkContainer}
          onClick={onClick}
        >
          <NavLinkItem
            isActive={isActive}
            iconName={iconName}
            badge={badge}
            hoverTitle={hoverTitle}
            isMobile={isMobile}
          />
        </div>
      )
      }
    </NavLink>
  )
}

export default CustomNavLink

import React from 'react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import MessageHeader from 'src/features/messages/components/MessageHeader'
import MessageList from 'src/features/messages/MessageList'
import FlashMessage from 'src/components/Elements/FlashMessage'
import layoutStyles from '../layouts.module.scss'

const RightMainContentPanel = () => {
  const { sidePanelDetails } = useGlobalContext()
  return (
    <div className={layoutStyles.rightMainContentPanel}>
      <FlashMessage />
      <div className={layoutStyles.rightMainContent}>
        <div className={layoutStyles.headerContents}>
          <div className={layoutStyles.fixedHeader}>
            {sidePanelDetails.type && <MessageHeader data={sidePanelDetails.data} />}
          </div>
        </div>
        <div
          className={classNames(layoutStyles.rightMainContentContainer, {
            [layoutStyles.scrollable]: sidePanelDetails.type === 'search'
          })}
        >
          <div className={layoutStyles.content}>
            {sidePanelDetails.type && <MessageList data={sidePanelDetails.data} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightMainContentPanel

import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import ProgramsSkeleton from './ProgramsSkeleton'
import UpcomingEvents from './UpcomingEvents'
import PastEvents from './PastEvents'
import NextEvent from './NextEvent'

const ProgramsHomePage = () => {
  const googleTracking = useAnalyticsTracking()
  const { state } = useLocation()

  googleTracking('login_complete', 'sign_in_page', null, state?.fromLogin || false)

  return (
    <Suspense fallback={<ProgramsSkeleton />}>
      <div className={generalStyles.smallWidth}>
        <NextEvent />
        <UpcomingEvents />
        <PastEvents />
      </div>
    </Suspense>
  )
}

export default ProgramsHomePage

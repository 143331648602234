import React, { useState } from 'react'
import VirtualPortalStyles from '../wizard/components/virtualPortalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import classNames from 'classnames'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import HelpAndSupport from './components/HelpAndSupport'

const BlueHeader = ({ event }) => {
  const [openModal, setOpenModal] = useState(false)
  const eventTime = `${event.event_ics_begin_time} - ${event.event_ics_end_time} ${event.timezone_abbreviation}`
  return <div className={VirtualPortalStyles.virtualHeader}>
    <div className={VirtualPortalStyles.virtualHeaderInner}>
      <img
        src="/assets/images/evanta_logo_white.png"
        alt="Evanta Logo"
        style={{ width: 85, height: 'auto' }}
      />
      <div>
        <h1 style={{ margin: 0 }} className={fontStyles.h2boldlight}>{event.website_display_name}</h1>
        <div className={classNames(generalStyles.flexRow, generalStyles.alignVertical)}>
          <p style={{ margin: 0, fontWeight: 300 }} className={fontStyles.bodyboldlight}>{event.event_begin_date}</p>
          <div className={VirtualPortalStyles.dividerWhite} />
          <p style={{ margin: 0, fontWeight: 300 }} className={fontStyles.bodyboldlight}>{eventTime}</p>
        </div>
      </div>
      <IoIosInformationCircleOutline cursor="pointer" width={30} color="white" onClick={() => setOpenModal(!openModal)} />
      {openModal && <HelpAndSupport openModal={openModal} setOpenModal={setOpenModal} />}
    </div>
  </div>
}

export default BlueHeader

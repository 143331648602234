import React from 'react'
import classNames from 'classnames'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import programStyles from '../programStyles.module.scss'

const ImageWithText = ({ clickEvent, imageUrl, linkText, overlayText }) => (
  <button
    onClick={clickEvent}
    className={classNames(programStyles.imageWithTextButton)}
  >
    <div
      className={programStyles.backgroundImage}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className={programStyles.textContainer}>
        <span className={fontStyles.h2boldlight} data-testid='overlayText'>{overlayText}</span>
        <div className={generalStyles.mt40}>
          <span className={fontStyles.bodyvarianttitlecaselight} data-testid='linkText'>
            {linkText}
          </span>
        </div>
      </div>
    </div>
  </button>
)

export default ImageWithText

import React from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import Badge from 'src/components/Elements/Badge'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import messageStyles from '../messageStyles.module.scss'

const Thread = ({ userId, message, handleRefreshMessages }) => {
  const { attendees, setSidePanelDetails, sidePanelDetails, isLoadingAttendees } = useGlobalContext()
  const attendee = attendees?.find(user => user.user_id === userId)

  const handleMessageBtn = () => {
    setSidePanelDetails({ type: 'message', data: userId })
    handleRefreshMessages()
  }
  const getTimeframe = (time) => {
    const diff = Math.abs(new Date() - new Date(time))
    const secs = Math.floor(diff / 1000)
    const mins = Math.floor(secs / 60)
    let timeframe = ''
    if (mins < 60) {
      timeframe = `${mins}m`
    } else {
      const hours = Math.floor(mins / 60)
      if (hours < 24) {
        timeframe = `${hours}h`
      } else {
        const days = Math.floor(hours / 24)
        if (days < 31) {
          timeframe = `${days}d`
        } else {
          timeframe = '30d+'
        }
      }
    }
    return timeframe
  }

  if (isLoadingAttendees || isEmpty(attendee)) return <></>

  return (
    <div className={generalStyles.flexColumn}>
      <button
        className={classNames(
          generalStyles.flexRow,
          generalStyles.fullFlex,
          generalStyles.leftAlignedText,
          { [generalStyles.userRowButton]: sidePanelDetails.data !== userId }
        )}
        onClick={() => handleMessageBtn()}
      >
        <div className={generalStyles.mr20}>
          <UserImage
            photoUrl={attendee?.photo}
            altText={attendee ? `${attendee.first_name} ${attendee.last_name} photo` : ''}
            styles={messageStyles.userPhoto}
          />
        </div>

        <div
          className={classNames(
            generalStyles.flexColumn,
            generalStyles.fullFlex
          )}
        >
          <div
            className={classNames(
              generalStyles.flexRow,
              generalStyles.flexApart
            )}
          >
            <span
              className={classNames(
                messageStyles.message,
                fontStyles.bodyboldgrey,
                {
                  [fontStyles.bodyboldblue]: sidePanelDetails.data === userId
                }
              )}
            >
              {attendee?.first_name} {attendee?.last_name}
            </span>
            <div className={generalStyles.flexColumn}>
              <span className={fontStyles.bodysmallaltdemigreycentered}>
                {getTimeframe(message?.created_at)}
              </span>
            </div>
          </div>
          <div>
            <div
              className={classNames(
                generalStyles.flexRow,
                generalStyles.flexApart
              )}
            >
              <span
                className={classNames(messageStyles.message, {
                  [fontStyles.bodyregulardark]: message?.data_count === 0,
                  [fontStyles.bodybolddark]: message?.data_count !== 0
                })}
              >
                {message?.is_sender ? 'You' : attendee?.first_name}:{' '}
                {message?.most_recent_message}
              </span>

              {message?.data_count > 0 && (
                <Badge
                  number={message?.data_count}
                  styles={messageStyles.messageBadge}
                />
              )}
            </div>
          </div>
        </div>
      </button>
      <div className={classNames(generalStyles.mt20, generalStyles.mb20)}>
        <HorizontalLine fullWidth color='lightGrey' />
      </div>
    </div>
  )
}

export default Thread

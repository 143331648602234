import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import Button from 'src/components/Elements/Button'

const EvaluationHeader = ({
  session,
  setSubmitForm,
  submitForm,
  formValid
}) => {
  return (
    <div>
      <div>
        <span className={fontStyles.bodybolddark}>
          {session?.session_type} Evaluation
        </span>
      </div>

      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6variant2demidark}>{session?.name}</span>
      </div>
      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6variant2demigrey}>
          {session?.room_name}
        </span>
      </div>
      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6variant2demigrey}>
          {session?.formatted_display_date} | {session?.formatted_display_time}
        </span>
      </div>

      <div className={generalStyles.mt10}>
        <span className={fontStyles.h6variant2demigrey}>
          Please rate 1 = lowest and 5 = highest
        </span>
      </div>
      <div className={classNames(generalStyles.mt20, generalStyles.mb10)}>
        <HorizontalLine color={'lightGrey'} fullWidth={true} />
      </div>
      <div className={generalStyles.mb10}>
        <Button
          color={formValid ? 'evantaBlue' : 'evantaBlueOutline'}
          onClick={() => setSubmitForm(!submitForm)}
          label={'Submit'}
          size={'med'}
          disabled={!formValid}
        />
      </div>
      <HorizontalLine color={'lightGrey'} fullWidth={true} />
    </div>
  )
}

export default EvaluationHeader

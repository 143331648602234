import React from 'react'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserRow from 'src/components/Elements/UserRow'

const P2PView = ({ meeting, p2pUser }) => {
  return (
    <div className={generalStyles.mb10}>
      <div className={generalStyles.mb10}>
        <span className={fontStyles.h6demidark}>{meeting.time_block}</span>
      </div>
      {p2pUser
        ? <div className={generalStyles.mt10}>
          {p2pUser && <UserRow key={p2pUser?.user_id} user={p2pUser} />}
        </div>
        : <span className={fontStyles.bodydemigrey}>TBD</span>
      }
    </div>
  )
}

export default P2PView

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import PreloginNavigationHeader from 'src/features/navigation/PreloginNavigationHeader'
import preloginStyles from './prelogin.module.scss'

const HelpAndSupport = () => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/auth/signin')
  }
  const links = [
    {
      text: 'Terms & Conditions',
      link: 'https://www.evanta.com/terms/terms-and-conditions',
      key: 'tc'
    },
    {
      text: 'Privacy Policy',
      link: 'https://www.evanta.com/terms/privacy-policy',
      key: 'pp'
    },
    {
      text: 'Official Sweepstakes Rules',
      link: 'https://www.evanta.com/terms/sweepstakes-rules',
      key: 'osr'
    },
    {
      text: 'Official Leaderboard Rules',
      link: 'https://www.evanta.com/terms/leaderboard-rules',
      key: 'olr'
    }
  ]
  return (
    <div data-testid="supportMenu">
      <PreloginNavigationHeader back={true} onClick={() => handleBackClick()} />
      <div className={preloginStyles.contentBodyContainer}>
        <span className={fontStyles.h1regulardark} data-testid="header">Help & Support</span>
        <div
          className={classNames(
            generalStyles.mt40,
            generalStyles.mb40,
            preloginStyles.helpWrapper
          )}
        >
          <span className={fontStyles.bodydemigrey} data-testid="body">
            For general help please <Link to="/auth/contact" data-testid="contactLink">contact us</Link>
          </span>
        </div>
        <div>
          {links.map((l) => {
            return (
              <div
                key={l.key}
                className={classNames(
                  generalStyles.flexRow,
                  generalStyles.verticalAlign,
                  generalStyles.mb20
                )}
                data-testid={l.key}
              >
                <div className={preloginStyles.tickContainer}>
                  <div className={preloginStyles.tickOutline}>
                    <IcomoonReact
                      icon={'Control-TickIcon-Medium-BlueOutline'}
                      iconSet={iconSet}
                      color={variables.evantaBlue}
                      size={11}
                    />
                  </div>
                </div>
                <div className={generalStyles.ml20}>
                  <span className={fontStyles.bodydemigrey}>
                    View the{' '}
                    <a href={l.link} target="_blank" rel="noreferrer">
                      {l.text}
                    </a>
                  </span>
                </div>
              </div>
            )
          })}
        </div>
        <span className={fontStyles.bodydemigrey} data-testid="body">
        To submit a request regarding your personal information, including deletion,<a href="https://www.gartner.com/personaldatarequest" target="_blank" rel="noreferrer"> click here.</a>
        </span>
      </div>
    </div>
  )
}

export default HelpAndSupport

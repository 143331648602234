import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import agendaStyles from '../../agendaStyles.module.scss'

const TypeProps = {
  commentText: PropTypes.string.isRequired,
  updateCommentMethod: PropTypes.func.isRequired,
  commentId: PropTypes.number
}

const CommentInput = ({ commentText, updateCommentMethod, commentId }) => (
  <div key={`commentInput-${commentId}`}>
    <div className={generalStyles.mb20}>
      <span className={fontStyles.bodybolddark}>Additional Comments:</span>
    </div>
    <div
      className={classNames(generalStyles.flexColumn, generalStyles.fullFlex)}
    >
      <textarea
        className={agendaStyles.textareaInput}
        id="comments"
        onChange={(e) => updateCommentMethod(e.target.value, commentId)}
        value={commentText}
        rows="10"
        placeholder="Write here..."
      />
    </div>
  </div>
)

CommentInput.propTypes = {
  ...TypeProps
}

export default CommentInput

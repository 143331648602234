import React from 'react'
import classNames from 'classnames'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import Button from 'src/components/Elements/Button'
import ExpandableDiv from 'src/components/Elements/ExpandableDiv'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import programStyles from '../programStyles.module.scss'

const LocationBlock = ({
  block,
  venueName,
  venueUrl,
  mapUrl,
  venueInfo,
  reservationInfo,
  reservationUrl
}) => {
  const renderMap = () => {
    const adjustedMap = mapUrl.replace(
      'iframe src',
      'iframe width="100%" height="300" src'
    )
    return (
      <div
        data-testid='locationMap'
        dangerouslySetInnerHTML={{
          __html: `
        <!DOCTYPE html>
        <html>
          <head></head>
          <body style="background: #EFEFF0; width: 100%">
            <div id="baseDiv">${adjustedMap}</div>
          </body>
        </html>
  `
        }}
      ></div>
    )
  }
  const renderInfo = () => {
    if (venueInfo !== null) {
      return (
        <ExpandableDiv
          toggleableDiv={
            <div
              className={classNames(
                generalStyles.pt20,
                generalStyles.pl20,
                generalStyles.pr20
              )}
              dangerouslySetInnerHTML={{ __html: venueInfo }}
            />
          }
          unexpandedText={'VIEW MORE INFORMATION'}
          expandedText={'VIEW LESS INFORMATION'}
          testID={'ViewInfo'}
        />
      )
    }
  }
  const renderResInfo = () => {
    if (reservationInfo !== null && reservationUrl !== null) {
      return (
        <div
          className={classNames(
            generalStyles.pb20,
            generalStyles.pl20,
            generalStyles.pr20
          )}
          data-testid='resInfo'
        >
          <div dangerouslySetInnerHTML={{ __html: reservationInfo }} data-testid='resInfoBody'/>
          <div className={generalStyles.flexCentered} data-testid='resInfoButton'>
            <Button
              color={'evantaBlue'}
              onClick={() => window.open(reservationUrl)}
              label={'reserve a room'}
              size={'small'}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div key={block?.id} className={programStyles.locationBlockContainer}>
      <div className={programStyles.header} data-testid='locationHeader'>
        <span className={fontStyles.h3alternate2bolddark} data-testid='locationTitle'>{block?.title}</span>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine color={'white'} fullWidth={true} />
        </div>
        <a
          className={fontStyles.bodyboldblue}
          href={venueUrl}
          target="_blank"
          rel="noreferrer"
          data-testid="venueName"
        >
          {venueName}
        </a>
      </div>
      {renderMap()}
      {renderInfo()}
      {renderResInfo()}
    </div>
  )
}

export default LocationBlock

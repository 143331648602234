import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import Button from 'src/components/Elements/Button'
import UserRow from 'src/components/Elements/UserRow'
import useFetchSponsors from 'src/queries/sponsors/useFetchSponsors'
import partnerStyles from './partnerStyles.module.scss'

const PartnerDetailPage = () => {
  const { partnerId, eventId, currentEvent, attendees } = useGlobalContext()
  const { partner } = useFetchSponsors(partnerId)
  const navigate = useNavigate()
  const handleWebsiteClick = () => {
    window.open(partner.url)
  }
  const handleContactClick = (userId) => {
    navigate(`/programs/${eventId}/attendees/${userId}`)
  }
  return (
    <div className={generalStyles.thirdWidth}>
      <div className={partnerStyles.partnerHeader}>
        <div
          className={classNames(generalStyles.flexColumn, generalStyles.pt40)}
        >
          <span className={fontStyles.h2alternate2bolddark}>
            {partner?.sponsor_type_name}
          </span>
          <div className={generalStyles.mt40}>
            <img src={partner?.logo} className={partnerStyles.detailLogo} />
          </div>
        </div>

        <div className={generalStyles.mt40}>
          <HorizontalLine fullWidth={true} color={'lightGrey'} />
        </div>
        <div className={generalStyles.mt20}>
          <Button
            color={'evantaBlue'}
            label={'View Website'}
            size={'med'}
            onClick={() => {
              handleWebsiteClick()
            }}
          />
        </div>
        <div className={generalStyles.mt20}>
          <HorizontalLine fullWidth={true} color={'lightGrey'} />
        </div>
      </div>

      <div className={generalStyles.mt40}>
        <span className={fontStyles.h2alternate2bolddark}>Overview</span>
        <div className={generalStyles.mt10}>
          <HorizontalLine fullWidth={true} color={'lightGrey'} />
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: partner?.description }} />
      {currentEvent?.is_attended && partner?.sponsor_contacts?.length > 0 && (
        <>
          <div className={generalStyles.mt40}>
            <span className={fontStyles.h2alternate2bolddark}>Contacts</span>
            <div className={classNames(generalStyles.mt10, generalStyles.mb10)}>
              <HorizontalLine fullWidth={true} color={'lightGrey'} />
            </div>
          </div>
          {partner.sponsor_contacts.map((contact) => {
            const user = attendees.find((a) => a.user_id === contact.user_id)
            return (
              <button
                key={user.user_id}
                className={generalStyles.userRowButton}
                onClick={() => handleContactClick(user.user_id)}
              >
                <UserRow user={user} />
              </button>
            )
          })}
        </>
      )}
    </div>
  )
}

export default PartnerDetailPage

import React from 'react'
import VirtualPortalStyles from '../components/virtualPortalStyles.module.scss'

const Footer = () => {
  if (window.visitingOneOnOneWizardFrom) return ''

  return (
    <div className={VirtualPortalStyles.virtualFooter}>
      <footer style={{ padding: 20 }}>
        <p style={{ color: 'white' }}>&copy; { new Date().getFullYear() } Evanta, all rights reserved. View our
          <i><a style={{ color: 'white', marginRight: 4 }} href='https://www.evanta.com/terms/privacy-policy' target='_blank' rel='noopener noreferrer'> Privacy Policy</a></i>
          or <i><a style={{ color: 'white' }}href='https://www.evanta.com/terms/terms-and-conditions' target='_blank' rel='noopener noreferrer'> Terms and Conditions</a>.</i></p>
      </footer>
    </div>
  )
}

export default Footer

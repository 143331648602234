import React from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'src/components/Elements/Button'
import virtualPortalStyles from './virtualPortalStyles.module.scss'

const FooterButtons = ({ isLoading, onClick, onPrev }) => {
  const location = useLocation()
  const isLastPage = location.pathname.split('/')[2] === 'availability'

  return (
    <div className={virtualPortalStyles.footerButtons}>
      <Button
        color='solarOutline'
        label='SAVE & PREVIOUS'
        style={{ marginRight: '12px' }}
        onClick={onPrev}
        size='med'
      />
      <Button
        color='solar'
        disabled={isLoading}
        label={isLoading ? 'SAVING' : isLastPage ? 'SAVE & COMPLETE' : 'SAVE & CONTINUE'}
        onClick={onClick}
        size='med'
      />
    </div>
  )
}

export default FooterButtons

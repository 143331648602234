import React, { useMemo, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { useGlobalContext } from 'src/stores/GlobalContext'
import UserRow from 'src/components/Elements/UserRow'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import Button from 'src/components/Elements/Button'
import Loader from 'src/components/Elements/Loader'
import useFetchLeaderboard from 'src/queries/leaderboard/useFetchLeaderboard'
import leaderboardStyles from './leaderboardStyles.module.scss'
import CurrentUserRankingComponent from './components/CurrentUserRankingComponent'

const Leaderboard = () => {
  let scrollRef = useRef(null)
  const { eventId, currentUser: user, currentEvent, attendees } = useGlobalContext()
  const { leaderboardRules, leaderboardUsers } = useFetchLeaderboard()
  const filteredLeaderboardUsers = useMemo(() => leaderboardUsers.filter(user => user.leaderboard_points > 0), [leaderboardUsers])
  const attendeesObj = attendees.map(user => ({ [user.user_id]: user }))
  const currentUser = attendees.find((a) => a.user_id === user.id)

  const allowLeaderboard = currentUser?.allow_leaderboard
  const attendeesToObj = Object.assign({}, ...attendeesObj)

  const navigate = useNavigate()

  const renderTopThree = () => {
    if (filteredLeaderboardUsers !== null) {
      return filteredLeaderboardUsers.slice(0, 3).map((u) => {
        const user = attendeesToObj[u.user_id]
        return (
          <div
            key={user?.id}
            className={classNames(
              leaderboardStyles.topThreeContainer,
              generalStyles.flexRow
            )}
          >
            <div className={leaderboardStyles.rankingContainer}>
              <span className={fontStyles.h1bolddark}>{u?.rank}</span>
            </div>
            <Link to={`/programs/${eventId}/attendees/${user?.user_id}`}>
              <UserRow
                isMedPhoto={true}
                user={user}
                textContent={'points'}
                points={`${u?.formatted_points} points`}
              />
            </Link>
          </div>
        )
      })
    }
  }
  const createLink = (rule) => {
    switch (rule.code_key) {
      case 'biz_card_exchange':
        return navigate(`/programs/${eventId}/scanner`)
      case 'prize':
        return navigate(`/programs/${eventId}/scanner?true`)
      case 'session_survey':
        return navigate(`/programs/${eventId}/agenda`)
      case 'p2p_survey':
        return navigate(`/programs/${eventId}/agenda`)
      case 'profile_details':
        return navigate('/profile')
      case 'profile_photo':
        return navigate('/edit-profile')
      case 'event_survey':
        return window.open(currentEvent?.event_eval_link, '_blank')
      default:
        break
    }
  }

  const renderLeaderboardOptions = () => {
    return leaderboardRules.map((rule) => {
      return (
        <button
          key={rule.display_rank}
          disabled={!allowLeaderboard}
          onClick={() => createLink(rule)}
          className={leaderboardStyles.lbRuleBtn}
        >
          <div className={generalStyles.mb10}>
            <span
              className={classNames(fontStyles.h6demidark, {
                [fontStyles.inactive]: !allowLeaderboard
              })}
            >
              {rule.points_allocated} {rule.points_label}
            </span>
          </div>

          <span
            className={classNames(fontStyles.h3alternate2bolddark, {
              [fontStyles.inactive]: !allowLeaderboard
            })}
          >
            {rule.app_label}
          </span>
        </button>
      )
    })
  }

  if (isEmpty(attendeesToObj)) {
    return (
      <div className={leaderboardStyles.spinnerWrapper}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={classNames(generalStyles.thirdWidth, generalStyles.pb50)}>
      <span className={fontStyles.h2alternate2bolddark}>
        Today&#39;s Ranking
      </span>
      <div className={generalStyles.mt10}>
        <HorizontalLine color='lightGrey' fullWidth />
      </div>
      <div
        className={classNames(
          generalStyles.mt20,
          generalStyles.mb30,
          generalStyles.flexRow,
          generalStyles.flexApart
        )}
      >
        <CurrentUserRankingComponent leaderboardUsers={leaderboardUsers} currentUser={currentUser} />
        <Button
          color={allowLeaderboard ? 'evantaBlueOutline' : 'greyOutline'}
          onClick={() => scrollRef.scrollIntoView()}
          label='Get Points'
          size='med'
          disabled={!allowLeaderboard}
        />
      </div>
      { renderTopThree()}
      <div
        className={classNames(
          generalStyles.mt20,
          generalStyles.mb30,
          generalStyles.flexCentered
        )}
      >
        <button
          onClick={() => navigate(`/programs/${eventId}/leaderboard-ranking`)}
          className={classNames(
            generalStyles.hoverGrayBtn,
            generalStyles.flexRow,
            generalStyles.alignVertical
          )}
          disabled={!allowLeaderboard}
        >
          <span className={fontStyles.h6demigrey}>View Full Leaderboard</span>
          <IcomoonReact
            icon='Control-ForwardCarat-Large-LightGreyOutline'
            iconSet={iconSet}
            color={variables.evantaBlue}
            size={10}
            className={generalStyles.ml20}
          />
        </button>
      </div>
      <span
        className={fontStyles.h3alternate2bolddark}
        ref={(ref) => (scrollRef = ref)}
      >
        Points system
      </span>
      <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
        <HorizontalLine color='lightGrey' fullWidth />
      </div>
      <div className={generalStyles.mb20}>
        {allowLeaderboard
          ? (
            <span>
            All actions below must be taken during the event to be counted. You
            will automatically be awarded points if you have already uploaded
            your profile photo and/or completed your profile.
            </span>
          )
          : (
            <span>
            We&apos;re sorry, you are ineligible to participate in the
            leaderboard.
            </span>
          )}
      </div>
      <div className={generalStyles.flexColumn}>
        {renderLeaderboardOptions()}
      </div>
    </div>
  )
}

export default Leaderboard

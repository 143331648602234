import React from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'

const ViewMore = ({ onClick, viewMore }) => {
  return (
    <div className={generalStyles.pb20}>
      <div className={generalStyles.flexCentered}>
        <button
          onClick={onClick}
          className={classNames(
            generalStyles.flexRow,
            generalStyles.alignVertical,
            generalStyles.hoverGrayBtn
          )}
        >
          <span className={fontStyles.h6demigrey}>
            {viewMore ? 'VIEW LESS' : 'VIEW MORE'}
          </span>
          <IcomoonReact
            iconSet={iconSet}
            icon={
              viewMore
                ? 'Control-CloseCarat-Small-BlueOutline'
                : 'Control-OpenCarat-Small-BlueOutline'
            }
            size={10}
            color={variables.evantaBlue}
            className={generalStyles.ml20}
          />
        </button>
      </div>
    </div>
  )
}

export default ViewMore

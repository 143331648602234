import React, { useEffect, useState } from 'react'
import parse, { domToReact } from 'html-react-parser'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import iconSet from 'src/assets/icons/iconList.json'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { getSession } from 'src/utils/helpers/SessionHelperMethods'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import AgendaDetailHeader from 'src/features/programs/program/agenda/components/AgendaDetailHeader'
import AgendaDetail from 'src/features/programs/program/agenda/AgendaDetail'
import EvaluationHeader from 'src/features/programs/program/agenda/components/Evaluation/EvaluationHeader'
import SessionEvaluation from 'src/features/programs/program/agenda/SessionEvaluation'
import Fab from 'src/features/programs/program/fab/fabIndex'
import P2PEvaluation from 'src/features/programs/program/agenda/P2PEvaluation'
import useFetchSpeakers from 'src/queries/speakers/useFetchSpeakers'
import useFetchHomepageBlocks from 'src/queries/homepageBlocks/useFetchHomepageBlocks'
import RightSidePanelHeader from './RightSidePanelHeader'
import layoutStyles from '../layouts.module.scss'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'

const RightSidePanel = () => {
  const { homepageBlocks } = useFetchHomepageBlocks()
  const { currentEvent, sidePanelDetails, resetSidePanelDetails, sessions } = useGlobalContext()
  const speakersData = useFetchSpeakers()
  const homepageBlock = homepageBlocks?.find(block => block.id === sidePanelDetails?.data)
  const session = getSession(sessions, sidePanelDetails.data)
  const googleTracking = useAnalyticsTracking()

  const [submitForm, setSubmitForm] = useState(false)
  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    setSubmitForm(false)
    setFormValid(false)
  }, [sidePanelDetails])

  const renderHeader = () => {
    switch (sidePanelDetails?.type) {
      case 'agenda_detail':
        return <AgendaDetailHeader currentEvent={currentEvent} session={session} />
      case 'evaluation':
      case 'p2p_evaluation':
        return (
          <EvaluationHeader
            session={session}
            setSubmitForm={setSubmitForm}
            submitForm={submitForm}
            formValid={formValid}
          />
        )
      case 'fab':
        return (
          <RightSidePanelHeader
            icon={'Symbol-AlertIcon-Circle-Red'}
            iconColor={variables.solar}
            title={sidePanelDetails?.title}
          />
        )
      default:
        return <RightSidePanelHeader title={sidePanelDetails?.title} />
    }
  }

  const renderContent = () => {
    switch (sidePanelDetails?.type) {
      case 'agenda_detail':
        return <AgendaDetail
          currentEvent={currentEvent}
          speakersData={speakersData}
          session={session}
        />
      case 'evaluation':
        return (
          <SessionEvaluation
            session={session}
            formSubmitted={submitForm}
            setFormValid={setFormValid}
            speakersData={speakersData}
          />
        )
      case 'p2p_evaluation':
        return (
          <P2PEvaluation
            session={session}
            formSubmitted={submitForm}
            setFormValid={setFormValid}
          />
        )
      case 'homepage_block':
        return (
          <div className={generalStyles.pb40}>
            {parse(homepageBlock?.html_body || '', {
              replace: (domNode) => {
                if (domNode.name === 'a') {
                  const onClick = () => {
                    if (homepageBlock?.type_key.includes('research')) {
                      const linkText = domNode.children[0].data
                      googleTracking('gartner_research_outbound_link', 'event_page', undefined, true, { click_text: linkText })
                    }
                  }
                  return (
                    <a onClick={onClick} href={domNode.attribs.href} target='_blank' rel='noreferrer'>{domToReact(domNode.children)}</a>
                  )
                }
              }
            })}
          </div>
        )
      case 'fab':
        return <Fab type="content" />
      default:
        return (
          <div className={generalStyles.pb40}>
            <span className={fontStyles.h3alternate2bolddark}>
              {sidePanelDetails?.data}
            </span>
          </div>
        )
    }
  }

  return (
    <div className={layoutStyles.sidePanelContentContainer} data-testid='rightSidePanel'>
      <div className={layoutStyles.closeContainer} data-testid='closeSidePanel'>
        {sidePanelDetails.type && (
          <button
            className={classNames(
              layoutStyles.iconContainer,
              layoutStyles.closeButton
            )}
            onClick={resetSidePanelDetails}
          >
            <IcomoonReact
              iconSet={iconSet}
              color={variables.darkGrey}
              size={12}
              icon="Control-CrossIcon-Large-GreyOutline"
            />
          </button>
        )}
      </div>
      <div id="fixedHeader" className={layoutStyles.fixedHeader} data-testid='rightPanelHeader'>
        {sidePanelDetails.type && renderHeader()}
      </div>
      <div className={layoutStyles.contentContainer} data-testid='rightPanelContent'>
        <div
          className={layoutStyles.content}
        >
          {sidePanelDetails.type && renderContent()}
        </div>
      </div>

    </div>
  )
}

export default RightSidePanel

import React from 'react'
import classNames from 'classnames'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Button from 'src/components/Elements/Button'

const AgendaFilters = ({ initialFilters, filters, onFilterChange }) => {
  const toggleFilter = (key) => {
    const updatedFilters = { ...initialFilters() }
    updatedFilters[key].active = !updatedFilters[key].active
    onFilterChange(updatedFilters)
  }

  const renderFilters = () => {
    const filtersToDisplay = []
    for (const filter in filters) {
      filtersToDisplay.push(
        <Button
          key={filter}
          color={filters[filter].active ? 'evantaBlue' : 'greyOutline'}
          onClick={() => toggleFilter(filter)}
          label={filters[filter].filterName}
          size={'med'}
          lettercase={true}
        />
      )
    }
    return filtersToDisplay
  }

  return (
    <>
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.mt20,
          generalStyles.gap20
        )}
      >
        {renderFilters()}
      </div>
      <div className={generalStyles.mt20}>
        <HorizontalLine color={'lightGrey'} fullWidth={true} />
      </div>
    </>
  )
}

export default AgendaFilters

import React, { useEffect, useState } from 'react'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import virtualPortalStyles from './virtualPortalStyles.module.scss'

const WizardHeader = ({
  currentUser,
  tab,
  token,
  visitingOneOnOneWizardFrom
}) => {
  const [index, setIndex] = useState([])

  useEffect(() => {
    if (tab === 'wishlist_rank') {
      setIndex([0])
    }
    if (tab === 'availability') {
      setIndex([0, 1])
    }
    if (tab === 'wishlist') {
      setIndex([])
    }
  }, [tab])

  return (
    <>
      {!visitingOneOnOneWizardFrom
        ? (
          ''
        )
        : (
          <div className="welcome">
            <h2 className={virtualPortalStyles.welcomeTitle}>
              Welcome {currentUser?.first_name} {currentUser?.last_name}
            </h2>
            <h5 className={virtualPortalStyles.welcomeSubtitle}>
              {currentUser?.title}, {currentUser?.organization_name}
            </h5>
          </div>
        )}
      <p className={fontStyles.bodydemigrey}>
        Thank you for taking part in peer-to-peer meetings. To personalize your
        experience, please complete the following steps.
      </p>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '12%' }} className={virtualPortalStyles.wizardLinks}>
          <div
            className={virtualPortalStyles.activeTab}
          >
            <a href={sanitizeHtml(`/${token}/detail/wizard${location.search}`)}>
            MY DETAILS
            </a>
          </div>
        </div>
        <div className={virtualPortalStyles.divider} />
        <div className={virtualPortalStyles.wizardLinks}>
          <div
            className={classNames(
              tab === 'wishlist'
                ? virtualPortalStyles.active
                : virtualPortalStyles.tabs,
              index.includes(0) && virtualPortalStyles.activeTab
            )}
          >
            <a href={tab === 'wishlist' ? sanitizeHtml(`/${token}/wishlist/wizard${location.search}`) : null}>
            MY PREFERENCES
            </a>
          </div>
          <div
            className={classNames(
              tab === 'wishlist_rank'
                ? virtualPortalStyles.active
                : virtualPortalStyles.tabs,
              index.includes(1) && virtualPortalStyles.activeTab
            )}
          >
            <a href={tab === 'wishlist_rank' ? sanitizeHtml(`/${token}/wishlist_rank/wizard${location.search}`) : null}>
            RANK PREFERENCES
            </a>
          </div>
          <div
            className={
              tab === 'availability'
                ? virtualPortalStyles.active
                : virtualPortalStyles.tabs
            }
          >
            <a href={tab === 'availability' ? sanitizeHtml(`/${token}/availability/wizard${location.search}`) : null}>
            AVAILABILITY
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default WizardHeader

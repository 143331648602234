import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { postData } from 'src/api/api-utilities'
import { useToastAlert } from 'src/stores/ToastContext'

const updateSessionStatus = async (params) => {
  const response = await postData('/my_evanta/event_session_surveys', {
    event_session_survey: {
      ...params
    }
  })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response
}

const useUpdateSessionEvalStatus = () => {
  const { eventId } = useParams()
  const { setAlert } = useToastAlert()
  const queryClient = useQueryClient()
  const { mutateAsync, mutate } = useMutation({
    mutationFn: updateSessionStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [`sessions_${eventId}`] })
    },
    onError: (error) => {
      setAlert({
        status: error.code,
        title: error.message,
        message: error.info || error.text,
        forceLogout: false
      })
    }
  })

  return { mutateAsync, mutate }
}

export default useUpdateSessionEvalStatus

import React from 'react'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'
import preloginLayoutStyles from './layouts.module.scss'

const showAnimation = () => {
  return window.location.pathname === '/auth/signin'
}

const PreLoginLayout = () => {
  return (
    // only animated if directed towards /auth/signin
    // if window directed towards other /auth child route, we do not want animation
    <div
      className={classNames(preloginLayoutStyles.preloginContainer, {
        [preloginLayoutStyles.preloginContainerAnimated]: showAnimation()
      })}
    >
      <div className={preloginLayoutStyles.logoContainer} data-testid="preloginHeader">
        <img
          src="/assets/images/evanta_logo_white.png"
          alt="Evanta Logo"
          className={preloginLayoutStyles.logo}
        />
      </div>
      <div
        className={classNames(preloginLayoutStyles.contentContainer, {
          [preloginLayoutStyles.contentContainerAnimated]: showAnimation()
        })}
        data-testid="preloginContentContainer">
        <Outlet />
      </div>
    </div>
  )
}

export default PreLoginLayout

import React from 'react'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import navStyles from '../navigation.module.scss'

const NavItem = ({ hoverTitle, iconName, isActive, size = 16, isMobile }) => (
  <div className={classNames(
    generalStyles.ml10,
    navStyles.navItemContainer
  )}>
    <div
      data-title={hoverTitle}
      className={classNames(
        navStyles.iconContainer,
        isActive && navStyles.iconContainerActive,
        isMobile && navStyles.iconMobileWrapper
      )}
    >
      <IcomoonReact
        iconSet={iconSet}
        color={variables.white}
        size={size}
        style={isMobile ? { verticalAlign: 'initial' } : null}
        icon={
          isActive
            ? `Symbol-${iconName}OutlineBold`
            : `Symbol-${iconName}Outline`
        }
      />
    </div>
  </div>
)

export default NavItem

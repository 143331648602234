import React, { useState, useEffect } from 'react'
import { sortedEventSessionSpeakers } from 'src/utils/helpers/SessionHelperMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import StarRating from 'src/features/programs/program/agenda/components/Evaluation/StarRating'
import CommentInput from 'src/features/programs/program/agenda/components/Evaluation/CommentInput'
import UserEvaluation from 'src/features/programs/program/agenda/components/Evaluation/UserEvaluation'
import PartnerFollowUpQuestion from 'src/features/programs/program/agenda/components/Evaluation/PartnerFollowUpQuestion'
import useUpdateSessionEvalStatus from 'src/queries/sessions/useUpdateSessionEvalStatus'

const SessionEvaluation = ({ speakersData, session, formSubmitted, setFormValid }) => {
  const { speakers } = speakersData
  const { eventId, resetSidePanelDetails, sessions } = useGlobalContext()
  const { mutate } = useUpdateSessionEvalStatus()
  const eventSessionSpeakers = sortedEventSessionSpeakers(sessions.find(s => s.id === session.id))

  /// Form Elements
  // Sponsor FollowUp
  const [requestFollowup, setRequestFollowup] = useState(null)
  // Comments
  const [overallComment, setOverallComment] = useState('')
  // Ratings
  const [overallRating, setOverallRating] = useState(0)
  // use speaker id to send to API
  const [userRatingList, setUserRatingList] = useState(
    eventSessionSpeakers?.map((s) => {
      return { [s.id]: 0 }
    })
  )
  const updateOverallRating = (number) => {
    setOverallRating(number)
  }
  const updateUserRatingList = (number, userId) => {
    const index = userRatingList?.findIndex(
      (l) => Object.keys(l)[0] === userId.toString()
    )
    const newList = [...userRatingList]
    newList[index][userId] = number
    setUserRatingList(newList)
  }
  /// Form Handling
  // Reset Form on Session Change
  useEffect(() => {
    setRequestFollowup(null)
    setOverallComment('')
    setOverallRating(0)
    setUserRatingList(
      eventSessionSpeakers?.map((s) => {
        return { [s.id]: 0 }
      })
    )
  }, [session])

  // Validate Submit Button
  useEffect(() => {
    // If Overall Rating, Required
    const overallRatingCompleted = overallRating !== 0
    // If Speakers, Required
    const usersRatingCompleted =
      userRatingList?.length > 0
        ? !userRatingList?.some((l) => Object.values(l)[0] === 0)
        : true
    // If Session Follow Up, Required
    const sessionSponsorQCompleted =
      session?.is_followup_meeting_question_enabled
        ? requestFollowup !== null
        : true
    setFormValid(
      overallRatingCompleted && usersRatingCompleted && sessionSponsorQCompleted
    )
  }, [overallRating, requestFollowup, userRatingList])

  const handleFormData = async (formData) => {
    await mutate(formData)
    resetSidePanelDetails()
  }

  useEffect(() => {
    if (formSubmitted) {
      const speakers = userRatingList?.map((s) => {
        return {
          event_session_speaker_id: parseInt(Object.keys(s)[0]),
          rating: Object.values(s)[0]
        }
      })
      const formData = {
        event_session_id: session?.id,
        rating: overallRating,
        comments: overallComment,
        event_session_survey_speakers: speakers,
        requests_sponsor_followup: requestFollowup
      }
      handleFormData(formData)
    }
  }, [formSubmitted])

  /// Component Rendering
  const renderUserRatings = () => {
    if (eventSessionSpeakers?.length > 0) {
      return (
        <>
          <span key="userHeader" className={fontStyles.bodybolddark}>
            How effective was/were the speaker(s)?
          </span>
          {eventSessionSpeakers.map((speaker) => {
            const user = speakers.find((f) => f?.user_id === speaker?.user_id)
            return (
              <div key={speaker?.id} className={generalStyles.mt20}>
                <UserEvaluation
                  eventId={eventId}
                  user={user}
                  userType={'speaker'}
                  userLabel={speaker?.speaker_label}
                  updateRatingMethod={updateUserRatingList}
                  ratingId={speaker?.id}
                  userRating={
                    userRatingList?.[
                      userRatingList?.findIndex(
                        (l) => Object.keys(l)[0] === speaker?.id.toString()
                      )
                    ]?.[speaker?.id]
                  }
                />
              </div>
            )
          })}
        </>
      )
    }
  }

  return (
    <div key="session-eval" className={generalStyles.pb40}>
      <>{session?.is_followup_meeting_question_enabled
        ? (
          <PartnerFollowUpQuestion
            radioValue={requestFollowup}
            setRadioValue={setRequestFollowup}
            session={session}
          />
        )
        : null}
      <div className={generalStyles.mt20}>
        <span className={fontStyles.bodybolddark}>
            How would you rate the session&apos;s content?
        </span>
      </div>
      <StarRating
        itemId="overallRating"
        updateRating={updateOverallRating}
        rating={overallRating}
      />
      </>
      {renderUserRatings()}
      <CommentInput
        commentText={overallComment}
        updateCommentMethod={setOverallComment}
      />
    </div>
  )
}

export default SessionEvaluation

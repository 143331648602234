import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import EventCard from 'src/features/programs/home/components/EventCard'
import useFetchNextEvent from 'src/queries/events/useFetchNextEvent'

const NextEvent = () => {
  const { nextEvent } = useFetchNextEvent()

  if (isEmpty(nextEvent)) return <></>

  return (
    <div key={nextEvent.id} className={generalStyles.mb40} data-testid="upNextEvent">
      <Link to={`/programs/${nextEvent?.id}`}>
        <EventCard
          event={nextEvent}
          eventCardType='Up Next'
          largerHeight
          currentEvent={false}
        />
      </Link>
    </div>
  )
}

export default NextEvent

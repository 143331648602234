import { useQuery } from '@tanstack/react-query'
import { isEmpty, groupBy } from 'lodash'
import { fetchData } from 'src/api/api-utilities'
import { getQueryConfig } from 'src/utils/helpers/VirtualPortalHelpers'

const fetchOneOnOneTimeslot = async (config) => {
  const response = await fetchData('/virtual_portal/event_session_one_on_one_timeslots', { ...config })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchOneOnOneTimeslot = () => {
  const config = getQueryConfig()
  const { data, isLoading } = useQuery({
    queryKey: ['event_one_on_one_timeslot', config],
    queryFn: () => fetchOneOnOneTimeslot(config),
    staleTime: Infinity,
    enabled: !!config
  })

  const timeslots = isEmpty(data) ? [] : groupBy(data.event_session_one_on_one_timeslots, 'event_date')
  const p2pDates = isEmpty(data) ? [] : Object.keys(groupBy(data.event_session_one_on_one_timeslots, 'event_date'))

  return {
    timeslots,
    p2pDates,
    isLoadingTimeslots: isLoading
  }
}

export default useFetchOneOnOneTimeslot

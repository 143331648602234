import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchData } from 'src/api/api-utilities'

const fetchFab = async (eventId) => {
  const response = await fetchData('/my_evanta/floating_action_button/', { eventId })
  if (response.status < 200 || response.status > 300) {
    const error = new Error('Error')
    error.code = response.status
    error.info = response.data
    error.text = response.statusText
    throw error
  }
  return response.data
}

const useFetchFAB = () => {
  const { eventId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: [`event_fab_${eventId}`],
    queryFn: () => fetchFab(eventId),
    staleTime: Infinity,
    enabled: !!eventId,
    retry: false
  })
  const fab = isEmpty(data) ? [] : data.floating_action_button

  return {
    fab,
    isLoadingFAB: isLoading
  }
}

export default useFetchFAB

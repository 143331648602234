import React from 'react'
import UserRow from 'src/components/Elements/UserRow'

const CurrentUserRankingComponent = ({ currentUser, leaderboardUsers }) => {
  const currentUserLeaderboard = leaderboardUsers.find(
    (u) => u?.user_id === currentUser?.user_id
  )

  const renderCurrentUserPointsAndRank = () => {
    const points = currentUserLeaderboard?.formatted_points || 0
    const rank =
      currentUserLeaderboard === null || points === 0
        ? ''
        : `| Rank ${currentUserLeaderboard?.rank}`
    return `${points} points ${rank}`
  }

  return (
    <UserRow
      key={currentUser?.id}
      user={currentUser}
      textContent='points'
      isMedPhoto
      points={renderCurrentUserPointsAndRank()}
      currentUser
    />
  )
}

export default CurrentUserRankingComponent

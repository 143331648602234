import React from 'react'
import classNames from 'classnames'
import { useIsMobileQuery } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import messageStyles from '../messageStyles.module.scss'
import MessageMobileBackBtn from './MessageMobileBackBtn'

const MessageHeader = ({ data }) => {
  const { attendees } = useGlobalContext()
  const attendee = attendees.find(user => user.user_id === parseInt(data))
  const isMobileSize = useIsMobileQuery()
  return (
    <div className={messageStyles.messageHeader}>
      <div className={messageStyles.searchInputContainer}>
        {/* <MessageSearch /> */}
        {isMobileSize && <MessageMobileBackBtn />}
      </div>
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.alignVertical,
          generalStyles.mt10
        )}
      >
        <div className={generalStyles.mr20}>
          <UserImage
            photoUrl={attendee?.photo}
            altText={`${attendee?.first_name} ${attendee?.last_name} photo`}
            styles={messageStyles.profileImage}
          />
        </div>
        <div className={generalStyles.flexColumn}>
          <div>
            <span className={fontStyles.h3alternate2bolddark}>
              {attendee?.first_name} {attendee?.last_name}
            </span>
          </div>
          <div>
            <span className={fontStyles.bodydemigrey}>{attendee?.title}</span>
          </div>
          <div>
            <span className={fontStyles.bodydemigrey}>
              {attendee?.organization_name}
            </span>
          </div>
        </div>
      </div>
      <div className={generalStyles.mt10}>
        <HorizontalLine fullWidth={true} color={'lightGrey'} />
      </div>
    </div>
  )
}

export default MessageHeader

import React from 'react'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import programStyles from '../programStyles.module.scss'

const EventTypeChip = ({ eventCardType, color }) => (
  <div
    className={classNames(
      programStyles.eventTypeChip,
      generalStyles.mr20,
      programStyles[color]
    )}
    data-testid="eventCardType"
  >
    <span
      className={
        color === 'white' ? fontStyles.h6demilight : fontStyles.h6demigrey
      }
    >
      {eventCardType}
    </span>
  </div>
)

export default EventTypeChip

import React from 'react'
import virtualPortalStyles from '../components/virtualPortalStyles.module.scss'

const WishlistTableHeader = ({ isPotential }) => (
  <div style={{ padding: '20px 8px' }} className={virtualPortalStyles.tableHeader}>
    <div>{isPotential ? 'Participant' : 'Meeting Participant'}</div>
  </div>
)

export default WishlistTableHeader
